import { CSSProperties, MutableRefObject, useEffect, useState } from "react";
import { t } from "i18next";
import { InputValidator } from "../InputValidator/InputValidator";
import { InvalidHint, InvalidSpan } from "../InvalidHint/InvalidHint";
import { Input } from "antd";
import { registerComponent } from "@core/Plugins/pluginManager";
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";
import { StringInput } from "../StringInput/StringInput";

export interface IDecimalInputProps {
    initialValue?: any;
    placeholder?: string;
    onChanged?: (value: any) => void;
    setIsValid?: (isValid: boolean) => void;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    onEnter?: () => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    autoFocus?: boolean;
    valueRef?: MutableRefObject<any>;
    validation?: (value: any) => boolean;
    delayOnChanged?: boolean;
}

export const DecimalInput = registerComponent(coreUiComponentDescriptions.DecimalInput, _DecimalInput);

function _DecimalInput(props: IDecimalInputProps) {
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        return InputValidator.validateDecimal(value);
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let newValue: any = parseFloat(e.target.value.trim());
        newValue = isNaN(newValue) ? e.target.value.trim() : newValue;

        if (!InputValidator.isEqual(props.initialValue, newValue)) {
            props.onChanged?.(newValue);
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue: any = parseFloat(e.target.value.trim());
        newValue = isNaN(newValue) ? e.target.value.trim() : newValue;

        if (props.valueRef) {
            props.valueRef.current = isNaN(newValue) ? e.target.value.trim() : newValue;
        }

        if (!props.delayOnChanged) {
            props.onChanged?.(newValue);
        }

        setIsValid(isValidValue(e.target.value));
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;

        if (e.key === 'Enter') {
            onBlur({ target: { value: newValue } } as any);

            props.onEnter?.();
        }

        props.onKeyDown?.(e);
    };

    return <>
        <Input
            defaultValue={extractNumber(props.initialValue)}
            placeholder={isValid ? props.placeholder : t("error")}
            className={props.className}
            style={props.style}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            disabled={props.readonly}
            allowClear
            autoFocus={props.autoFocus}
            status={isValid ? undefined : "error"}
        />
        {isValid ||
            <InvalidSpan text={t("invalid_decimal_value")}/>
        }
    </>;
}

function extractNumber(value: any) {
    if (value == null || value === "") {
        return "";
    }

    if (typeof value == "number" || typeof value == "string") {
        return value.toString();
    }

    return "";
}