import {createAsyncThunk} from '@reduxjs/toolkit';

import {ICrmArrayElement} from '@core/Models/i-array-element';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import { IEntity, IEntityData } from '@core/Models/i-entity';
import { IEventSourcingStore } from '@core/EventSourcing/EventSourcingStore';

export interface IUpdateArrayAsyncPayload {
    store: IEventSourcingStore;
    oldValues: ICrmArrayElement[];
    newValues: ICrmArrayElement[];
    entityId: string;
    fieldId: string;
}

export const updateArrayAsync = createAsyncThunk(
    'orders/updateArrayAsync',
    async (payload: IUpdateArrayAsyncPayload) => {
        try {
            let {store, entityId, fieldId, oldValues, newValues} = payload;
            Logger.debug('updateArrayAsync', {id:entityId, fieldId, oldValues, newValues});
            const time = performance.now();

            await payload.store.updateArray(entityId, fieldId, oldValues, newValues);
                
            Logger.debug(`update array spent ${performance.now() - time} ms`);

            const entityData = {
                [fieldId]: newValues
            } as IEntityData;
            const entityChanges = {tableId: store.tableId, id: entityId, data: entityData};

            return entityChanges;
        } catch (err: any) {
            Logger.error('updateArrayAsync failed');
            Logger.error(serializeError(err, { maxDepth: 2}));
            throw new Error(err);
        }
    }
);
