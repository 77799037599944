import {CrmText} from '@core/VisualComponents/CrmText';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import styles from '../CrmGridView.module.scss';
import cellStyles from './CrmCellInternal.module.scss';
import classNames from 'classnames/bind';
import { ICrmField } from '@core/Models/tenantConfig.models';
import { FormattedDate } from '@core/VisualComponents/FormattedDate/FormattedDate';
import {
    coreUiComponentDescriptions,
    ICellClickedArgs,
    IContextMenuClickedArgs,
} from "src/PluginShared/core-ui-api";
import { ICrmCommentMessage } from '@core/Models/i-comment-message';
import { registerComponent } from '@core/Plugins/pluginManager';
import { useTranslation } from "react-i18next";
import { isIosSafari } from 'src/Utils/userAgent';
import { UserProfileIcon } from '@core/VisualComponents/Icons/UserProfileIcon/UserProfileIcon';
import { CommentsDateTime } from '@core/VisualComponents/Comments/CommentsDateTime';
import { IEntity } from '@core/Models/i-entity';
import { CSSProperties } from 'styled-components';
import { ArrayValuesViewer } from '@core/VisualComponents/ArrayComponents/ArrayValuesViewer/ArrayValuesViewer';
import { CrmCellInternalError } from './CrmCellInternalError';
import { InputValidator } from 'src/App/Pages/Shared/OrderField/InputValidator/InputValidator';
import { useAppSelector } from '@core/Redux/hooks';
import { selectUserInfo } from '@core/Redux/store';
import { ICrmUserInfo } from '@core/Models/autogenerated/user.models';
import { ReactComponent as EllipsisIcon } from "@assets/Icons/ellipsis-vertical-icon.svg";
import { CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models.shared';

let cx = classNames.bind(styles);

/* ====== CrmCellInternalSpan ====== */

export interface ICrmCellInternalSpanProps {
    field: ICrmField;
    entity: IEntity;
    style?: CSSProperties; // TODO: use style
}

export const CrmCellInternalSpan = registerComponent(coreUiComponentDescriptions.CrmCellInternalSpan, _CrmCellInternalSpan);

function _CrmCellInternalSpan(props: ICrmCellInternalSpanProps) {
    const { t } = useTranslation();
    const userInfo = useAppSelector(selectUserInfo);

    let value = props.entity.data[props.field.id];

    const render = ()=> {
        if (props.field.id == "__actions") {
            return <EllipsisIcon fill="gray" width="1em" height="1em"/>;
        }

        if (value == null || value === "") {
            return ""; 
        }

        switch (props.field.viewType) {
            case CrmFieldViewType.YesNo:
                if (InputValidator.validateByField(value, props.field)) {
                    return typeof value == "boolean"
                        ? (value as boolean) ? t("yes") : t("no")
                        : value;
                }
                break;
            case CrmFieldViewType.Combobox:
                if (InputValidator.validateByField(value, props.field)) {
                    return props.field.options!.find(x => x.value == value)?.label ?? value.toString();
                }
                break;
            case CrmFieldViewType.Date: {
                if (InputValidator.validateByField(value, props.field)) {
                    return <FormattedDate value={value as any}/>;
                }
                break;
            }
            case CrmFieldViewType.Timeline:
            case CrmFieldViewType.Comments: {
                const html = CommentsToHtml(value, userInfo);

                const commentsStyleRaw = (props.field.style as any ?? {})[".commentsCell"] ;
                let commentsStyle = extractRootStyle(commentsStyleRaw);

                return <div className={cellStyles.commentsCell} style={commentsStyle}>{html}</div>
            }
            case CrmFieldViewType.MultiString: {
                if (typeof value != "string" && typeof value != "number") {
                    break;
                }
                const str = (value?.toString() ?? '') as string
                let strArr = str.split('\n');
                if(strArr.length > 2){
                    strArr = strArr.slice(0,2);
                    strArr.push('...');
                }

                let html = [];
                let key = 0;
                for (let i = 0; i < strArr.length; i++) {
                    if (i > 0)
                        html.push(<br key={key++}/>);
                    html.push(<CrmText key={key++} text={strArr[i]}/>)
                }
                return <span title={str}>{html}</span>;
            }
            case CrmFieldViewType.Url:
                if (InputValidator.validateByField(value, props.field)) {
                    return <CrmText text={value as string} length={props.field.textEllipsis}/>;
                }
                break;
            case CrmFieldViewType.Decimal:
                if (InputValidator.validateByField(value, props.field)) {
                    return <CrmText text={value.toString()} length={props.field.textEllipsis}/>;
                }
                break;
            case CrmFieldViewType.Time:
                if (InputValidator.validateByField(value, props.field)) {
                    return <CrmText text={value as string} length={props.field.textEllipsis}/>;
                }
                break;
            case CrmFieldViewType.String:
            case CrmFieldViewType.Phone:
            case CrmFieldViewType.Unknown:
                if ((typeof value == "string" || typeof value == "number") && InputValidator.validateByRegExp(value, props.field.regExp)) {
                    return <CrmText text={value as string} length={props.field.textEllipsis}/>;
                }
                break;
            case CrmFieldViewType.Array:
                if (Array.isArray(value)) {
                    return <ArrayValuesViewer values={value as any} field={props.field} reversed/>
                }
                break;
        }

        const getErrorText = () => {
            if (props.field.viewType == CrmFieldViewType.Decimal) {
                return t("invalid_decimal_value");
            }
        }

        return <CrmCellInternalError value={value} hint={getErrorText()}/>;
    };



    return <>{render()}</>;
}

/* ====== CrmCellInternal ====== */

export interface ICrmCellInternalProps {
    field: ICrmField;
    entity: IEntity;
    autocompleteValues?: string[];
    onContextMenu?: (args: IContextMenuClickedArgs) => void;
    onCellDoubleClicked?: (args: ICellClickedArgs) => void;
    onCellClicked?: (args: ICellClickedArgs) => void;
    style?: CSSProperties;
}

export const CrmCellInternal = registerComponent(coreUiComponentDescriptions.CrmCellInternal, _CrmCellInternal);

function _CrmCellInternal(props: ICrmCellInternalProps) {
    const cellRef = useRef<HTMLTableDataCellElement>(null);

    let entityData = props.entity.data;
    const handleContextMenu = (e: MouseEvent) => {
        if (!props.onContextMenu)
            return;

        e.preventDefault();
        props.onContextMenu({e, cellRef, entityData});
    };

    useEffect(() => {
        cellRef.current && cellRef.current.addEventListener("contextmenu", handleContextMenu);
        if (isIosSafari()) {
            console.log('ios detected');
            cellRef.current && cellRef.current.addEventListener("long-press" as any, handleContextMenu);
        }
        return () => {
            cellRef.current && cellRef.current.removeEventListener("contextmenu", handleContextMenu);
            if (isIosSafari()) {
                cellRef.current && cellRef.current.removeEventListener("long-press" as any, handleContextMenu);
            }
        };
    });

    const field = props.field;

    let cellStyle = useMemo(() => {
        let result = extractRootStyle(field.style ?? null);
        if (result != null) {
            result = {
                ...result,
                maxWidth: undefined, //max-width handled by table
            };
        }
        return result;
    }, [field.style]);

    return <div className={cx(cellStyles.cellContainer)}
                style={{
                    ...cellStyle,
                    ...props.style,
                }}
                ref={cellRef}
                onDoubleClick={(e) => props.onCellDoubleClicked && props.onCellDoubleClicked({
                        e,
                        props,
                        entityData: entityData,
                        cellRef
                    })}
                onClick={(e) => {e.preventDefault(); props.onCellClicked && props.onCellClicked({
                        e,
                        props,
                        entityData: entityData,
                        cellRef
                    })}}
            >
                <CrmCellInternalSpan  entity = {props.entity} field={props.field}/>
            </div>
}

function extractRootStyle(styles: Record<string, any> | null) {
    if (styles == null)
        return {};
    
    const rootStyles = Object.entries(styles).filter( ([key, value]) => key[0] != '.');
    return Object.fromEntries(rootStyles)
}

function CommentsToHtml(commentsData : any, userInfo: ICrmUserInfo|null) : ReactElement[] {
    let limit :number = parseInt(process.env.REACT_APP_EVENT_MAX_TIMELINE_PREVIEW_MESSAGES_COUNT as string);

    if (!commentsData) {
        return [<CrmCellInternalError key="0"/>];
    }

    if (typeof commentsData == "string") {
        return [<div key="0">
            <UserProfileIcon name={userInfo?.login}/>
            {/* &nbsp;
            <CommentsDateTime date={DateTime.utc().toUnixInteger()}/> */}
            &nbsp;
            <CrmText text={commentsData}/>
        </div>];
    }

    if (!Array.isArray(commentsData)) {
        return [<CrmCellInternalError key="0"/>];
    }

    if (commentsData.some(x => !InputValidator.validateComment(x))) {
        return [<CrmCellInternalError key="0"/>];
    }

    let comments : ICrmCommentMessage[] =  Array.from(commentsData);
    let result: ReactElement[] = [];

    comments.sort((a,b)=> b.date - a.date);

    let n = 0;
    let key = 0;
    for (let x of comments ?? []) {
        if (n < limit) {
            result.push(
                <div key={key++}>
                    <UserProfileIcon name={x.author}/>
                    &nbsp;
                    <CommentsDateTime date={x.date}/>
                    &nbsp;
                    <CrmText text={x.message}/>
                </div>
            );
            n++;
        }
        else {
            result.push(<div key={key++}><span>...</span></div>);
            break;
        }
    }

    return result;
}