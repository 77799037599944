import React, { useState, useEffect, useRef } from 'react';
import { selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import { useAppDispatch, useAppSelector } from '@core/Redux/hooks';
import { useTranslation } from "react-i18next";
import { getValueFromLocalStorage } from '@core/Hooks/use-local-storage';
import { accessTokenKey } from '@core/Constants/app-storage-keys';
import { userApiSaveJsonConfig } from '@core/Api/user-api';
import { initApplicationAsync } from '@core/Redux/Slices/appSlice';
import { v4 as uuidv4 } from 'uuid';
import { saveConfig } from '@core/Redux/Slices/appSlice';
import './ChatWindow.css';
import './ChatMessage.css';
import { AIApi, CompositeTool, IToolState } from '@core/AI/ai-api';
import { ITenantConfig } from '@core/Models/tenantConfig.models';
import { MasterManagerTool } from '@core/AI/masterManager';
import Logger from 'js-logger';
import { INewUserInfo, NewUserForm } from './Components/NewUserForm/NewUserForm';
import { urlIsBasePath } from 'src/Utils/urlUtils';
import { useLocation } from 'react-router-dom';
import { tablePath } from '@core/Constants/route-paths';
import { ConfirmChangesMessage } from './Components/ConfirmChanges/ConfirmChanges';
import { Input } from 'antd';

export interface ChatMessage {
    id: string;
    sender: 'user' | 'support' | 'greeting' | 'debug';
    text: string;
}

export function ChatWindow() {
    const { t } = useTranslation();
    const [history, setHistory] = useState<JSX.Element[]>([]);
    const [agentState, setAgentState] = useState<IToolState>();

    useEffect(() => {
        Logger.debug('[ChatWindow] window opened');
        setHistory([
            renderMessage({id: 'greeting', sender: 'greeting', text: t("ai-assistant-greeting")}),
        ]);
    }, []);

    const [inputValue, setInputValue] = useState('');
    const [supportTyping, setSupportTyping] = useState(false);

    let tenantConfig = useAppSelector(selectTenantConfig);
    const userInfo = useAppSelector(selectUserInfo);
    const dispatch = useAppDispatch();

    const chatDialogRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const accessToken = getValueFromLocalStorage<string>(accessTokenKey);

    const location = useLocation();
    const tableConfig = tenantConfig?.tables.find(table => urlIsBasePath(location, tablePath(table.tableId)));
    const tableName = tableConfig?.tableName ?? '...';

    class ChatWindowAIApi extends AIApi {
        showNewUserForm(login?: string | undefined, password?: string | undefined): Promise<INewUserInfo> {
            return new Promise<INewUserInfo>((resolve, reject: (reason: string) => void) => {
                setHistory(prev => {
                    return [
                        ...prev,
                        <NewUserForm
                            userInfo={{
                                login: login ?? "",
                                password: password ?? "",
                                role: "User",
                            }}
                            onSubmit={resolve}
                            onCancel={() => reject("cancel")}
                        />
                    ]
                });
            });
        }
    }

    const confirmChanges = async (newConfig: ITenantConfig | null) => {
        return new Promise<boolean>((resolve, reject) => {
            setHistory(prev => {
                return [
                    ...prev,
                    <ConfirmChangesMessage
                        newConfig={newConfig}
                        onSubmit={() => resolve(true)}
                        onCancel={() => resolve(false)}
                    />
                ]
            })
        })
    }

    const scrollToBottom = () => {
        if (chatDialogRef.current) {
            chatDialogRef.current.scrollTop = chatDialogRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [history, supportTyping]);


    const renderMessage = (message: ChatMessage) => {
        let cls: string = '';

        if (message.sender === 'user')
            cls = 'user-message';
        else if (message.sender === 'support')
            cls = 'support-message';
        else if (message.sender === 'greeting')
            cls = 'support-message';
        return (
            <div
                key={message.id}
                className={`chat-message ${cls}`}
            >
                <MarkdownText text={message.text} />
            </div>
        );
    };

    const handleInputChange = (event: React.ChangeEvent<any>) => {
        setInputValue(event.target.value);
    };

    const sendMessage = async () => {
        if (inputValue.trim() === '') return;
        if (supportTyping) return;

        Logger.debug(`[ChatWindow] send message: '${inputValue}'`);

        setHistory(prev => {
            let newHistory = [
                ...prev,
                renderMessage({ id: uuidv4(), sender: 'user', text: inputValue }),
            ];

            setSupportTyping(true);
            setInputValue('');

            return newHistory;
        });

        const api = new ChatWindowAIApi(tenantConfig, userInfo!, dispatch);
        let agent = new MasterManagerTool(api, accessToken);

        if (agentState) {
            agent.setState(agentState);
        }

        agent.run(inputValue).then(async answer => {
            await receiveMessage(answer, api, agent);
            setAgentState(agent.getState());
        });
    };

    const receiveMessage = async (answer: string, api: AIApi, agent: CompositeTool) => {
        Logger.debug(`[ChatWindow] receive message: '${answer}'`);

        if (api.configChanged) {
            const newOrderConfig = api.getOrderConfig();
            const confirmed = await confirmChanges(newOrderConfig);

            if (confirmed) {
                dispatch(saveConfig(newOrderConfig));
                userApiSaveJsonConfig({ config: JSON.stringify(newOrderConfig) });
            }
            else {
                setSupportTyping(false);
        
                setHistory(prev => {
                    return [
                        ...prev,
                        renderMessage({
                            id: uuidv4(),
                            sender: 'support',
                            text: t("ai-answer-on-cancel"),
                        }),
                    ];
                });

                agent.addHumanMessage("All changes have been canceled by the user");

                return;
            }
        }
        
        if (api.rebootNeeded) {
            dispatch(initApplicationAsync());
        }

        setSupportTyping(false);

        setHistory(prev => {
            return [
                ...prev,
                renderMessage({
                    id: uuidv4(),
                    sender: 'support',
                    text: answer,
                }),
            ];
        });
    }

    const handleKeyPress = (event: any) => {
        // Check if the pressed key is "Enter", and that the shift key is not held down.
        if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
            // Prevent the default action to stop the form from submitting
            event.preventDefault();
            if (!supportTyping) {
                sendMessage();
            }
        }
    }

    const addColumnHintText = t("add-column-text").replaceAll('{tableName}', tableName);
    const addFilterHintText = t("add-filter-text").replaceAll('{tableName}', tableName);

    const showHint = history.length <= 1;

    const addHint = (event: any, text: string) => {
        event.preventDefault();
        setInputValue(text);
        if (inputRef.current)
            inputRef.current.focus();
    }

    return (
        <div className="chat-window">
            <div className="chat-dialog" ref={chatDialogRef}>
                {history.map((message, index) => (
                    <React.Fragment key={index}>
                        {message}
                    </React.Fragment>
                ))}
                {showHint &&
                    <div className='chat-hint'>{t("hint-examples")}
                        <br/><a className='tab-indent' onClick={(e)=>addHint(e, addColumnHintText)}>{addColumnHintText.trim() + "..."}</a>
                        <br/><a className='tab-indent' onClick={(e)=>addHint(e, addFilterHintText)}>{addFilterHintText.trim() + "..."}</a>
                    </div>
                }
                {supportTyping &&
                    <div key="support-typing" className="chat-message support-message typing-bubble">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }
            </div>
            <div className="input-container">
                <Input.TextArea
                    value={inputValue}
                    onKeyDown={handleKeyPress}
                    onChange={handleInputChange}
                    placeholder={t("ai-assistant-enter-command")}
                    autoFocus
                    autoSize={{maxRows: 4}}
                />
                <button className="send-button" onClick={sendMessage} disabled={supportTyping}>{t("send_message")}</button>
            </div>
        </div>
    );
};

const MarkdownText = (props: {text:string}) => {
    //todo:
    // const rawMarkup =useMemo(() => ({ __html: marked(props.text) as string }), [props.text]);
  
    // return (
    //   <div className="markdown-container" dangerouslySetInnerHTML={rawMarkup} />
    // );
    return <span>{props.text}</span>
  };
  

export default ChatWindow;