import { MutableRefObject } from 'react';
import { OrdersListViewItemWrapper } from './OrdersListViewItem/OrdersListViewItem';
import styles from './OrdersListView.module.scss';
import { useNavigate } from 'react-router-dom';
import { entityEditPath } from '@core/Constants/route-paths';
import InfiniteScroll from 'react-infinite-scroller';
import { useAppSelector } from '@core/Redux/hooks';
import { selectEntitiesAreLoading, selectQueryStatus } from '@core/Redux/Slices/ordersSlice/storesSlice';
import { useTranslation } from "react-i18next";
import { PageContentComponentId } from '@core/VisualComponents/Page/PageContent/PageContent';
import { IOrderContextMenu } from '../OrderContextMenu/OrderContextMenu';

export interface IOrdersListViewProps {
    tableId: string;
    entityIds: string[];
    fields?: string[];
    numRowsPerPage: number;
    contextMenuRef: MutableRefObject<IOrderContextMenu>;
    selectedEntityId?: string;
    onEditEntity: (entityId: string) => void;
}

export function OrdersListView(props: IOrdersListViewProps) {
    const { t } = useTranslation();

    //TODO:
    const hasMore = false;//queryStatus.resultLength > props.entityIds.length;
    const isLoading = useAppSelector(selectEntitiesAreLoading(props.tableId));
    const { status: queryStatus } = useAppSelector(selectQueryStatus(props.tableId));
    const onOrderClicked = (entityId: string) => {
        // navigateTo(entityEditPath(props.tableId, entityId));
        props.onEditEntity(entityId);
    };

    const loadMore = () => {
        //console.log('fetch more');
        if (isLoading)
            return;

        //TODO:
        //dispatch(fetchMoreAsync());
    };

    // Я не нашел способа задать стиль контейнера InfiniteScroll,
    // в котором будут отрисовываться элементы списка.
    // Поэтому задал стиль контейнера через .host > div.
    return (<div className={styles.host}>
        {(queryStatus == "loading")
            ? <div className={styles.noData}>{t("loading")}</div>
            : <>{props.entityIds.length == 0
                ? <div className={styles.noData}>{t("noOrders")}</div>
                : <InfiniteScroll
                    getScrollParent={() => document.getElementById(PageContentComponentId)}
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={<div className="loader" key={0}>{t("loading_status")}</div>}>
                    {props.entityIds.map((id) =>
                        <OrdersListViewItemWrapper key={id}
                            tableId={props.tableId}
                            entityId={id}
                            fields={props.fields}
                            onClick={onOrderClicked}
                            contextMenuRef={props.contextMenuRef}
                            selected={props.selectedEntityId === id} />)}
                </InfiniteScroll>
            }</>
        }
    </div>);
}
