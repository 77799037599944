import {CrmCellInputEditors, ICellEditor} from '@core/VisualComponents/CrmGridView/CrmCellInputEditors/CrmCellInputEditors';
import React, {MutableRefObject, useContext, useRef, useState} from 'react';
import {useAppDispatch} from '@core/Redux/hooks';
import {updateOrderAsync} from '@core/Redux/Slices/ordersSlice/thunks/updateOrderAsync';
import { ICrmField} from '@core/Models/tenantConfig.models';
import {IEntityData} from '@core/Models/i-entity';
import {updateArrayAsync} from '@core/Redux/Slices/ordersSlice/thunks/updateArrayAsync';
import { StoreContext } from '@core/Stores/OrderStoreProvider';
import { ICrmCellInputEditors, IMousePosition, IOnShowEditorReq, coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { getCachedValuesFromDumbCrmDb } from '@core/JsStore/stores/autocomplete-store';
import { registerComponent } from '@core/Plugins/pluginManager';
import { ICrmArrayElement } from '@core/Models/i-array-element';
import _ from 'lodash';
import Logger from 'js-logger';
import { CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models.shared';

export interface IOrderGridViewEditorsProps {
    cellEditorsRef: MutableRefObject<ICrmCellInputEditors>;
}

export const OrderGridViewEditors = registerComponent(coreUiComponentDescriptions.OrderGridViewEditors, _OrderGridViewEditors);

function _OrderGridViewEditors(props: IOrderGridViewEditorsProps) {
    const [editor, setEditor] = useState<ICellEditor | null>(null);
    const dispatch = useAppDispatch();
    const stores = useContext(StoreContext);
    const anchorRef = useRef<any>();

    const onSaveEditor = (value: ICrmArrayElement[] | string | number | boolean | null) => {
        setEditor(prevEditor => {
            if (prevEditor == null) {
                Logger.error("Can't save because editor is null");
                return null;
            }

            const {viewType, id} = prevEditor.req.field;
            const store = stores[prevEditor.req.tableId];
            if (store == null)
                return null;

            // if (viewType == CrmFieldViewType.Decimal && typeof value === "string") {
            //     const floatValue = parseFloat(value);
            //     if (!Number.isNaN(floatValue))
            //         value = floatValue;
            // }

            const entityData = {
                [id]: value
            } as IEntityData;

            switch (viewType) {
                case CrmFieldViewType.Timeline:
                case CrmFieldViewType.Array:
                case CrmFieldViewType.Comments: {
                    const array = value as ICrmArrayElement[];
                    dispatch(updateArrayAsync({
                        store: store,
                        oldValues: prevEditor.req.initialValue as ICrmArrayElement[],
                        newValues: array,
                        fieldId: id,
                        entityId: prevEditor.req.entityId
                    }))
                    break;
                }
                default:
                    dispatch(updateOrderAsync({store, id: prevEditor.req.entityId, changes: entityData}));
                    break;
            }

            return {
                req: {
                    ...prevEditor.req,
                    initialValue: value,
                }
            };
        });
    };

    const onHideEditor = () => {
        setEditor(null);
    };

    props.cellEditorsRef.current.hideEditor = onHideEditor;

    props.cellEditorsRef.current.showEditor = (tableId: string, entityId: string, entityData: IEntityData, field: ICrmField, position: IMousePosition, targetRef?: React.MutableRefObject<any>) => {
        const showEditor = async () => {
            const autocompleteValues = field.autocomplete
                    ? await getCachedValuesFromDumbCrmDb(tableId, field.id)
                    : [];
            const { id: dataField } = field;
            const initialValue = entityData[dataField];
            
            const req: IOnShowEditorReq = {
                field: field,
                key: entityId + dataField,
                position: position,
                initialValue,
                entityData,
                tableId,
                entityId,
                autocompleteValues,
                targetRef: targetRef || anchorRef!
            }
            
            setEditor({req});
        };

        if (!field.readonly) {
            showEditor().catch(err => {throw new Error('Unhandled exception during showing editor');});
        }
    };

    return (<>
        <div ref={anchorRef} style={{
            position: 'fixed',
            left: editor?.req.position.x,
            top: editor?.req.position.y,
        }}/>
        {editor && <CrmCellInputEditors
            onSave={onSaveEditor}
            onHide={onHideEditor}
            editor={editor}
            gridViewRef={props.cellEditorsRef}
        />}
    </>);
}
