import axios, { AxiosResponse } from 'axios';

const endpoint = '/api/ExternalEvent';

type IExternalEventMandatory = {
    crm_tenant: string;
    crm_eventtype: string;
    // Add other known fields here
};

// Define a type that allows any number of additional string properties
export type IExternalEvent = IExternalEventMandatory & {
    [key: string]: unknown;
};

export async function externalEventApiAdd(req: IExternalEvent): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/Add`, JSON.stringify(req));
    return resp;
}