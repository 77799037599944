import type { ICrmOperationEvent } from "@core/Models/autogenerated/operation.models";

export enum EventStreamFetchStatus
{
    FullCompletion,
    PartialCompletion
}

export interface IEventStreamFetchResult
{
    events: ICrmOperationEvent[];
    status: EventStreamFetchStatus;
    nextCursor: any;
}

export interface IEventStream {
    add(event: ICrmOperationEvent): Promise<void>;
    fetch(cursor: any | null, limit: number): Promise<IEventStreamFetchResult>;
    process(): Promise<void>;
}
