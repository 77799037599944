import { lock } from "@core/Helpers/lock";
import Logger from "js-logger";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

export type ShortQueueHandler<T> = (item: T) => Promise<void>;

interface IShortQueueStoreItem<T> {
    id : string;
    value: T;
}

export interface IShortQueueStore<T> {
    enqueue(value: T): Promise<void>;
    process(): Promise<void>;
} 

export class ShortQueueStore<T> implements IShortQueueStore<T> {
    private id: string;
    private tableName: string;
    private lockName: string;
    private handler: ShortQueueHandler<T>

    private resolvers: {[key: string]: ()=>void}
    private rejectors: {[key: string]: (reason?: any)=>void}

    public constructor(name: string, handler: ShortQueueHandler<T>) {
        this.tableName = name + "_shortqueue";
        this.lockName = "ShortQueueHandlerLock" + name;
        this.resolvers = {};
        this.rejectors = {};
        this.handler = handler
        this.id = uuidv4().substring(0, 4);
        //console.log(`[ShortQueueStore${this.id}] created`);
    }

    public enqueue(value: T): Promise<void> {
        const start_time = performance.now();
        //console.log(`[ShortQueueStore${this.id}] start enqueue`);

        let id = uuidv4(); 
        let item = { id, value } as IShortQueueStoreItem<T>;

        let items = this.getItems();
        items.push(item);
        this.setItems(items);

        Logger.debug(`[ShortQueueStore${this.id}] item  ${id} enqueued. ${performance.now()-start_time} ms`);
        
        var promise = new Promise<void>((resolve, reject) => {
            this.resolvers[id] = resolve;
            this.rejectors[id] = reject;
            //console.log(`[ShortQueueStore${this.id}] shedule processing. ${performance.now()-start_time} ms`);
            this.process();
            //console.log(`[ShortQueueStore${this.id}] after shedule processing. ${performance.now()-start_time} ms`);
        });

        return promise;
    }

    public async process(): Promise<void> {
        //Logger.debug(`[ShortQueueStore${this.id}] start processing`);
        const start_time = performance.now();

        // await navigator.locks.request(this.lockName, { ifAvailable: true }, async (lock) => {
        //     console.log(`[ShortQueueStore${this.id}] lock is ${lock != null ? 'free' : 'locked'}. ${performance.now()-start_time} ms`);
        // });

        await lock(this.lockName, async () => {
            //console.log(`[ShortQueueStore${this.id}] lock acquired. ${performance.now()-start_time} ms`);
            await this.handle();
            //console.log(`[ShortQueueStore${this.id}] handled. ${performance.now()-start_time} ms`);
        });
        //Logger.debug(`[ShortQueueStore${this.id}] process spent ${performance.now() - start_time} ms`);
    }

    private async handle(): Promise<void> {
        let items = this.getItems();
        let handled: string[] = [];
        let i = 0;
        while (i < items.length) {
            let item = items[i];
            let resolver = this.resolvers[item.id];
            let rejector = this.rejectors[item.id];
            
            if (!resolver) {
                Logger.warn(`[ShortQueueStore${this.id}] resolver for ${item.id} not found`);
                resolver = ()=>{};
            }

            if (!rejector) {
                Logger.warn(`[ShortQueueStore${this.id}] rejector for ${item.id} not found`);
                rejector = (reason?: any)=>{};
            }

            try {
                Logger.debug(`[ShortQueueStore${this.id}] handling item ${item.id}`);
                await this.handler(item.value);
                resolver();
            } catch(ex: any) {
                Logger.error(`[ShortQueueStore${this.id}]exception when handling queue item`, item);
                rejector(ex);
            }

            handled.push(item.id);
            items.splice(i, 1);
            delete this.resolvers[item.id];
            delete this.rejectors[item.id];
        }

        items = this.getItems();
        items = _.filter(items, item => !handled.includes(item.id));
        this.setItems(items);
    }

    private getItems(): IShortQueueStoreItem<T>[] {
        let data = localStorage.getItem(this.tableName);
        if (!data) {
            return [];
        } else {
            return JSON.parse(data) as IShortQueueStoreItem<T>[];
        }
    }

    private setItems(items: IShortQueueStoreItem<T>[]): void {
        let newData = JSON.stringify(items);
        localStorage.setItem(this.tableName, newData);
    }
}
