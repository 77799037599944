import {IFetchOrdersQuery} from '@core/Redux/Slices/ordersSlice/thunks/fetchOrdersAsync';
import {IEntity} from '@core/Models/i-entity';
import {db} from '@core/JsStore/idb';
import {IDbRawData} from '@core/JsStore/stores/shared/models/i-db-raw-data';
import {getEntityTable} from '@core/JsStore/stores/shared/funcs/common-store-funcs';
import {mapIDbRawToIDbEntity} from '@core/JsStore/mappers/map-i-db-raw-to-i-db-entity';
import {IWhereQueryOption} from 'jsstore/dist/ts/common/interfaces';
import _ from 'lodash';
import {Collection, IndexableType, Table} from 'dexie';
import { IDbEntity } from './shared/models/i-db-entity';

export const ordersTableName = 'orders';

// //todo: remove this file

function deprecated_db2entity(entity: IDbEntity) : IEntity {
    return {
        id: entity.entityId,
        data: entity.entityData,
        _lastEventNumber: entity.lastEventNumber,
    }
}

async function filterByDexie(ordersTable: Table, wherePair: [string, (IWhereQueryOption | string | number | boolean)]): Promise<Collection<any, IndexableType>> {
    const [fieldName, whereClause] = wherePair;
    if(_.isString(whereClause)){
        return ordersTable.where(fieldName).equalsIgnoreCase(whereClause as any);
    }
    else if (_.isNumber(whereClause)) {
        return ordersTable.where(fieldName).equals(whereClause as any);
    }
    else if (_.isBoolean(whereClause)) {
        return ordersTable.filter(x => x[fieldName] == whereClause as boolean);
    }

    const queryOption = Object.entries(whereClause as IWhereQueryOption)[0];
    const [queryOptionKey, queryOptionValue] = queryOption;

    switch (queryOptionKey){
        case '>':
            return ordersTable.where(fieldName).above(queryOptionValue);
        case '<':
            return ordersTable.where(fieldName).below(queryOptionValue);
        case '>=':
            return ordersTable.where(fieldName).aboveOrEqual(queryOptionValue);
        case '<=':
            return ordersTable.where(fieldName).belowOrEqual(queryOptionValue);
        case '!=':
            if (queryOptionValue === null)
                return ordersTable.where(fieldName).below([]);
            else
                return ordersTable.where(fieldName).notEqual(queryOptionValue);
        case '-':
            const low = queryOptionValue.low;
            const high = queryOptionValue.high;

            return ordersTable.where(fieldName).between(low, high, true, true);
        case 'like':
            const substring = (queryOptionValue?.toString() ?? "").toLowerCase();
            return ordersTable.filter(x => (x[fieldName] ?? "").toString().toLowerCase().includes(substring));
        case 'regex':
            throw new Error('not implemented');
            break;
        case 'or':
            debugger;
            throw new Error('not implemented');
            break;
        case 'in':
            const inVals = queryOptionValue as any[];

            return ordersTable.where(fieldName).anyOfIgnoreCase(inVals);
        default:
            throw new Error('not implemented');
    }
}

function stringEqualsIgnoreCase(a: any, b: any){
    return (a?.toString() ?? "").toLowerCase() === (b?.toString() ?? "").toLowerCase();
}

function filterByScript(values: Collection<any, IndexableType>, wherePair: [string, (IWhereQueryOption | string | number | boolean)]): Collection<any, IndexableType> {
    const [fieldName, whereClause] = wherePair;
    if(_.isString(whereClause) || _.isNumber(whereClause) || _.isBoolean(whereClause)) {
        return values.filter(v => stringEqualsIgnoreCase(v[fieldName], whereClause));
    }

    const queryOption = Object.entries(whereClause as IWhereQueryOption)[0];
    const [queryOptionKey, queryOptionValue] = queryOption;

    switch (queryOptionKey){
        case '>':
            return values.filter(x=>x[fieldName] > queryOptionValue)
            break;
        case '<':
            return values.filter(x=>x[fieldName] < queryOptionValue)
            throw new Error('not implemented');
            break;
        case '>=':
            return values.filter(x=>x[fieldName] >= queryOptionValue)
            break;
        case '<=':
            return values.filter(x=>x[fieldName] <= queryOptionValue)
            break;
        case '!=':
            if (queryOptionValue === null)
                return values.filter(x=>x[fieldName] !== null && x[fieldName] !== undefined);
            else
                return values.filter(x=>x[fieldName] !== queryOptionValue);
            break;
        case '-':
            const low = queryOptionValue.low;
            const high = queryOptionValue.high;

            return values.filter(x=> x[fieldName] >= low && x[fieldName] <= high);
        case 'like':
            const substring = (queryOptionValue?.toString() ?? "").toLowerCase();
            return values.filter(x => (x[fieldName] ?? "").toString().toLowerCase().includes(substring));
        case 'regex':
            debugger;
            throw new Error('not implemented');
            break;
        case 'or':
            debugger;
            throw new Error('not implemented');
            break;
        case 'in':
            const inVals = queryOptionValue as any[];

            return values.filter(x=> inVals.some(y=>stringEqualsIgnoreCase(x[fieldName], y)));
        default:
            throw new Error('not implemented');
    }
}

export function filterArrByScript(values: Collection<any, IndexableType>, wherePairs: [string, (IWhereQueryOption | string | number | boolean)][], fullTextSearch?: string): Collection<any, IndexableType> {
    let result = values;
    for(let wherePair of wherePairs ?? []){
        result = filterByScript(result, wherePair);
    }

    if (fullTextSearch) {
        const textToSearch = fullTextSearch.trim().toLowerCase();
        result = result.filter(x => JSON.stringify(x).toLowerCase().includes(textToSearch) )
    }

    return result;
}

enum IndexPriority{
    WhereFirst = 0,
    OrderByFirst = 1,
    None = 2,
}


export async function selectEntitiesFromDb(tableId: string, query: IFetchOrdersQuery): Promise<IEntity[]> {
    let ordersTable = getEntityTable(tableId);

    return db.transaction('r', ordersTable, async () => {
        let dbRawDataItems: IDbRawData[] | null = null;

        let useIndexFor : IndexPriority;
        if (query.limit && query.order)
            useIndexFor = IndexPriority.OrderByFirst;
        else if (query.where)
            useIndexFor = IndexPriority.WhereFirst;
        else if (query.order)
            useIndexFor = IndexPriority.OrderByFirst;
        else
            useIndexFor = IndexPriority.None;

        let entityCollection : Collection<any, IndexableType>;
        let wherePairs : [string, string | number | boolean | IWhereQueryOption][] = [];
        let firstWherePair : [string, string | number | boolean | IWhereQueryOption];
        let otherWherePairs : [string, string | number | boolean | IWhereQueryOption][] = [];

        let limit = query.limit;

        if (query.where) {
            wherePairs = Object.entries(query.where);
        }

        if(useIndexFor == IndexPriority.WhereFirst) {
            if (wherePairs.length > 0) {
                firstWherePair = wherePairs[0];
                otherWherePairs = wherePairs.slice(1);
                entityCollection = await filterByDexie(ordersTable, firstWherePair);
            }
            else {
                entityCollection = ordersTable.toCollection();
            }
            entityCollection = filterArrByScript(entityCollection, otherWherePairs, query.fullTextSearch);
            if (query.order) {
                if(query.order.type === 'desc'){
                    entityCollection = entityCollection.reverse();
                }
                dbRawDataItems = await entityCollection.sortBy(query.order.by as string);
                dbRawDataItems = dbRawDataItems.slice(query.skip, query.skip + limit);
            }
        }
        else if (useIndexFor == IndexPriority.OrderByFirst) {
            entityCollection = ordersTable.orderBy(query.order!.by as string);
            if(query.order!.type === 'desc'){
                entityCollection = entityCollection.reverse();
            }
            entityCollection = filterArrByScript(entityCollection, wherePairs, query.fullTextSearch);
        }

        if (!dbRawDataItems) {
            if (useIndexFor == IndexPriority.None) {
                dbRawDataItems = await ordersTable
                    .offset(query.skip)
                    .limit(limit)
                    .toArray();
            } else {
                dbRawDataItems = await entityCollection!
                    .offset(query.skip)
                    .limit(limit)
                    .toArray();
            }
        }

        return dbRawDataItems
            .map(x => mapIDbRawToIDbEntity(x))
            .map(x => deprecated_db2entity(x));
    });
}