import { IRoute} from '../../../PluginShared/i-plugin-contract';
import styles from './PluginPage.module.scss'
import Logger from 'js-logger';

export interface IPluginPageProps {
    route: IRoute
}

export function PluginPage(props: IPluginPageProps) {
    const PluginComponent = () => {
        return props.route.func();
    }
    
    return (<PluginComponent key={props.route.id}/>);
}
