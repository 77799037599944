import { EventHandler } from "@core/Helpers/eventHandler";
import { lock } from "@core/Helpers/lock";
import { db } from "@core/JsStore/idb";
import { Table } from "dexie";
import Logger from "js-logger";
import { DATA_TYPE } from "jsstore";
import { property } from "lodash";
import { DateTime } from "luxon";

export const enum QueueItemStatus {
    Pending = 0,
    Handled = 1,
    Error = 3,
}

export interface IHandleStatus {
    status: QueueItemStatus,
    statusText: string
}

export type QueueHandler<T> = (item: T) => Promise<IHandleStatus>;

interface QueueEntry<T> {
    createdAt: Date,
    status: QueueItemStatus,
    statusText: string | null,
    handledAt: Date | null,
    tenant?: string,
    data: T,
}

export interface IQueueStoreMonitor<T, V> {
    handleNewData(entries: QueueEntry<T>[]): void;
    handleNewItem(item: T): void;

    onChange: EventHandler<Set<V>>;
}

export class QueueStoreMonitor<T, V> implements IQueueStoreMonitor<T, V> {
    private projector: (item:T)=>V;
    private data: Set<V> = new Set<V>;

    public onChange = new EventHandler<Set<V>>();
    
    public constructor(projector: (item:T)=>V) {
        this.projector = projector;
    }

    public handleNewData = (entries: QueueEntry<T>[]) => {
        let newData = new Set<V>(entries.map(e => this.projector(e.data)));
        this.handleDataUpdate(newData);
    }

    public handleNewItem = (item: T) =>{
        let newData = new Set<V>(this.data);
        newData.add(this.projector(item));
        this.handleDataUpdate(newData);
    }

    private handleDataUpdate = (newIdsList: Set<V>) => {
        let newIdsSet: Set<V>;
        if (Array.isArray(newIdsList))
            newIdsSet = new Set(newIdsList);
        else
            newIdsSet = newIdsList;

        let haveChanges = false;
        if (newIdsSet.size == this.data.size) {
            this.data.forEach(x => {
                if (!newIdsSet.has(x))
                    haveChanges = true;
            });
        } else {
            haveChanges = true;
        }

        if (haveChanges) {
            this.data = newIdsSet;
            this.onChange.trigger(this.data);
        }
    }
}

export interface IQueueStore<T> {
    enqueue(item: T): Promise<void>;
    process(handler: QueueHandler<T>): Promise<void>;
    createMonitor<V>(projector: (item:T)=>V): IQueueStoreMonitor<T, V>;
}

export class QueueStore<T> implements IQueueStore<T> {
    private tableName: string;
    private lockName: string;

    private tenant?: string;
    private monitors: IQueueStoreMonitor<T, any>[] = [];

    public constructor(name: string, tenant?: string) {
        this.tableName = QueueStore.getTableName(name);
        this.lockName = "QueueHandlerLock" + name;
        this.tenant = tenant;
    }

    public static getTableName = (name: string) => {
        return name + "_queue";
    }

    public static getSchemas(name: string) {
        return [{
            name: name + "_queue",
            columns: {
                id: {
                    dataType: DATA_TYPE.Number,
                    primaryKey: true,
                    autoIncrement: true
                },
                createdAt: {
                    dataType: DATA_TYPE.DateTime,
                    notNull: true,
                },
                handledAt: {
                    dataType: DATA_TYPE.DateTime,
                    notNull: false,
                },
                status: {
                    dataType: DATA_TYPE.Number,
                    notNull: true,
                },
                statusText: {
                    dataType: DATA_TYPE.String,
                    notNull: false,
                },
                data: {
                    dataType: DATA_TYPE.Object,
                }
            }
        }];
    }

    public async enqueue(item: T): Promise<void> {
        this.monitors.forEach(m => m.handleNewItem(item));

        await lock(this.lockName, async () => {
            const entry: QueueEntry<T> = {
                createdAt: DateTime.utc().toJSDate(),
                tenant: this.tenant,
                status: QueueItemStatus.Pending,
                statusText: null,
                handledAt: null,
                data: item,
            }

            const table = this.getTable();
            await db.transaction('rw', table, () => {
                return table.add(entry).catch(ex => {
                    Logger.error(`[QueueStore]fail to enqueue item ${item}`, ex);
                })
            }
            );
        });
    }

    public async process(handler: QueueHandler<T>): Promise<void> {
        await lock(this.lockName, async () => {
            await this.handle(handler);
            await this.cleanup();
        });
    }

    private async handle(handler: QueueHandler<T>): Promise<void> {
        const table = this.getTable();
        const pendingList: QueueEntry<T>[] = 
            await db.transaction('r', table, () =>
                table
                    .where({ status: QueueItemStatus.Pending })
                    .filter(e => e.tenant == this.tenant || e.tenant == null || this.tenant == null)
                    .sortBy("id")
            ).catch(ex => {
                Logger.error(`[QueueStore]fail to read queue`, ex);
                return [];
            });

        this.monitors.forEach(m => m.handleNewData(pendingList));

        let newSize = 0;

        for (let e of pendingList) {
            let result: IHandleStatus;
            try {
                result = await handler(e.data);
            } catch(ex: any) {
                Logger.error(`[QueueStore]exception when handling queue item: ${e.data}`);
                result = {
                    status: QueueItemStatus.Error,
                    statusText: ex.toString(),
                };
            }

            e.status = result.status;
            e.statusText = result.statusText;
            e.handledAt = DateTime.utc().toJSDate();

            if (e.status != QueueItemStatus.Handled) {
                newSize++;
            }

            await db.transaction('rw', table, () => 
                table.put(e).catch(ex => {
                    Logger.error(`[QueueStore]fail to update item ${e}`, ex);
                })
            );
        }

        let newPendingList = pendingList
            .filter(x => x.status != QueueItemStatus.Handled)
        this.monitors.forEach(m => m.handleNewData(newPendingList));
    }

    public createMonitor<V>(projector: (item:T)=>V): QueueStoreMonitor<T,V> {
        const newMonitor = new QueueStoreMonitor<T,V>(projector);
        this.monitors.push(newMonitor);
        return newMonitor;
    }

    private async cleanup(): Promise<void> {
        const table = this.getTable();
        let threshold = DateTime.utc().minus({ days: 7 }).toJSDate();
        await db.transaction('rw', table, () => 
            table.where("createdAt").below(threshold)
                .and(e => e.status !== QueueItemStatus.Pending)
                .delete()
        ).catch(ex => {
            Logger.error(`[QueueStore]fail to cleanup:`, ex);
        });
    }

    private getTable(): Table {
        return db.table(this.tableName);
    }
}
