import { CSSProperties, MutableRefObject, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import styles from './CrmGridView.module.scss';

import { CrmPaginationInternal } from './CrmPaginationInternal/CrmPaginationInternal';
import { coreUiComponentDescriptions, ICellClickedArgs } from "src/PluginShared/core-ui-api";
import { registerComponent } from '@core/Plugins/pluginManager';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { t } from 'i18next';

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
    Data,
    TableNode,
    ExtendedNode,
  } from '@table-library/react-table-library/table';
import { ColumnSize, Layout } from '@table-library/react-table-library/types/layout';
import { getValueFromLocalStorage, setValueToLocalStorage } from '@core/Hooks/use-local-storage';
import { CrmGridViewUserSettings, setSettingsColumnSize } from './CrmGridViewUserSettings';
import _ from 'lodash';
import { IEntity, IEntityData } from '@core/Models/i-entity';
import { CrmCellInternal } from './CrmCellInternal/CrmCellInternal';
import { ICrmField } from '@core/Models/tenantConfig.models';
import { CrmGridSimpleFilterButton } from './CrmGridAddFilter/CrmGridSimpleFilterButton/CrmGridSimpleFilterButton';
import { CrmGridAddColumnPanel } from './CrmGridAddColumnPanel/CrmGridAddColumnPanel';
import { StatusPanel } from 'src/App/Pages/OrdersPage/Components/StatusPanel';

/* ====== CrmGridRow ====== */

interface CrmTableNode extends TableNode {
    value: string;
}

export interface ICrmGridRowProps {
    entityId: string,
    rowNumber : number,
    fields: ICrmField[],
    useItemByIdSelector: (id: string)=>IEntity;
    onContextMenu?: (e: MouseEvent, ref: MutableRefObject<any>, entityId: string, entityData: IEntityData, field?: ICrmField) => void;
    onCellDoubleClicked?: (args: ICellClickedArgs) => void;
    onRowClick?: (entityId: string) => void;
    className: string;
    selected?: boolean;
    style?: CSSProperties;
}

export const CrmGridRow = registerComponent(coreUiComponentDescriptions.CrmGridRow, _CrmGridRow);

function _CrmGridRow(props: ICrmGridRowProps){
    let entity = props.useItemByIdSelector(props.entityId);

    return <Row 
                key={props.entityId}
                className={props.className}
                item={entity}
                onClick={() => props.onRowClick ? props.onRowClick(props.entityId) : null}
                style = {props.style}
            >
                {props.onContextMenu && <Cell className={props.selected ? styles.selectedRow : ''}>
                    <CrmCellInternal
                        key={"__actions"}
                        field={{
                            id: "__actions"
                        } as ICrmField}
                        entity={entity}
                        onContextMenu={(args) => props.onContextMenu?.(args.e, args.cellRef, props.entityId, args.entityData)}
                        onCellClicked={(args) => props.onContextMenu?.(args.e as any, args.cellRef, props.entityId, args.entityData)}/>
                </Cell>}
                {props.fields.map(field =>
                    <Cell key={field.id} className={props.selected ? styles.selectedRow : ''}>
                        <CrmCellInternal
                            key={field.id}
                            field={field}
                            entity={entity}
                            onContextMenu={(args) => props.onContextMenu?.(args.e, args.cellRef, props.entityId, args.entityData, field)}
                            onCellDoubleClicked={props.onCellDoubleClicked}/>
                    </Cell>
                )}
            </Row>
}

/* ====== CrmGridView ====== */

export interface ICrmGridViewProps {
    gridId: string;
    keyField: string;
    tableName: string;
    entityIds: string[];
    numRowsPerPage: number;
    fields: ICrmField[],
    children?: ReactElement<any, any>[] | ReactElement<any, any> | undefined;
    useItemByIdSelector: (id: string)=>IEntity;
    onCellDoubleClicked?: (args: ICellClickedArgs) => void;
    onContextMenu?: (e: MouseEvent, ref: MutableRefObject<any>, entityId: string, entityData: IEntityData, field?: ICrmField) => void;
    addColumnAllowed? : boolean;
    selectedEntityId?: string;
    onRowClick?: (entityId: string) => void;
    isLoading?: boolean;

    simpleFilters?: {[columnId: string]: any};
    onChangedSimpleQuery?: (columnId: string, query: any) => void;
    onResetSimpleQuery?: (columnId: string) => void;

    ResetCursor: () => void;
    MoveCursor: (offset: number) => void;
    cursorAtStart: boolean;
    cursorAtEnd: boolean;

    style?: CSSProperties;
    showFilters?: boolean;
}

export const CrmGridView = registerComponent(coreUiComponentDescriptions.CrmGridView, _CrmGridView);

function _CrmGridView(props: ICrmGridViewProps) {
    const fields = props.fields;
    const userSettingsKey = `tableUserSettings_${props.gridId}`;
    const [selectedEntityId, setSelectedEntityId] = useState<string | undefined>(undefined);

    if (!props.onRowClick) {
        props.selectedEntityId = selectedEntityId;
        props.onRowClick = (entityId) => setSelectedEntityId(entityId);
    }

    const showPagination = !props.cursorAtStart || !props.cursorAtEnd;

    let data: Data<CrmTableNode> = {nodes: props.entityIds.map(e => ({id: e, value: e}))};

    const columnSizes = useMemo(()=>{
        let settings = getValueFromLocalStorage<CrmGridViewUserSettings>(userSettingsKey);
        let sizes: ColumnSize[] = [];

        if (props.onContextMenu) {
            sizes.push({ defaultSize: '2em', actualSize: '2em' });
        }

        sizes.push(...fields.map(column => {
            const defaultSize = 
                (column.style?.maxWidth != null) ?
                    `minmax(auto, ${column.style.maxWidth})`
                    : 'minmax(auto, 20em)';

            let actualSize = undefined;
            if (settings?.ColumnSettings != null && column.id in settings?.ColumnSettings) {
                actualSize = settings?.ColumnSettings[column.id].size;
            }

            return { defaultSize, actualSize } as ColumnSize;
        }));

        return sizes;
    }, [fields, props.onContextMenu]);

    const resizeColumnHandlers = useMemo(() => fields.map(column => {
        const columnId = column.id;

        return _.debounce((size:number) => {
            let settings = getValueFromLocalStorage<CrmGridViewUserSettings>(userSettingsKey);
            settings = setSettingsColumnSize(settings, columnId, size);
            setValueToLocalStorage(userSettingsKey, settings);
        }, 1000);
    }), [fields]);

    const onResize = useCallback( (index: number, size: number) => {
        resizeColumnHandlers[index-1](size); // index-1 beacuse of actions column
    }, [fields] );


    const layout = {
        custom: true, 
        fixedHeader: true, 
        horizontalScroll: true,
        columnSizes,
        onResize: onResize
    } as Layout;

    return (<div className={styles.tableWrapper} style={props.style}>
        <div className={styles.gridContainer}>
            <Table
                data={data}
                layout={layout}
            >
                {(tableList: ExtendedNode<CrmTableNode>[]) =>  (
                <>
                    <Header>
                        <HeaderRow>
                            {props.onContextMenu && <HeaderCell></HeaderCell>}
                            {fields.map(column =>
                                <HeaderCell key={column.id} resize={true}>
                                    <span className={styles.headerText}>{column.caption}</span>
                                    {props.showFilters &&
                                        <CrmGridSimpleFilterButton
                                            query={props.simpleFilters?.[column.id]}
                                            field={column}
                                            onChangedSimpleQuery={query => props.onChangedSimpleQuery && props.onChangedSimpleQuery(column.id, query)}
                                            onResetSimpleQuery={() => props.onResetSimpleQuery && props.onResetSimpleQuery(column.id)}
                                        />
                                    }
                                </HeaderCell>
                            )}
                        </HeaderRow>
                    </Header>

                    <Body>
                        {props.isLoading ||
                            <>{tableList.map((node, index) => <CrmGridRow 
                                key={index}
                                rowNumber = {index}
                                entityId={node.value}
                                style= {{}}
                                className={""}
                                fields={props.fields}
                                onContextMenu={props.onContextMenu}
                                onCellDoubleClicked={props.onCellDoubleClicked}
                                useItemByIdSelector={props.useItemByIdSelector}
                                onRowClick = {() => props.onRowClick!(node.value)}
                                selected={node.value === props.selectedEntityId}
                                />)
                            }</>
                        }
                    </Body>
                </>
            )}
            </Table>
            {data.nodes.length == 0 && !props.isLoading &&
                <div className={styles.noData}>{t("noOrders")}</div>
            }
            {props.isLoading &&
                <div className={styles.noData}>{t("loading")}</div>
            }
        </div>
        { showPagination ?
            <CrmPaginationInternal
                onFirstPageClicked={()=>props.ResetCursor()}
                onPreviousPageClicked={()=>props.MoveCursor(-props.numRowsPerPage)}
                onNextPageClicked={()=>props.MoveCursor(props.numRowsPerPage)}
                hasPreviousPage={!props.cursorAtStart}
                hasNextPage={!props.cursorAtEnd}
            />
            :
            <div/>
        }
    </div>)
}