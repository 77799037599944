//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface ICrmTenantConfig
{
	useDefaultTenant: boolean;
	tenant?: string;
}
export enum CrmFieldViewType {
	Timeline = "Timeline",
	Comments = "Comments",
	String = "String",
	Decimal = "Decimal",
	Date = "Date",
	Time = "Time",
	YesNo = "YesNo",
	Combobox = "Combobox",
	MultiString = "MultiString",
	Url = "Url",
	Phone = "Phone",
	Array = "Array",
	Unknown = "Unknown"
}
