import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import styles from './ConfirmDelete.module.scss';
import { MutableRefObject } from 'react';
import Overlay from 'react-overlays/Overlay';
import { t } from 'i18next';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { registerComponent } from '@core/Plugins/pluginManager';
import { Placement } from '@popperjs/core';

export interface IConfirmDeleteProps {
    triggerRef: MutableRefObject<any> | null;
    visible: boolean;
    onCancel: () => void;
    onDelete: () => void;
    placement?: Placement;
}

export const ConfirmDelete = registerComponent(coreUiComponentDescriptions.ConfirmDelete, _ConfirmDelete);

export function _ConfirmDelete(compProps: IConfirmDeleteProps) {
    const onDelete = (e: any) => {
        e.stopPropagation();
        compProps.onDelete();
    }

    const onCancel = (e: any) => {
        e.stopPropagation();
        compProps.onCancel();
    }

    return (<Overlay
        show={compProps.visible}
        rootClose
        flip={true}
        placement={compProps.placement ?? 'bottom-start'}
        onHide={compProps.onCancel}
        container={null}
        target={compProps.triggerRef}
    >
        {({props, arrowProps, placement}) => (
            <div {...props} className={styles.host}>
                <p>{t("ask_confirm_delete")}</p>
                
                <div className={styles.buttonsPanel}>
                    <SecondaryButton onClick={onDelete}>
                        {t("confirm_delete")}
                    </SecondaryButton>
                    <SecondaryButton onClick={onCancel}>
                        {t("cancel")}
                    </SecondaryButton>
                </div>
            </div>
        )}
    </Overlay>);
}
