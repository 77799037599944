import _ from 'lodash';

import { BaseTool, CompositeTool, IApiForAIAgent, LangChainAgent } from "./ai-api";
import { createApi } from '@core/Redux/Slices/appSlice';
import { ICrmApi } from '@pluginShared/i-crm-api';
import { CreateDecoratorTool } from './createDecoratorTool';
import { CreateReportTool } from './createReportTool';
import { AskUserTool, DeleteModuleTool, ListPluginsTool, PluginCreatorTool, UpdatePluginTool } from './commonPluginTools';
import { CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models.shared';

export const getPluginPromptParts = async (api: IApiForAIAgent) => ({
    overridingComponents: `
    - CrmGridView: The main table in the system. Should be the only one on the page
        IMPORTANT: this component does not support its own styles!
        'props' for CrmGridView contains:
            - entityIds: string[];
            - fields: ICrmField[]; // ICrmField is a description of the field. It will be described below.
            - useItemByIdSelector: (id: string) => IEntity; // IEntity is a description of one entity of the system. It will be described below.
    - CrmCellInternal: Cell in the main table. Uses CrmCellInternalSpan.
        Create a decorator for CrmCellInternal only if you want to change the appearance of the table cell (background, text color, etc.).
        'props' for CrmCellInternal contains:
            - field
            - entity: IEntity;
            - style?: CSSProperties;
    - CrmCellInternalSpan: Contents of the table cell. Typically contains plain text
        Create a decorator for CrmCellInternalSpan only if you want to change the content of the table cell.
        IMPORTANT: this component does not support its own styles! Instead use CrmCellInternal.
        'props' for CrmCellInternalSpan contains:
            - field: ICrmField;
            - entity: IEntity;
    - CrmCellInputEditors: Contents of the order table cell editor
        If you replace CrmCellInputEditors, remember that it must be an overlay or modal window.
        IMPORTANT: Always add enter handling.
        'props' for CrmCellInputEditors contains:
            - onHide: () => void; // You must call this function to hide the editor.
            - onSave: (value: any) => void; // You must call this function to save the new values.
            - editor: { req: IOnShowEditorReq } | null; // IOnShowEditorReq describes the entity being edited. It will be described below.
                Note that the editor field contains a req field, which in turn describes the entity being edited.
            - style?: CSSProperties;
            - readonly?: boolean; // Set this to true if you need to disable editing
    - OrdersListViewItem: component for displaying the entity in the list of orders
        'props' for OrdersListViewItem contains:
            - entityId: string;
            - entity: IEntity; 
            - fields: ICrmField[];
            - style?: CSSProperties;
    - OrdersListViewItemValue: component for displaying the field value in the list of orders
        'props' for OrdersListViewItemValue contains:
            - value: any;
            - field: ICrmField; // ICrmField is a description of the field. It will be described below.
            - style?: CSSProperties;
    - OrderFieldEditor: Field editor on the list for editing or creating an object
        'props' for OrderFieldEditor contains:
            - field: ICrmField; 
            - value: any;
            - entityId: string;
            - entityData: Record<string, unknown>; // key is field id and value is value
            - autocompleteValues: string[] | null;
            - onChanged: (value: any) => void; // You must call this function to save the new values.
            - style?: CSSProperties;
            - readonly?: boolean; // Set this to true if you need to disable editing
    - ArrayEditorInner: A component for editing a field, which is an array of some values.
        IMPORTANT: this component does not support its own styles!
        'props' for ArrayEditorInner contains:
            - values: any[];
            - field: ICrmField;
            - onChanged: (v: ICrmArrayElement[]) => void;
            - readonly?: boolean; // Set this to true if you need to disable editing. Or use readonly value from props for original component.
            - reversed?: boolean;
            - narrow?: boolean; // Set this to true by default
    - ArrayValuesViewer: Component for displaying field values, which is an array of some values. Used in the orders table and in the order list.
        IMPORTANT: Do not replace this component, just change props if needed.
        'props' for ArrayValuesViewer contains:
            - values: ICrmArrayElement[];
            - style?: CSSProperties;
            - reversed?: boolean;
    - ArrayValueRenderer: Component for displaying one element of an array
        IMPORTANT: Use only one line of text, separated by commas.
        'props' for ArrayValueRenderer contains:
            - arrayValue: Record<string, unknown>; // key is field id and value is value
            - field: ICrmField; // You can use props.field.fields to access array fields
            - style?: CSSProperties;
    `,
    componentsForUse: `
    - CallButton: Component button for handling 'call' click in phone field. 
        If you replace onCall method, you can implement custom call method to be used in your IP telephony
        'props' for CallButton contains:
            - value?: string|null; //contains user input in phone field. contain multiple dirty phone numbers
            - userPhone: string|null; //current user phone number
            - onCall?: (userPhone: string, targetPhone:string) => void; //method been called when user want to make call to specific phone
    - CrmEditOverlay: A universal component for editing simple values in a table. It already contains a button for saving and closing. All you have to do is create input fields.
        IMPORTANT: Use this component every time you need to edit simple value in a table.
        'props' for CrmEditOverlay contains:
            - onSave: () => void;
            - onHide: () => void;
            - targetRef: MutableRefObject<any>; // Reference to the calling element. If the CrmCellInputEditors is overridden, then you need to take the reference from IOnShowEditorReq.targetRef
            - disabledSave?: boolean;
            - children?: any;
    - ModalWindow: A universal component for editing complex values in a table. It already contains a button for saving and closing. All you have to do is create input fields.
        IMPORTANT: Use this component every time you need to edit complex value in a table.
        'props' for ModalWindow contains:
            - title?: string | JSX.Element;
            - onHide: () => void;
            - onCancel: () => void;
            - onSave: () => void;
            - children?: any;
    - StringInput: Universal component for string input
        IMPORTANT: Always use this component for string input because it already contains all the necessary functionality.
        'props' for StringInput contains StandartInputProps and:
            - onKeyDown?: (e: KeyboardEvent) => void;
            - type?: "text" | "textarea" | "password"; // "text" by default
    - PhoneInput: Universal component for phone input
        IMPORTANT: Always use this component for phone input because it already contains all the necessary functionality.
        'props' for PhoneInput contains StandartInputProps and:
            - onKeyDown?: (e: KeyboardEvent) => void;
    - UrlInput: Universal component for url input
        IMPORTANT: Always use this component for url input because it already contains all the necessary functionality.
        'props' for UrlInput contains StandartInputProps and:
            - onKeyDown?: (e: KeyboardEvent) => void;
    - DecimalInput: Universal component for decimal input
        IMPORTANT: Always use this component for decimal input because it already contains all the necessary functionality.
        'props' for DecimalInput contains StandartInputProps and:
            - onKeyDown?: (e: KeyboardEvent) => void;
    - DateInput: Universal component for date input
        IMPORTANT: Always use this component for date input because it already contains all the necessary functionality.
        'props' for DateInput contains StandartInputProps.
    - TimeInput: Universal component for time input
        IMPORTANT: Always use this component for time input because it already contains all the necessary functionality.
        'props' for TimeInput contains StandartInputProps.
    - ComboboxInput: Universal component for combobox input
        IMPORTANT: Always use this component for combobox input because it already contains all the necessary functionality.
        IMPORTANT: the onChanged function sends a value, not an ICrmValueOption object
        'props' for ComboboxInput contains StandartInputProps and:
            - options: ICrmValueOption[];
            - disableClear?: boolean;
    - CheckboxInput: Universal component for true/false input
        IMPORTANT: Always use this component for true/false input because it already contains all the necessary functionality.
        'props' for CheckboxInput contains StandartInputProps and:
            - onKeyDown?: (e: KeyboardEvent) => void;
    - PrimaryButton:
        IMPORTANT: Always use this component because it already contains all the necessary functionality.
        'props' for PrimaryButton contains:
            - onClick?: (e: React.MouseEvent) => void;
            - style?: any;
            - disabled?: boolean;
    - SecondaryButton:
        IMPORTANT: Always use this component because it already contains all the necessary functionality.
        'props' for SecondaryButton the same as for PrimaryButton.
    `,
    commonNotes: `
    StandartInputProps:
        {
            initialValue: any; // initial value
            placeholder?: string;
            onChanged?: (value: any) => void;
            className?: string;
            style?: CSSProperties;
            readonly?: boolean;
            autoFocus?: boolean;
            validation?: (value: any) => boolean;
        }
    IEntity:
        {
            id: string;
            data: Record<string, unknown>; // key is field id and value is value
        }
    IOnShowEditorReq:
        {
            position: {x: number, y: number};
            initialValue: unknown;
            entityData: Record<string, unknown>; // key is field id and value is value
            entityId: string;
            targetRef: MutableRefObject<any>;
            field: ICrmField;
            autocompleteValues: string[];
        }
    ICrmField:
        {
            "id":<mandatory. field identificator written in camel case on english>,
            "caption":<mandatory. how field been displayed to user >,
            "viewType":<mandatory. from list from below>,
            "placeholder":<optional. placeholder for user input>,
            "autocomplete":<optional. is autocomplete based on input history enabled for this field>,
            "visibilityOnList":<optional. Must be one of "Title" or "Subtitle". In list mode view, "Title" is used for the most important information, while "Subtitle" is used for rest details>,
            "style":<optional. object with CSS styles used for <td> for column data. example: {"maxWidth": "150px","wordWrap": "break-word"}>,
            "options":<optional. array of options for selection for field with predefined values. example:[{"label": "Created","value": "Created"},{"label": "Payed","value": "Payed"}]>,
            "textEllipsis": <optional. display text truncated to this number of characters>
            "fields": <optional. Fields for complex values (such as array or object). 'viewType' of this fields can't be '${CrmFieldViewType.Comments}' or '${CrmFieldViewType.Array}'.>
        }
    ICrmUserInfo:
        {
            login: string;
            role: 'User' | 'SuperUser';
        }
    ICrmValueOption:
        {
            value: string;
            label: string;
        }
    `,
});


const getPluginManagerPrompt = async (api: IApiForAIAgent) => `
You are AI plugin manager. Your goal is to manage plugins for CRM based on user query.
A plugin is any additional functionality in the system.
They come in two types: reports (requiring an additional page and menu item) and decorators (add or change the behavior or appearance of some system elements).
Based on user query you must classify task and use appropriate function call to solve it.

Plugin is a object with following structure: 
interface IPlugin {
    routes: IRoute[]; //routes used when separate page needed. It visible as menu item and used for display user requested data (reports for example must use this)
    components: ComponentImpl<any>[]; //reserved for future usage. must be []
    decorators: IReactComponentDecorator<any>[]; //Decorators are used to add or change the behavior or appearance of some system elements.
}

IRoute integrates in CRM frontend and contains custom jsx code for its purpose.

Tips:
- Plugin name used as a unique identifier for the plugin. Users mostly refer to the name as a menu caption (if plugin has).

IMPORTANT:
- After a plugin is created, changing the plugin name is forbidden as it is used as the plugin ID. Change the menu caption and plugin content instead.

Date time now is: ${new Date().toUTCString()}

There are list of currently installed plugins:
${await new ListPluginsTool(api).run('')}
`;

export class PluginManagerTool extends CompositeTool {
    name: string = "plugin_manager";
    crmApi: ICrmApi;

    pluginCreators: PluginCreatorTool[];
    toolkit: BaseTool[];
    description: string = `
useful for when user want to add custom behavior to CRM, report or decorator for example. This function can interact with code (generate, edit, etc.) that implements new custom behaviour.
Use this function if you need to create a new page, for example with a report, or change some functionality or appearance of system elements.
All additional functionality is called plugins.
They come in two types: reports (requiring an additional page and menu item) and decorators (add or change the behavior or appearance of some system elements).
When calling this function, you must clearly state which plugin you need to add: a report or a decorator.
You also need to come up with and provide names for each new plugin.
This tool have memory and remember past conversations in current session.
Input is text with human description of new behaviour. In multistep scenario input is a user comment.
Output is result of adding.
`;
    public getPrompt = (): Promise<string> => getPluginManagerPrompt(this.api);

    constructor(api: IApiForAIAgent, access_token: string) {
        super(api, access_token, 'gpt-4o', true);
        this.crmApi = createApi(api.getUserInfo()!);

        this.pluginCreators = [
            new CreateReportTool(api, access_token),
            new CreateDecoratorTool(api, access_token),
        ];

        this.toolkit = [
            new AskUserTool(api, this.crmApi),
            new UpdatePluginTool(api, this.pluginCreators),
            new DeleteModuleTool(api, this.crmApi),
            ...this.pluginCreators

        ];
    }

    async run(query: string): Promise<string> {
        try {
            console.log(`*${this.name} ${query}`);

            const agent = await this.createAgent();

            let response = await agent.invoke(
                { input: query }
            );

            return response.output;
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}