import styles from './ArrayEditorInner.module.scss';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import i18n from 'src/Locale/i18n';
import { ICrmField } from "@core/Models/tenantConfig.models";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ICrmArrayElement } from "@core/Models/i-array-element";
import Logger from "js-logger";
import { ConfirmDelete } from "@core/VisualComponents/ConfirmDelete/ConfirmDelete";
import { DateInput } from "src/App/Pages/Shared/OrderField/DateInput/DateInput";
import { StringInput } from "src/App/Pages/Shared/OrderField/StringInput/StringInput";
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { ComboboxInput } from 'src/App/Pages/Shared/OrderField/ComboboxInput/ComboboxInput';
import { CheckboxInput } from 'src/App/Pages/Shared/OrderField/CheckboxInput/CheckboxInput';
import { TimeInput } from 'src/App/Pages/Shared/OrderField/TimeInput/TimeInput';
import { DecimalInput } from 'src/App/Pages/Shared/OrderField/DecimalInput/DecimalInput';
import { InputValidator } from 'src/App/Pages/Shared/OrderField/InputValidator/InputValidator';
import { InvalidTypeInput } from 'src/App/Pages/Shared/OrderField/InvalidTypeInput/InvalidTypeInput';
import { UrlInput } from 'src/App/Pages/Shared/OrderField/UrlInput/UrlInput';
import { ArrayValueRenderer } from '../ArrayValuesViewer/ArrayValuesViewer';
import { ReactComponent as ChevronDownIcon } from "@assets/Icons/chevron-down-icon.svg";
import { ReactComponent as ChevronUpIcon } from "@assets/Icons/chevron-up-icon.svg";
import { ReactComponent as TrashCanOutlineIcon } from "@assets/Icons/trash-can-outline-icon.svg";
import { CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models.shared';
import { PhoneInput } from 'src/App/Pages/Shared/OrderField/PhoneInput/PhoneInput';
import { getCachedValuesFromDumbCrmDb } from '@core/JsStore/stores/autocomplete-store';

export interface IArrayEditorInnerProps {
    values: any;
    onChanged: (v: ICrmArrayElement[]) => void;
    setIsValid?: (isValid: boolean) => void;
    saveIfInvalid: boolean;
    readonly?: boolean;
    narrow?: boolean;
    tableId: string;
    field: ICrmField;
    reversed?: boolean;
}

export const ArrayEditorInner = registerComponent(coreUiComponentDescriptions.ArrayEditorInner, _ArrayEditorInner);

function _ArrayEditorInner(props: IArrayEditorInnerProps) {
    const [anchorRef, setAnchorRef] = useState<MutableRefObject<any> | null>(null);
    const [deleteData, setDeleteData] = useState<{id: string, index: number} | null>();
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false);
    const [invalidInputs, setInvalidInputs] = useState<{index: number, fieldId: string}[]>([]);
    const [values, setValues] = useState<(ICrmArrayElement | undefined)[]>(getArrayValues(props.values));
    const [autocompleteValues, setAutocompleteValues] = useState<Record<string, string[]>>();

    const loadAutocompleteValues = async () => {
        const newAutocompleteValues: Record<string, string[]> = {};
        
        for (const field of props.field.fields || []) {
            if (!field.autocomplete) {
                continue;
            }

            const cachedValues = await getCachedValuesFromDumbCrmDb(props.tableId, props.field.id + "." + field.id);

            // Merge autocomplete values ​​with current values ​​in array for correct autocomplete list
            newAutocompleteValues[field.id] = Array.from(
                new Set([
                    ...cachedValues,
                    ...values.filter(x => x != null && (x as any)[field.id] != null && typeof (x as any)[field.id] == "string").map(value => (value as any)[field.id].trim() as string),
                ])
            );
        }

        setAutocompleteValues(newAutocompleteValues);
    };

    useEffect(() => {
        loadAutocompleteValues();
    }, [values]);

    const onValueChanged = (newValues: (ICrmArrayElement | undefined)[]) => {
        const valuesForSave = newValues.filter(value => value !== undefined && !isEmptyValue(value)) as ICrmArrayElement[];
        if (props.values != null || valuesForSave.length > 0) {
            props.onChanged(valuesForSave);
        }
    }

    const isEmptyValue = (value?: ICrmArrayElement) => {
        if (value === undefined) {
            return true;
        }

        for (const field of props.field.fields || []) {
            if (field.id in value && (value as any)[field.id] != null && (value as any)[field.id] !== "") {
                return false;
            }
        }
        return true;
    }

    const onDeleteConfirmed = (id?: string, index?: number) => {
        if (!deleteData && !id) {
            Logger.error("[BirthdaysArray] No delete data");
            return;
        }
        setValues(prevValues => {
            const newValues = prevValues.map(value => value !== undefined && value.id != (deleteData?.id ?? id) ? value : undefined);
            onValueChanged(newValues);
            return newValues;
        });
        setInvalidInputs(prev => prev.filter(x => x.index != (deleteData?.index ?? index)));
        setDeleteData(null);
        setConfirmDeleteVisible(false);
    }

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string, index: number) => {
        if (props.readonly) {
            return;
        }

        setDeleteData({id, index});

        
        const value = values.find(v => v !== undefined && v.id == id);

        if (!value || isEmptyValue(value)) {
            onDeleteConfirmed(id, index);
            return;
        }

        setAnchorRef({current: e.currentTarget});
        setConfirmDeleteVisible(true);
    }

    const onCancelDelete = () => {
        setDeleteData(null);
        setConfirmDeleteVisible(false);
    }

    const onAddRow = () => {
        if (props.readonly) {
            return;
        }

        setValues(prevValues => {
            const newValues = [
                ...prevValues,
                { id: uuidv4() } as ICrmArrayElement,
            ];
            return newValues;
        });
    }

    const onChange = (oldId: string, value: ICrmArrayElement) => {
        setValues(prevValues => {
            const newValues = prevValues.map(e => e !== undefined && e.id == oldId ? value : e);
            onValueChanged(newValues);
            return newValues;
        });
    }

    const onValidChanged = (index: number, fieldId: string, isValid: boolean) => {
        if (isValid) {
            setInvalidInputs(prev => prev.filter(x => x.index != index || x.fieldId != fieldId));
        }
        else {
            setInvalidInputs(prev => {
                if (prev.find(x => x.index == index && x.fieldId == fieldId) == null) {
                    return [...prev, {index, fieldId}];
                }
                return prev;
            });
        }
    }

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(invalidInputs.length == 0);
        }
    }, [invalidInputs.length == 0]);

    if (props.narrow) {
        return <div className={props.reversed ? styles.narrowContainerReversed : styles.narrowContainer}>
            {values.map((value, index) => {
                if (value !== undefined) {
                    return <ArrayItemInput key={index}
                        value={value}
                        field={props.field}
                        onChange={onChange}
                        onDelete={(e, id) => onDelete(e, id, index)}
                        setIsValid={(f, v) => onValidChanged(index, f, v)}
                        saveIfInvalid={props.saveIfInvalid}
                        readonly={props.readonly}
                        narrow
                        expanded={isEmptyValue(value)}
                        autocompleteValues={autocompleteValues}
                    />;
                }
            })}
            <button className={styles.addRowButton} onClick={onAddRow} disabled={props.readonly}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path d="M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z"></path>
                </svg>
                {i18n.t("add-row")}
            </button>
            <ConfirmDelete
                visible={confirmDeleteVisible}
                triggerRef={anchorRef}
                onCancel={onCancelDelete}
                onDelete={onDeleteConfirmed}
                placement="bottom-end"
            />
        </div>;
    }

    return <>
        <div className={styles.container} style={{
            gridTemplateColumns: `repeat(${props.field.fields?.length ?? 0}, auto) 30px`,
        }}>
            {props.field.fields?.map(field =>
                <div key={field.id} className={styles.caption}>
                    <span className={styles.wrapper}></span>
                    {field.caption}
                    <span className={styles.wrapper}></span>
                </div>
            )}
            <div></div>
            {values.map((value, index) => { if (value !== undefined)
                return <ArrayItemInput key={index}
                    value={value}
                    field={props.field}
                    onChange={onChange}
                    onDelete={(e, id) => onDelete(e, id, index)}
                    setIsValid={(f, v) => onValidChanged(index, f, v)}
                    saveIfInvalid={props.saveIfInvalid}
                    readonly={props.readonly}
                    autocompleteValues={autocompleteValues}
                />}
            )}
        </div>
        <button className={styles.addRowButton} onClick={onAddRow} disabled={props.readonly}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path d="M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z"></path>
            </svg>
            {i18n.t("add-row")}
        </button>
        <ConfirmDelete
            visible={confirmDeleteVisible}
            triggerRef={anchorRef}
            onCancel={onCancelDelete}
            onDelete={onDeleteConfirmed}
            placement="bottom-end"
        />
    </>;
}

interface IArrayItemInputProps {
    value: ICrmArrayElement;
    field: ICrmField;
    onChange: (oldId: string, v: ICrmArrayElement) => void;
    onDelete: (e: React.MouseEvent<any>, id: string) => void;
    setIsValid?: (fieldId: string, isValid: boolean) => void;
    saveIfInvalid: boolean;
    autocompleteValues?: Record<string, string[]>;
    readonly?: boolean;
    narrow?: boolean;
    expanded?: boolean;
}

function ArrayItemInput(props: IArrayItemInputProps) {
    const [expanded, setExpanded] = useState<boolean>(props.expanded ?? false);
    const { value } = props;

    const handleExpanded = () => setExpanded(prev => !prev);

    const onChangeValueByField = (field: ICrmField, newValue: any) => {
        if (newValue != (value as any)[field.id] && (props.saveIfInvalid || InputValidator.validateByField(newValue, field))) {
            props.onChange(value.id, {
                ...value,
                id: uuidv4(),
                [field.id]: newValue,
            });
        }
    }

    const onDelete = (e: React.MouseEvent<any>, id: string) => {
        e.stopPropagation();
        props.onDelete(e, id);
    };

    const getInputByField = (field: ICrmField) => {
        const fieldValue = (value as any)[field.id];
        const onChanged = (v: any) => onChangeValueByField(field, v);
        const setIsValid = (v: boolean) => {
            if (props.setIsValid) {
                props.setIsValid(field.id, v);
            }
        };

        const commonProps = {
            className: styles.valueInput,
            value: fieldValue,
            initialValue: fieldValue,
            placeholder: field.placeholder,
            autocompleteValues: props.autocompleteValues?.[field.id],
            onChanged: onChanged,
            setIsValid: setIsValid,
            readonly: props.readonly || field.readonly,
            validation: (value: any) => InputValidator.validateByField(value, field),
        }

        switch (field.viewType) {
            case CrmFieldViewType.Date:
                return <DateInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Combobox:
                return <ComboboxInput
                    key={field.id}
                    {...commonProps}
                    options={field.options!}
                />;
            case CrmFieldViewType.YesNo:
                return <CheckboxInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Time:
                return <TimeInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.String:
                return <StringInput
                    key={field.id}
                    {...commonProps}
                    type="text"
                />;
            case CrmFieldViewType.MultiString:
                return <StringInput
                    key={field.id}
                    {...commonProps}
                    type="textarea"
                />;
            case CrmFieldViewType.Url:
                return <UrlInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Decimal:
                return <DecimalInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Phone:
                return <PhoneInput key={field.id} {...commonProps}/>;
            default:
                return <InvalidTypeInput key={field.id}
                    className={styles.valueInput}
                />;
        }
    }

    if (props.narrow) {
        return <div className={styles.narrowInputItem}>
            <div className={styles.narrowInputHeader} onClick={handleExpanded}>
                {expanded
                    ? <>
                        <div className={styles.chevronContainer}>
                            <ChevronUpIcon/>
                        </div>
                        <div></div>
                    </>
                    : <>
                        <div className={styles.chevronContainer}>
                            <ChevronDownIcon/>
                        </div>
                        <div className={styles.arrayValue}>
                            <ArrayValueRenderer arrayValue={value} field={props.field}/>
                        </div>
                    </>
                }
                <div onClick={e => onDelete(e, value.id)}>
                    <TrashCanOutlineIcon/>
                </div>
            </div>
            {expanded && props.field.fields?.map(field =>
                <div className={styles.narrowValueInputContainer} key={field.id}>
                    <span>{field.caption}</span>
                    <div>{getInputByField(field)}</div>
                </div>
            )}
        </div>;
    }

    return <>
        {props.field.fields?.map(field => <div className={styles.valueInputContainer} key={field.id}>{getInputByField(field)}</div>)}
        <button type="button" className={styles.deleteButton} onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onDelete(e, value.id)} disabled={props.readonly}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                <path d="M6.414 5l3.293 3.293a1 1 0 1 1-1.414 1.414L5 6.414 1.707 9.707A1 1 0 0 1 .293 8.293L3.586 5 .293 1.707A1 1 0 0 1 1.707.293L5 3.586 8.293.293a1 1 0 0 1 1.414 1.414L6.414 5z"></path>
            </svg>
        </button>
    </>;
}

function getArrayValues(values: any): ICrmArrayElement[] {
    if (Array.isArray(values) && values.length > 0) {
        return values as ICrmArrayElement[];
    }
    return [
        {
            id: uuidv4(),
        },
    ];
}
