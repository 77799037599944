import { ICrmCommentMessage } from "@core/Models/i-comment-message";
import { ICrmField } from "@core/Models/tenantConfig.models";
import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import styles from './CommentsEditView.module.scss';
import { useTranslation } from "react-i18next";
import { extractComments } from "src/Utils/commentsUtils";
import { useStore } from "@core/Stores/OrderStoreProvider";
import { useAppSelector } from "@core/Redux/hooks";
import { selectUserInfo } from '@core/Redux/store';
import { CommentsMessage } from "@core/VisualComponents/Comments/CommentsMessage";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ReactComponent as SendMessageIconSvg } from '@assets/Icons/send-message-icon.svg';
import { StringInput } from "src/App/Pages/Shared/OrderField/StringInput/StringInput";
import { Input } from "antd";

interface ICommentsEditViewProps {
    field: ICrmField;
    tableId: string;
    entityId: string;
    isReversed?: boolean;
    style?: CSSProperties;
}

export function CommentsEditView(props: ICommentsEditViewProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const [comments, setComments] = useState<ICrmCommentMessage[]>([]);
    const [removedCommentItems, setRemovedCommentItems] = useState<ICrmCommentMessage[]>([]);
    const chatDialogRef = useRef<HTMLInputElement>(null);
    const store = useStore(props.tableId);
    const userInfo = useAppSelector(selectUserInfo);

    const entity = store.useGet(props.entityId);

    useEffect(()=>{
        let comments = entity?.data[props.field.id] ?? [];
        setComments(extractComments(comments));
    }, [entity, props.field.id]);

    const isCommentRemoved = (comment: ICrmCommentMessage): boolean => {
        return removedCommentItems.find(x => x.id === comment.id) !== undefined;
    }
    const orderComments = (comments: ICrmCommentMessage[]) => {
        return _.sortBy(comments, ['date', 'id']);
    }

    const restoreComment = (comment: ICrmCommentMessage) => {
        let newComments = [...comments, comment];
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }

    const storeComment = (comment: ICrmCommentMessage) => {
        let newComments = [...comments, comment];
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }

    const removeComment = (comment: ICrmCommentMessage) => {
        let newComments = comments.filter(x => x.id !== comment.id);
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }

    const addMessage = () => {
        const commentItem = {
            id: uuidv4(),
            author: userInfo?.login,
            date: DateTime.utc().toUnixInteger(),
            message: inputValue
        } as ICrmCommentMessage;
        setComments(prev => [...prev, commentItem]);
        setInputValue('');

        storeComment(commentItem);
    };

    const isAddMessageAllowed = useCallback(() => {
        return !!inputValue && inputValue.trim().length > 0
    }, [inputValue])

    const handleAddMessage = useCallback(() => {
        if (isAddMessageAllowed()) {
            addMessage();
        }
    }, [isAddMessageAllowed]);

    const onDeleteClicked = useCallback((comment: ICrmCommentMessage) => {
        if (isCommentRemoved(comment)) {
            const restoredComment = removedCommentItems.find(x => x.id === comment.id);
            if (restoredComment) {
                setRemovedCommentItems(removedCommentItems.filter(x => x.id !== comment.id));
                restoreComment(restoredComment);
            }
        }
        else {
            setRemovedCommentItems([...removedCommentItems, comment]);
            removeComment(comment);
        }
    }, [comments, removedCommentItems]);

    const commentsToShow = useMemo(() => {
        return orderComments([...comments, ...removedCommentItems]);
    }, [comments]);

    const scrollDown = () => {
        chatDialogRef.current?.lastElementChild?.scrollIntoView({ block: props.isReversed ? "end" : "start"});
    };

    useEffect(() => {
        scrollDown();
    }, [commentsToShow.length]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }

    const renderMessage = (message: ICrmCommentMessage) => {
        return (
            <CommentsMessage
                key={message.id}
                text={message.message}
                date={message.date}
                author={message.author}
                isDeleted={isCommentRemoved(message)}
                onDelete={() => onDeleteClicked(message)}
            />
        );
    };
    
    return (
        <div className={`${styles.chatWindow} ${props.isReversed && styles.reversed}`} style={props.style}>
            <div className={`${styles.chatDialog} ${props.isReversed && styles.reversed}`} ref={chatDialogRef}>
                {commentsToShow.map((message) => renderMessage(message))}
            </div>
            <div className={styles.inputContainer}>
                <Input
                    value={inputValue}
                    placeholder={props.field.placeholder}
                    className={styles.chatInput}
                    style={props.style}
                    onChange={onChange}
                    onPressEnter={handleAddMessage}
                    disabled={props.field.readonly}
                    allowClear
                    autoFocus
                />
                <PrimaryButton style={{ padding: "6px 16px" }} onClick={handleAddMessage} disabled={props.field.readonly}>
                    <SendMessageIconSvg style={{ height: "100%" }} className='addMessageIcon'/>
                </PrimaryButton>
            </div>
        </div>
    );
}