import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
//import {fetchOrdersAsync, IFetchOrdersQuery} from '@core/Redux/Slices/ordersSlice/thunks/fetchOrdersAsync';
//import {refetchOrdersAsync} from '@core/Redux/Slices/ordersSlice/thunks/refetchOrdersAsync';
import {updateOrderAsync} from '@core/Redux/Slices/ordersSlice/thunks/updateOrderAsync';
import {deleteOrderAsync} from '@core/Redux/Slices/ordersSlice/thunks/deleteOrderAsync';
import {addOrderAsync} from '@core/Redux/Slices/ordersSlice/thunks/addOrderAsync';
//import {fetchMoreAsync} from '@core/Redux/Slices/ordersSlice/thunks/fetchMoreAsync';
import {IEntity, IEntityData} from '@core/Models/i-entity';
import _ from 'lodash';
//import {updateChangedOrdersAsync} from '@core/Redux/Slices/ordersSlice/thunks/updateChangedOrdersAsync';
import {initApplicationAsync} from '@core/Redux/Slices/appSlice';
import {updateArrayAsync} from './thunks/updateArrayAsync';
import { updateEntityCountAsync } from './thunks/updateOrdersCountAsync';


export interface IQueryStatus {
    status: 'idle' | 'loading' | 'failed';
    resultLength: number;
    // skip: number;
    // limit: number;
    // sortDirection: 'asc'|'desc';
}


export interface EntitiesState {
    entitiesIds: string[];
    entities: Record<string, IEntity>;
    status: IQueryStatus;
    uploadingQueueLength: number;
    downloadingProgress: number | null;
    overallEntitiesCount?: number;
}

const initialEntitiesState: EntitiesState = {
    entitiesIds: [],
    entities: {},
    status: {resultLength: 0, status: 'loading'},
    uploadingQueueLength: 0,
    downloadingProgress: null,
    overallEntitiesCount: 0
}

type StoreState = Record<string, EntitiesState>;

const initialState: StoreState = {};

export function mergeOrderChanges(order: IEntityData, orderChanges: IEntityData) {
    for (let key in orderChanges) {
        if (key === 'id')
            continue;

        order[key] = orderChanges[key];
    }
}

export const storesSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        // init(state, action:PayloadAction<string[]>) {
        //     for (let tableId of action.payload) {
        //         state[tableId] = {...initialEntitiesState};
        //     }
        // },
        clearLoadedEntitySet: (state, action:PayloadAction<string>): StoreState => {
            const tableId = action.payload;
            if (state && state[tableId] != null){
                let tableState = state[tableId] ?? initialEntitiesState;
                tableState = {
                    ...tableState,
                    entities: {},
                    entitiesIds: [],                     
                }

                return {
                    ...state,
                    [tableId]: tableState
                }
                
            } else {
                return {...initialState};
            }
        },
        updateEntitySet: (state, action: PayloadAction<{tableId: string, entities:IEntity[]}>): StoreState => {
            const tableId = action.payload.tableId;
            let tableState = state[tableId] ?? initialEntitiesState;

            let newEntities: Record<string, IEntity> = {};

            action.payload.entities.forEach(x => {
                newEntities[x.id] = x;
            })

            tableState = {
                ...tableState,
                entitiesIds: action.payload.entities.map(x => x.id),
                entities: newEntities
            }

            return {
                ...state,
                [tableId]: tableState,
            };
        },

        updateIndividualEntities: (state, action: PayloadAction<{tableId: string, entities:IEntity[]}>): StoreState => {
            const tableId = action.payload.tableId;
            let tableState = state[tableId] ?? initialEntitiesState;

            let newEntities: Record<string, IEntity> = {...tableState.entities};
            
            action.payload.entities.forEach(x => {
                newEntities[x.id] = x;
            });

            tableState = {
                ...tableState,
                entities: newEntities
            };

            return {
                ...state,
                [tableId]: tableState,
            };
        },

        updateStatus: (state, action: PayloadAction<{tableId: string, status: EntitiesState["status"]}>): StoreState => {
            const tableId = action.payload.tableId;
            let tableState = state[tableId] ?? initialEntitiesState;

            tableState = {
                ...tableState,
                status: action.payload.status
            };

            return {
                ...state,
                [tableId]: tableState,
            };
        },

        updateUploadingQueueLength: (state: StoreState, action: PayloadAction<{tableId: string, length: number}>): StoreState => {
            const tableId = action.payload.tableId;
            let tableState = state[tableId] ?? initialEntitiesState;

            tableState = {
                ...tableState,
                uploadingQueueLength: action.payload.length
            };

            return {
                ...state,
                [tableId]: tableState,
            };
        },

        updateDownloadingProgress: (state: StoreState, action: PayloadAction<{tableId: string, length: number|null}>): StoreState => {
            const tableId = action.payload.tableId;
            let tableState = state[tableId] ?? initialEntitiesState;

            tableState = {
                ...tableState,
                downloadingProgress: action.payload.length
            };

            return {
                ...state,
                [tableId]: tableState,
            };

        },
    },
    extraReducers: builder => {
        builder
            .addCase(initApplicationAsync.pending, (state) => {
                //state.status = 'loading';
            })

            .addCase(updateOrderAsync.pending, (state) => {
                //state.status = 'loading';
            })
            .addCase(updateOrderAsync.rejected, (state) => {
                //state.status = 'failed';
            })
            .addCase(updateOrderAsync.fulfilled, (state, action: PayloadAction<{tableId: string, id: string, changes: IEntityData}>) => {
                const {tableId, id, changes} = action.payload;
                let tableState = state[tableId] ?? initialEntitiesState;

                const entity = tableState.entities[id];
                if (entity) {
                    mergeOrderChanges(entity, changes);
                }
    
                return state;

                //state.status = 'idle';
            })

            .addCase(updateArrayAsync.pending, (state) => {
                //state.status = 'loading';
            })
            .addCase(updateArrayAsync.rejected, (state) => {
                //state.status = 'failed';
            })
            .addCase(updateArrayAsync.fulfilled, (state, action: PayloadAction<{tableId: string, id: string, data: IEntityData}>) => {
                const {tableId, id, data} = action.payload;
                let tableState = state[tableId] ?? initialEntitiesState;

                const entity = tableState.entities[id];
                if (entity) {
                    mergeOrderChanges(entity, data);
                }
                //state.status = 'idle';

                return state;
            })

             .addCase(deleteOrderAsync.pending, (state) => {
                //state.status = 'loading';
            })
            .addCase(deleteOrderAsync.rejected, (state) => {
                //state.status = 'failed';
            })

            .addCase(addOrderAsync.pending, (state) => {
                //state.status = 'loading';
            })
            .addCase(addOrderAsync.rejected, (state) => {
                //state.status = 'failed';
            })

            .addCase(updateEntityCountAsync.fulfilled, (state, action: PayloadAction<{tableId: string, count: number}>) => {
                const tableId = action.payload.tableId;
                let tableState = state[tableId] ?? initialEntitiesState;

                tableState = {
                    ...tableState,
                    overallEntitiesCount: action.payload.count
                }
    
                return {
                    ...state,
                    [tableId]: tableState,
                };
            })
    }
});

export const selectEntitiesState = (tableId: string) => (state: RootState) => state.stores[tableId] ?? initialEntitiesState ;

export const selectEntitiesIds = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).entitiesIds;
export const selectEntitiesAreLoading = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).status?.status === 'loading';
export const selectQueryStatus = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).status;

export const selectEntitiesCount = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).overallEntitiesCount;
export const selectIsNoEntities = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).overallEntitiesCount !== undefined && state.stores[tableId]?.overallEntitiesCount === 0;
export const selectEntityById = (tableId: string, entityId: string) => (state: RootState) => selectEntitiesState(tableId)(state).entities?.[entityId] || {};
export const selectUploadingQueueLength = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).uploadingQueueLength;
export const selectUploadingQueueNotEmpty = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).uploadingQueueLength > 0;
export const selectDownloadingProgress = (tableId: string) => (state: RootState) => selectEntitiesState(tableId)(state).downloadingProgress;

export const { updateEntitySet, updateIndividualEntities, updateStatus, updateUploadingQueueLength, updateDownloadingProgress, clearLoadedEntitySet } = storesSlice.actions;

export default storesSlice.reducer;
