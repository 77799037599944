import {DATA_TYPE, ITable} from 'jsstore';
import {autocompleteTableName} from '@core/JsStore/stores/autocomplete-store';

export function createAutocompleteTable(tableId: string): ITable {
    return {
        name: autocompleteTableName(tableId),
        columns: {
            fieldName: {
                dataType: DATA_TYPE.String,
                primaryKey: true,
                notNull: true,
                unique: true
            },
            valuesJson: {
                dataType: DATA_TYPE.String,
            }
        }
    };
}
