import {createAsyncThunk} from '@reduxjs/toolkit';
import {v4 as uuidv4} from 'uuid';
import {DateTime} from 'luxon';
import {IEntity, IEntityData} from '@core/Models/i-entity';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import { IEventSourcingStore } from '@core/EventSourcing/EventSourcingStore';

export const addOrderAsync = createAsyncThunk(
    'orders/addOrderAsync',
    async ( args : { store : IEventSourcingStore, data: IEntityData}, {dispatch}) => {
        try {
            const time = performance.now();
            const orderId = uuidv4();
            Logger.debug('addOrderAsync', {id: orderId, data:args.data});

            const modifiedOrderData = {...args.data, createdAt: DateTime.utc().toUnixInteger()};
            const entity: IEntity = {id: orderId, data: modifiedOrderData, _lastEventNumber: undefined};
            await args.store.add(entity);
            Logger.debug(`add order spent ${performance.now() - time} ms`);
        } catch (err: any ) {
            Logger.error('addOrderAsync failed');
            Logger.error(serializeError(err, { maxDepth: 2}));
            throw new Error(err);
        }
    }
);
