import type { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import type { ISnapshotStore } from "@core/Stores/OrderSnapshotStore";
import _ from "lodash";
import Logger from "js-logger";

export class CachedStore{
    private cache: Map<string, IDbEntity|null>;
    private preloaded: Set<string>;
    private persistentStore : ISnapshotStore;

    constructor(persistentStore : ISnapshotStore, ) {
        this.cache = new Map<string, IDbEntity>();
        this.persistentStore = persistentStore;
        this.preloaded = new Set();
    }

    public async preload(entityIds: string[]) : Promise<void> {
        let entities = await this.persistentStore.bulkGet(entityIds);
        if (!entities)
            return;

        for (let i = 0; i < entityIds.length; i++) {
            if (entities[i]) {
                this.cache.set(entityIds[i], entities[i]!);
            }
        }
        this.preloaded = new Set(entityIds);
    }

    public async get(entityId: string, cache: boolean = true): Promise<IDbEntity | null> {
        if (!this.preloaded.has(entityId) && !this.cache.has(entityId)) {
            let entity = await this.persistentStore.get(entityId);
            if (!entity)
                entity = null;
            this.cache.set(entityId, entity);
            this.preloaded.add(entityId);
        }

        return this.cache.get(entityId)!;
    }

    public async insert(entityId: string, value: IDbEntity): Promise<boolean> {
        if (await this.get(entityId)) {
            return false;
        }

        this.cache.set(entityId, value);
        return true;
    }

    public async upsert(entityId: string, value: IDbEntity): Promise<void> {
        this.preloaded.add(entityId);
        this.cache.set(entityId, value);
    }

    public async remove(entityId: string): Promise<void> {
        this.preloaded.add(entityId);
        this.cache.set(entityId, null);
        await this.persistentStore.remove(entityId);
    }

    public async flush(): Promise<void> {
        try {
            const entites: IDbEntity[] = Array.from(this.cache.values()).filter(x=>x != null) as IDbEntity[];
            await this.persistentStore.bulkPut(entites);
            this.preloaded.clear();
            this.cache.clear();
        }
        catch (ex: any) {
            Logger.error(`[CachedStore] flush failed`, ex);
        }
    }

    public async clear() : Promise<void> {
        this.preloaded.clear();
        this.cache.clear();
    }
}
