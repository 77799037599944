import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import styles from "./CreateOrEditEntity.module.scss";
import { extenedLogsEnabled } from "@core/Services/logger-service";
import Logger from "js-logger";
import { useStore } from '@core/Stores/OrderStoreProvider';
import { IEntityData } from "@core/Models/i-entity";
import { t } from "i18next";
import { PrimaryButton } from "@core/VisualComponents/Buttons/PrimaryButton";
import { SecondaryButton } from "@core/VisualComponents/Buttons/SecondaryButton";
import { IOrderDialogChanges } from "../../Shared/Models/i-order-changes";
import { ICrmField } from "@core/Models/tenantConfig.models";
import { useAppDispatch, useAppSelector } from "@core/Redux/hooks";
import { selectTableConfig } from "@core/Redux/store";
import { OrderField } from "../../Shared/OrderField/OrderField";
import { ICrmArrayElement } from "@core/Models/i-array-element";
import { updateArrayAsync } from "@core/Redux/Slices/ordersSlice/thunks/updateArrayAsync";
import { updateOrderAsync } from "@core/Redux/Slices/ordersSlice/thunks/updateOrderAsync";
import _ from "lodash";
import { addOrderAsync } from "@core/Redux/Slices/ordersSlice/thunks/addOrderAsync";
import { DeviceTypeContext } from "@core/Contexts/DeviceTypeContext";
import { useNavigate } from "react-router-dom";
import { CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models.shared";
import { ModalWindow } from "@core/VisualComponents/ModalWindow/ModalWindow";
import { CommentsEditView } from "@core/VisualComponents/Comments/CommentsEditView";
import { Breadcrumb } from "antd";
import { InputValidator } from "../../Shared/OrderField/InputValidator/InputValidator";

declare type PageStatus = 'loading' | 'loaded' | 'not_found' | 'failed';

export interface ICreateOrEditEntityCommands {
    onUpdate?: () => void;
    onCreate?: () => void;
}

export interface ICreateOrEditEntityProps {
    tableId: string;
    entityId?: string;

    orderChanged: MutableRefObject<boolean>;
    externalCommands?: MutableRefObject<ICreateOrEditEntityCommands>;
    onCreateOrUpdateOrderClicked: () => void;
    onCancelClicked: () => void;
    showSaveButtons?: boolean;
}

export function CreateOrEditEntity(props: ICreateOrEditEntityProps) {
    const [pageStatus, setPageStatus] = useState<PageStatus>(props.entityId ? 'loading' : 'loaded');
    const [editState, setEditState] = useState<any>({});
    const [origOrderData, setOrigOrderData] = useState<IEntityData>({});
    const [orderData, setOrderData] = useState<IEntityData>({});
    const [invalidFields, setInvalidFields] = useState<ICrmField[]>([]);
    const [editingComments, setEditingComments] = useState<string | null>(null);

    const orderChanges = useRef<IOrderDialogChanges>({ id: props.entityId ?? "", data: {} });

    const tableConfig = useAppSelector(selectTableConfig(props.tableId));
    const tableName = tableConfig?.tableName ?? '';
    const entityFields = tableConfig?.fields || [];

    const store = useStore(props.tableId);
    const dispatch = useAppDispatch();
    const deviceType = useContext(DeviceTypeContext);
    const navigator = useNavigate();

    const extended_logs = extenedLogsEnabled();

    const isEmptyValue = (value?: IEntityData) => {
        if (value == null) {
            return true;
        }

        for (const field of entityFields) {
            const innerValue = value[field.id];
            if (innerValue != null && innerValue !== "" && (!Array.isArray(innerValue) || innerValue.length > 0)) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        if (extended_logs)
            Logger.debug("OrderPage mounted");

        if (props.entityId) {
            store.get(props.entityId!)
                .then(order => {
                    if (!order) {
                        setPageStatus('not_found');
                        return;
                    }

                    setOrderData(order.data);
                    setOrigOrderData(order.data);
                    setPageStatus('loaded');
                })
                .catch(err => {
                    setPageStatus('failed');
                    throw new Error(`Unhandled error during reading order by id ${JSON.stringify(err)}`);
                });
        }

        return () => {
            if (extended_logs)
                Logger.debug("OrderPage unmounted");
        };
    }, []);

    const onValueChanged = (field: ICrmField, value: ICrmArrayElement[] | string | number | boolean | null) => {
        if (extended_logs)
            Logger.debug(`OrderPage field changed: ${field.id}, ${value}`);

        if (!_.isEqual(orderData[field.id], value)) {
            orderChanges.current.data[field.id] = value;
            props.orderChanged.current = true;
            setOrderData(prev => ({ ...prev, [field.id]: value }));
        }
    };

    const onValidChanged = (field: ICrmField, isValid: boolean) => {
        if (isValid) {
            setInvalidFields(prev => prev.filter(x => x.id != field.id));
        }
        else {
            setInvalidFields(prev => {
                if (prev.find(x => x.id == field.id) == null) {
                    return [...prev, field];
                }

                return prev;
            });
        }
    }

    const onUpdateOrderClicked = () => {
        if (extended_logs) {
            Logger.debug("OrderPage update clicked");
        }
        
        // eslint-disable-next-line no-restricted-globals
        const cancelSave = invalidFields.length > 0 && !confirm(`${t("invalid_fields_wont_be_saved")}:\n${invalidFields.map(field => ` - ${field.caption}`).join(";\n")}.`);

        if (cancelSave) {
            return;
        }

        props.orderChanged.current = false;

        const entityData: IEntityData = {};

        for (let fieldId of Object.keys(orderChanges.current.data)) {
            let field = entityFields.find(x => x.id === fieldId);
            if (!field) {
                Logger.error("OrderPage edit unknown field");
                continue;
            }

            if (invalidFields.find(x => x.id == field?.id)) {
                Logger.debug(`Skip field '${field.id}' because of the invalid value`);
                continue;
            }

            const viewType = field.viewType;
            const id = field.id;
            let value = orderChanges.current.data[fieldId];
            const prevValue = origOrderData[fieldId];

            switch (viewType) {
                case CrmFieldViewType.Timeline:
                case CrmFieldViewType.Comments:
                case CrmFieldViewType.Array: {
                    const array = value as ICrmArrayElement[];
                    dispatch(updateArrayAsync({
                        store: store,
                        oldValues: prevValue as ICrmArrayElement[],
                        newValues: array,
                        fieldId: id,
                        entityId: orderChanges.current.id
                    }))
                    break;
                }
                default:
                    if (!InputValidator.isEqual(value, prevValue)) {
                        entityData[id] = value;
                    }
                    break;
            }
        }
        
        if (!_.isEmpty(entityData)) {
            dispatch(updateOrderAsync({store, id: orderChanges.current.id, changes: entityData}));
        }

        props.onCreateOrUpdateOrderClicked();
    };

    const onCreateOrderClicked = () => {
        if (extended_logs) {
            Logger.debug("[CreateEntityPage] create clicked");
        }

        if (tableConfig == null) {
            Logger.error(`[CreateEntityPage] no table config for ${props.tableId}`);
            return;
        }

        if (store == null) {
            Logger.error(`[CreateEntityPage] no store for ${props.tableId}`);
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        const cancelSave = invalidFields.length > 0 && !confirm(`${t("invalid_fields_wont_be_saved")}:\n${invalidFields.map(field => ` - ${field.caption}`).join(";\n")}.`);

        if (cancelSave) {
            return;
        }

        props.orderChanged.current = false;

        let orderData = {...orderChanges.current.data};

        for (let fieldId of Object.keys(orderData)) {
            let field = entityFields.find(x => x.id === fieldId);
            if (!field) {
                Logger.error("[CreateEntityPage] edit unknown field");
                continue;
            }

            if (invalidFields.find(x => x.id == field?.id)) {
                Logger.debug(`Skip field '${field.id}' because of the invalid value`);
                delete orderData[fieldId];
                continue;
            }
        }

        dispatch(addOrderAsync({ store, data: orderData }));
        
        props.onCreateOrUpdateOrderClicked();
    };

    const onOpenComments = (fieldId: string) => {
        if (deviceType.isMobile) {
            navigator(fieldId);
        }
        else {
            setEditingComments(fieldId);
        }
    }

    if (props.externalCommands) {
        if (props.entityId) {
            props.externalCommands.current.onUpdate = onUpdateOrderClicked;
        }
        else {
            props.externalCommands.current.onCreate = onCreateOrderClicked;
        }
    }
    
    return <div className={styles.host}>
        {pageStatus == 'loading' && <span>{t("loading_status")}</span>}
        {pageStatus == 'not_found' && <span>{t("order_not_found_status")}</span>}
        {pageStatus == 'failed' && <span>{t("loading_error_status")}</span>}
        {pageStatus == 'loaded' && entityFields
            .map(field => <OrderField key={field.id}
                tableId={props.tableId}
                entityId={props.entityId!}
                entityData={orderData}
                field={field}
                value={orderData[field.id] || null}
                editState={editState}
                setEditState={setEditState}
                onValueChanged={v => onValueChanged(field, v)}
                setIsValid={v => onValidChanged(field, v)}
                onOpenComments={onOpenComments}
            />)}
        {invalidFields.length > 0 &&
            <div className={styles.invalidContainer}>
                <p className={styles.invalidTitle}>{t("invalid_fields_list")}:</p>
                <ul className={styles.invalidList}>
                    {invalidFields.map(field => <li key={field.id}>{field.caption};</li>)}
                </ul>
            </div>
        }
        {props.showSaveButtons &&
            <div className={styles.buttonsPanel}>
                <SecondaryButton title="Cancel" onClick={props.onCancelClicked}>
                    <span>{t("cancel")}</span>
                </SecondaryButton>
                {props.entityId
                    ? <PrimaryButton title="Save" onClick={onUpdateOrderClicked}>
                        <span>{t("save")}</span>
                    </PrimaryButton>
                    : <PrimaryButton title="Create" onClick={onCreateOrderClicked}>
                        <span>{t("create")}</span>
                    </PrimaryButton>
                }
            </div>
        }
        {editingComments == null ||
            <ModalWindow
                onHide={() => setEditingComments(null)}
                title={<Breadcrumb className={styles.breadcrumb} items={[
                    { title: <a onClick={() => setEditingComments(null)}>{t("edit-record")}</a> },
                    { title: entityFields.find(x => x.id == editingComments)!.caption },
                ]}/>}
            >
                <CommentsEditView
                    field={entityFields.find(x => x.id == editingComments)!}
                    tableId={props.tableId}
                    entityId={props.entityId!}
                    isReversed
                />
            </ModalWindow>
        }
    </div>;
}
