import { CommentsDateTime } from './CommentsDateTime';
import { ReactComponent as TrashCanSvg } from '@assets/Icons/trash-can-icon.svg';
import styles from './CommentsMessage.module.scss';
import { CrmText } from '../CrmText';
import { t } from 'i18next';

export interface ICommentsMessageProps {
    key?: string
    text: string;
    date: number;
    author?: string;
    isDeleted?: boolean;
    onDelete?: () => void;
}

export function CommentsMessage(props: ICommentsMessageProps) {
    return (
        <div className={styles.host}>
            <p className={`${styles.text} ${props.isDeleted && styles.deleted}`}>
                <CrmText text={props.text}/>
            </p>
            <div className={styles.deleteButton} onClick={props.onDelete}>
                {props.onDelete && <TrashCanSvg title={t("delete")}/>}
            </div>
            <CommentsDateTime date={props.date}/>
            <div className={styles.author}>
                <CrmText text={props.author || ""}/>
            </div>
        </div>
    );
}