import { CSSProperties, MutableRefObject, useEffect, useState } from 'react';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { Button, Dropdown, Input, MenuProps, Select, Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
import { PhoneFilled } from '@ant-design/icons'
import styles from './PhoneInput.module.scss';
import { useAppSelector } from '@core/Redux/hooks';
import { selectUserInfo } from '@core/Redux/store';
import { extractStrictCleanPhones } from '@core/Helpers/phoneNumbers';
import { StringInput } from '../StringInput/StringInput';

export interface IPhoneInputProps {
    initialValue?: string | null;
    placeholder?: string;
    autocompleteValues?: string[];
    onChanged?: (value: string | null) => void;
    onEnter?: () => void;
    onCall?: () => void;
    setIsValid?: (isValid: boolean) => void;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    disabled?: boolean;
    valueRef?: MutableRefObject<string | null>;
    autoFocus?: boolean;
    validation?: (value: string) => boolean;
    delayOnChanged?: boolean;
}

export interface ICallButtonProps {
    value?: string | null;
    userPhone: string | null;
    onCall?: (userPhone: string, targetPhone: string) => void;
}

export const CallButton = registerComponent(coreUiComponentDescriptions.CallButton, _CallButton);

function _CallButton(props: ICallButtonProps) {
    const { t } = useTranslation();

    const onCall = (phone: string)=> {
        if (props.onCall != null) {
            if (props.userPhone != null)
                props.onCall(props.userPhone, phone);
            else
                alert(t("user-phone-not-set"));
        } else {
            window.location.href = "callto:" + phone;
        }
    }

    if (props?.value == null)
        return (
            <Tooltip placement="topLeft" title={t("input-not-contains-phones")}>
                <Button className={styles.phoneButton} disabled={true}><PhoneFilled /></Button>
            </Tooltip>
        );


    const phones = extractStrictCleanPhones(props.value);

    if (phones.length > 1) {
        const items: MenuProps['items'] = phones.map( phone => ({
            key: phone,
            label: <a target='_blank' onClick={() => onCall(phone)}>{phone}</a>
        }));

        return (
            <Dropdown menu={{ items }} placement="bottomRight">
                <Button className={styles.phoneButton}><PhoneFilled /></Button>
            </Dropdown>
        );
    } else if (phones.length == 1) {
        const phone = phones[0];
        return <Button className={styles.phoneButton} onClick={()=> onCall(phone)}><PhoneFilled /></Button>
    } else {
        return (
            <Tooltip placement="topLeft" title={t("input-not-contains-phones")}>
                <Button className={styles.phoneButton} disabled={true}><PhoneFilled /></Button>
            </Tooltip>
        );
    }
}

export const PhoneInput = registerComponent(coreUiComponentDescriptions.PhoneInput, _PhoneInput);

function _PhoneInput(props: IPhoneInputProps) {
    const userInfo = useAppSelector(selectUserInfo);
    const [currentValue, setCurrentValue] = useState(props.initialValue);

    const onChanged = (value: string | null) => {
        setCurrentValue(value);
        props.onChanged?.(value);
    }

    const render = () => {

        return <div className={styles.phoneInput}>
            <StringInput
                {...props}
                onChanged={onChanged}
            />
            <CallButton value={currentValue} userPhone={userInfo?.config?.phone}/>

        </div>;
    }

    return render();
}
