import { CSSProperties, MutableRefObject, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { InvalidSpan } from '../InvalidHint/InvalidHint';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface ICheckboxInputProps {
    initialValue: boolean | null;
    placeholder?: string;
    onChanged?: (value: boolean) => void;
    valueRef?: MutableRefObject<boolean | null>;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    setIsValid?: (isValid: boolean) => void;
    validation?: (value: any) => boolean;
}

export const CheckboxInput = registerComponent(coreUiComponentDescriptions.CheckboxInput, _CheckboxInput);

function _CheckboxInput(props: ICheckboxInputProps) {
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        return value == null || typeof value == "boolean";
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));
    const [currValue, setCurrValue] = useState<boolean>(!!props.initialValue);

    useEffect(() => {
        props.setIsValid?.(isValid);
    }, [isValid]);

    const onChange = (e: any) => {
        props.onChanged?.(e.target.checked);
        setCurrValue(e.target.checked);
        setIsValid(true);
        if (props.valueRef) {
            props.valueRef.current = e.target.checked;
        }
    }

    return <label className={props.className}>
        <Checkbox
            checked={isValid ? currValue : false}
            disabled={props.readonly}
            style={props.style}
            onChange={onChange}
        />
        {props.placeholder && <span>&nbsp;{props.placeholder}</span>}
        {isValid ||
            <InvalidSpan/>
        }
    </label>;
}
