import React, { FormEvent, useState } from 'react';
import styles from './SearchBar.module.scss';
import { useTranslation } from 'react-i18next';
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";
import { registerComponent } from '@core/Plugins/pluginManager';
import { CSSProperties } from 'styled-components';
import { ReactComponent as CloseButton } from "@assets/Icons/close-button-icon.svg";
import { Input } from "antd";
const { Search } = Input;

export interface ISearchBarProps {
    onSearch: (query: string) => void;
    onClearSearch: () => void;
    style?: CSSProperties
}

export const SearchBar = registerComponent(coreUiComponentDescriptions.SearchBar, _SearchBar);

function _SearchBar(props : ISearchBarProps) {
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (searchQuery.trim() === "") {
            return;
        }

        console.log('Search for: ', searchQuery);
        props.onSearch(searchQuery.trim());
        setIsSearchActive(true);
        // Implement your search logic here
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const onClearSearch = () => {
        setIsSearchActive(false);
        setSearchQuery("");
        props.onClearSearch();
    }

    return (
        <div className={styles.container} style={props.style}>
            <form onSubmit={handleSubmit} className={styles.form}>
                {isSearchActive
                    ? <span className={styles.searchResultContainer}>
                        <span className={styles.searchResult}>
                            {searchQuery}
                            <i className="fas fa-times" onClick={onClearSearch}></i>
                        </span>
                    </span>
                    : <input
                        type="text"
                        value={searchQuery}
                        onChange={handleInputChange}
                        placeholder={t("search") + "..."}
                        className={styles.input}
                    />
                }
                <button type="submit" className={styles.button}>
                    <i className={`fas fa-search ${styles.icon}`}></i>
                </button>
            </form>
        </div>
    );
};
