import Modal from 'react-modal';
import styles from "./ModalWindow.module.scss";
import { useEffect, useState } from "react";
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { Button, Drawer, Space } from 'antd';
import { t } from 'i18next';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';

export interface IModalWindowProps {
    title?: string | JSX.Element;
    onHide: () => void;
    children?: any;
    onCancel?: () => void;
    onSave?: () => void;
    saveButtonText?: string;
}

export const ModalWindow = registerComponent(coreUiComponentDescriptions.ModalWindow, _ModalWindow);

function _ModalWindow(props: IModalWindowProps) {
    const [isOpen, setIsOpen] = useState(true);
    const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);

    const escFunction = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            props.onHide();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const afterOpenChange = (open: boolean) => {
        if (!open) {
            if (isSaveClicked) {
                props.onSave?.();
                setIsSaveClicked(false);
            }
            else {
                props.onCancel?.();
                props.onHide();
            }
        }
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const onSave = () => {
        setIsSaveClicked(true);
        setIsOpen(false);
    }

    const ButtonsPanel = () => <Space>
        {props.onCancel && <SecondaryButton onClick={onClose}>{t("cancel")}</SecondaryButton>}
        {props.onSave && <PrimaryButton onClick={onSave}>{props.saveButtonText ?? t("save")}</PrimaryButton>}
    </Space>

    return <Drawer
        title={props.title && <>
            {typeof props.title == "string"
                ? <h1 className={styles.title}>{props.title}</h1>
                : <div className={styles.header}>{props.title}</div>}
        </>}
        size='large'
        afterOpenChange={afterOpenChange}
        onClose={onClose}
        open={isOpen}
        extra={<ButtonsPanel/>}
        destroyOnClose
    >
        {props.children}
        <ButtonsPanel/>
    </Drawer>;
}