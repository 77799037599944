import { MutableRefObject, useEffect } from 'react';
import styles from './ArrayEditorModal.module.scss';
import Modal from 'react-modal';
import { ArrayEditorInner } from '../ArrayEditorInner/ArrayEditorInner';
import i18n from 'src/Locale/i18n';
import { ICrmField } from '@core/Models/tenantConfig.models';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface IArrayEditorModalProps {
    targetRef: MutableRefObject<any>;
    onHide: () => void;
    onSave: (value: any | null) => void;
    values: any;
    fields: ICrmField[];
    columnName: string;
}

// deprecated
export const ArrayEditorModal = registerComponent(coreUiComponentDescriptions.ArrayEditorModal, _ArrayEditorModal);

function _ArrayEditorModal(props: IArrayEditorModalProps) {
    const escFunction = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            props.onHide();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return <Modal
        isOpen={true}
        onRequestClose={props.onHide}
        appElement={props.targetRef.current}
        className={styles.content}
        overlayClassName={styles.overlay}
    >
        <h1>{i18n.t("edit-modal-title")} {props.columnName}</h1>
        <div className={styles.container}>
            {/* <ArrayEditorInner
                values={props.values}
                fields={props.fields}
                onChanged={props.onSave}
                saveIfInvalid={false}
            /> */}
        </div>
        <button type="button" className={styles.closeButton} onClick={props.onHide}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                <path d="M6.414 5l3.293 3.293a1 1 0 1 1-1.414 1.414L5 6.414 1.707 9.707A1 1 0 0 1 .293 8.293L3.586 5 .293 1.707A1 1 0 0 1 1.707.293L5 3.586 8.293.293a1 1 0 0 1 1.414 1.414L6.414 5z"></path>
            </svg>
        </button>
    </Modal>;
}