import { t } from "i18next";
import styles from './CrmCellInternal.module.scss';
import { InvalidHint } from "src/App/Pages/Shared/OrderField/InvalidHint/InvalidHint";

export interface ICrmCellInternalErrorProps {
    value?: any;
    hint?: string;
}

export function CrmCellInternalError(props: ICrmCellInternalErrorProps) {
    let value = props.value;
    if (value == null || (typeof value != "string" && typeof value != "number")) {
        value = t("error");
    }
    else {
        value = value.toString();
    }

    return <>
        <span className={styles.errorSpan}>{value}</span>
        <InvalidHint text={props.hint}/>
    </>;
}