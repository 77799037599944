import { ICrmCommentMessage } from "@core/Models/i-comment-message";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from 'uuid';

export function isCommentMessage(comment: any) {
    if (comment === null || comment === undefined)
        return false;
    if (typeof comment !== 'object')
        return false;
    if (comment['id'] === null || comment['id'] === undefined)
        return false;
    if (comment['date'] === null || comment['date'] === undefined || typeof comment['date'] !== 'number')
        return false;
    if (comment['message'] === null || comment['message'] === undefined || typeof comment['message'] !== 'string')
        return false;
    
    return true;
}

export function extractComments(value: any): ICrmCommentMessage[]{ 
    if (Array.isArray(value))
        return value.filter(isCommentMessage) as ICrmCommentMessage[];
    else if (typeof value === 'string') {
        let now = DateTime.now().toSeconds();
        return [{id: uuidv4(), date: now, message: value}];
    }
    else
        return [];
}