import { ConfirmDelete } from "@core/VisualComponents/ConfirmDelete/ConfirmDelete";
import { ContextMenu } from "@core/VisualComponents/ContextMenu/ContextMenu";
import { ContextMenuItem } from "@core/VisualComponents/ContextMenu/ContextMenuItem/ContextMenuItem";
import { t } from "i18next";
import { ReactComponent as TrashCanOutlineSvg } from '@assets/Icons/trash-can-outline-icon.svg';
import { ReactComponent as PenSvg } from '@assets/Icons/pen-icon.svg';
import { ReactComponent as CopyLinkIcon } from '@assets/Icons/link-icon.svg';
import { deleteOrderAsync as deleteEntityAsync } from "@core/Redux/Slices/ordersSlice/thunks/deleteOrderAsync";
import { useAppDispatch } from "@core/Redux/hooks";
import { StoreContext } from "@core/Stores/OrderStoreProvider";
import { useContext, useCallback, useState, MutableRefObject, Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IEntityData } from "@core/Models/i-entity";
import { ICrmField } from "@core/Models/tenantConfig.models";
import { ViewMode } from "../../Components/ViewSelector";
import { ICrmCellInputEditors } from "@pluginShared/core-ui-api";
import Logger from "js-logger";
import { message } from "antd";

interface IContextMenuData {
    tableId: string;
    entityId: string;
    entityData: IEntityData;
    field?: ICrmField;
}

export interface IOrderContextMenu {
    onContextMenu: (e: MouseEvent, ref: MutableRefObject<any>, tableId: string, entityId: string, entityData: IEntityData, field?: ICrmField) => void;
}

export interface IOrderContextMenuProps {
    contextMenuRef: MutableRefObject<IOrderContextMenu>;
    setSelectedEntityId?: Dispatch<SetStateAction<string | undefined>>;
    cellEditorsRef: MutableRefObject<ICrmCellInputEditors>;
    onEditEntity: (entityId: string) => void;
    viewMode: ViewMode;
}

export function OrderContextMenu(props: IOrderContextMenuProps) {
    const [contextMenuData, setContextMenuData] = useState<IContextMenuData | null>(null);
    const [contextMenuTriggerRef, setContextMenuTriggerRef] = useState<MutableRefObject<any> | null>(null);
    const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<{x: number, y: number}>({x: 0, y: 0});
    const navigateTo = useNavigate();
    const dispatch = useAppDispatch();
    const stores = useContext(StoreContext);

    const anchorRef = useRef<any>();
    const [key, setKey] = useState<number>(0);

    props.contextMenuRef.current.onContextMenu = (e: MouseEvent, ref: MutableRefObject<any>, tableId: string, entityId: string, entityData: IEntityData, field?: ICrmField) => {
        if (props.cellEditorsRef.current.hideEditor)
			props.cellEditorsRef.current.hideEditor();

        setContextMenuTriggerRef(ref);
        setContextMenuPosition({
            x: e.clientX,
            y: e.clientY,
        })
        setKey(prevKey => prevKey + 1);
        setContextMenuData({
            tableId: tableId,
            entityId: entityId,
            entityData: entityData,
            field: field
        });
        if (props.setSelectedEntityId) {
            props.setSelectedEntityId(entityId);
        }
        setConfirmDeleteVisible(false);
        setContextMenuVisible(true);
    };

    const onHideContextMenu = () => {
        setContextMenuData(null);
        setContextMenuVisible(false);
        setConfirmDeleteVisible(false);

        if (props.setSelectedEntityId) {
            props.setSelectedEntityId(undefined);
        }
    };

    const deleteOrderFromDbAndRedux = (tableId: string, entityId: string) => {
        const store = stores[tableId];
        if (store == null) {
            Logger.error(`deleteOrderFromDbAndRedux: store with tableId ${tableId} not found`);
            return;
        }
        dispatch(deleteEntityAsync({store, entityId: entityId}));
    }

    const onConfirmDelete = useCallback(() => {
        deleteOrderFromDbAndRedux(contextMenuData!.tableId, contextMenuData!.entityId);
        setContextMenuData(null);
        setConfirmDeleteVisible(false);
        setContextMenuVisible(false);

        if (props.setSelectedEntityId) {
            props.setSelectedEntityId(undefined);
        }
    }, [contextMenuData]);

    const onContextMenuEditListItemClicked = () => {
        setContextMenuData(null);
        setConfirmDeleteVisible(false);
        setContextMenuVisible(false);

        const {entityId} = contextMenuData!;
        props.onEditEntity(entityId);
    }

    const onContextMenuEditClicked = (e: React.MouseEvent) => {
        onContextMenuEditListItemClicked();
    }

    const onDeleteItemClicked = () => {
        setContextMenuVisible(false);
        setConfirmDeleteVisible(true);
    }

    const onCopyLink = () => {
        navigator.clipboard.writeText(window.location.origin + "/" + contextMenuData?.tableId + "/edit/" + contextMenuData?.entityId);
        message.success(t("link-copied"));
        setContextMenuData(null);
        setConfirmDeleteVisible(false);
        setContextMenuVisible(false);
    }

    useEffect(() => {
        if (!contextMenuTriggerRef?.current) {
            onHideContextMenu();
        }
    }, [contextMenuTriggerRef?.current]);

    return (<>
    <div ref={anchorRef} style={{
        position: 'fixed',
        left: contextMenuPosition.x,
        top: contextMenuPosition.y,
    }}></div>
    <ContextMenu
        key={key}
        visible={contextMenuVisible}
        triggerRef={anchorRef}
        onHide={onHideContextMenu}
        >
        <ContextMenuItem onClick={onContextMenuEditClicked}>
            <PenSvg/>
            <div>{t("edit_command")}</div>
        </ContextMenuItem>
        <ContextMenuItem onClick={onCopyLink}>
            <CopyLinkIcon/>
            <div>{t("copy-link")}</div>
        </ContextMenuItem>
        <ContextMenuItem onClick={onDeleteItemClicked}>
            <TrashCanOutlineSvg fill="red"/>
            <div style={{color: "red"}}>{t("delete_row_command")}</div>
        </ContextMenuItem>
    </ContextMenu>
    <ConfirmDelete
        visible={confirmDeleteVisible}
        triggerRef={anchorRef}
        onCancel={onHideContextMenu}
        onDelete={onConfirmDelete}
        />
    </>);
}