import { createApi } from "@core/Redux/Slices/appSlice";
import { ICrmApi } from "@pluginShared/i-crm-api";
import { BaseTool, IApiForAIAgent, LangChainAgent } from "./ai-api";
import { ListFieldsTool } from "./fieldsManager";
import { IGeneratedModuleInfo } from "@core/Models/autogenerated/plugins.models";
import { AddOrUpdatePluginTool, AskUserTool, PluginCreatorTool } from "./commonPluginTools";
import { ListUsersTool } from "./usersManager";
import { CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models.shared";
import { getPluginPromptParts } from "./pluginAiManager";

const getDecoratorPrompt = async (api: IApiForAIAgent) => {
    const promptParts = await getPluginPromptParts(api);

    return `
You are AI code plugin generator. Your goal is to develop decorator plugin for CRM based on user query.

Development consist of several steps:
1. Planing where you must plan overall structure and requirement for plugin. If needed ask user for some questions
2. Write plugin javascript code

Plugin is a object with following structure: 
interface IPlugin {
    routes: IRoute[]; //reserved for future usage. must be []
    components: ComponentImpl<any>[]; //reserved for future usage. must be []
    decorators: IReactComponentDecorator<any>[]; //Decorators are used to add or change the behavior or appearance of some system elements.
}

IReactComponentDecorator integrates in CRM frontend and contains custom jsx code for its purpose.

Plugin code must be provided in the following form:
return {
    routes: [], //reserved for future use
    components: [], //reserved for future use
    decorators: [{
        order: <The order in which the decorators are applied to the component. The less, the sooner. Detailed description below>,
        component: <The component to which the decorator is applied> // You should take it from api.coreUiApi.components. Example: api.coreUiApi.components.CrmCellInternalSpan
        
        //return JSX.Element
        func: (original, props) => {
            // The 'original' argument is a functional component that is overrided by this decorator. You can use it to, for example, use the original behavior.
            // The 'props' argument is the arguments that were passed to the original component. You can use it.

            // Plugin code here. This function must return React component which will override original component.
            // IMPORTANT: Place all necessary React hooks here, before conditional logic.

            // Get current user info if needed.
            const user = api.clientApi.getUserInfo(); // Remove this line if you don't need user information.

            if (<Check if you need to replace or change the original component. Please note that the decorator is applied to all components in the system, be careful>) {
                // IMPORTANT: no React hooks here!
                
                return (
                    {/* Here you can put your React code to render new component */}
                    <p>My new decorator</p>
                    {/* Or you can use original component with new props */}
                    {original(newProps)}
                )
            }

            //Return the original component with its original arguments if you don't need to change it
            return original(props);
        }
    }],
}

You can create multiple decorators at once.
Don't forget to create decorators for both types of data presentation: grid and list.
For example, the grid uses CrmCellInternalSpan to display data, and the list uses OrdersListViewItemValue.
For example, the grid uses CrmCellInputEditors for editing data, and the list uses OrderFieldEditor.

Important considerations:
- This route function run in sandboxed environment and don't have access to third party libraries.
- Plugin must be written using javascript code
- If you are creating a decorator that changes the behavior or content of an element, the order should be from 1000 to 1999.
    If you are creating a decorator that only changes the appearance of the element, the order should be from 5000 to 5999.
    If you are creating a decorator that disables editing, the order should be from 500 to 599.
    The rest of the cases are up to you.

several globals constants are defined and can be used:
- api, an object that contains:
    - clientApi
        - function getUserInfo(), returns ICrmUserInfo, can be used to get current user. ICrmUserInfo will be described below.
    - phoneApi
        - function makeCall(userPhone: string, targetPhone: string), Promise<void>, initiate call to targetPhone, userPhone - internal user phone number. Throw error if fail.
    - coreUiApi
        - components // Contains components to override and which can be used
- React: it is React

Components to override: ${promptParts.overridingComponents}

Components to use: ${promptParts.componentsForUse}

Types descriptions: ${promptParts.commonNotes}

IMPORTANT:
 * Be sure to keep in mind that any data can be of any type. Be sure to check that the data you are working with is not null.
 * The date in the system is stored as a number of seconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Display it like {value.date ? new Date(value.date * 1000).toLocaleDateString() : ""}. 
 * If you need to get the current date to compare it, use the following commands: let currentDate = new Date(); currentDate.setHours(0, 0, 0, 0); currentDate = Math.floor(currentDate.getTime() / 1000);
 * Add 'onClick={(e)=>e.stopPropagation()}' for all links in the system.
 * All the code you write goes straight into production without any additional checks or modifications. Use styles in all interface elements. Use CSS variable var(--primary-color). The standard indentation between system elements is 10px.
 * Don't pass hooks inside 'if' statement.

list of field viewTypes:
* ${CrmFieldViewType.Comments}
* ${CrmFieldViewType.String}
* ${CrmFieldViewType.Phone}
* ${CrmFieldViewType.Decimal}
* ${CrmFieldViewType.Date}
* ${CrmFieldViewType.Time}
* ${CrmFieldViewType.YesNo}
* ${CrmFieldViewType.Combobox}
* ${CrmFieldViewType.MultiString}
* ${CrmFieldViewType.Url}
* ${CrmFieldViewType.Unknown}
* ${CrmFieldViewType.Array}

IMPORTANT:
 * Don't write your own HTML code. Instead, whenever possible, use the components provided to you in the api variable.
 * If you need to create a decorator to display column values, be sure to do it for both display methods: for a table and for a list
 * If you need to change the way array elements are displayed, create a decorator for ArrayValuesViewer or ArrayValueRenderer.

Following fields can be used:
    ${await new ListFieldsTool(api).run("")}

Current users list:
    ${await new ListUsersTool(api).run("")}

Current user:
    ${JSON.stringify(api.getUserInfo()!)}

Date time now is: ${new Date().toUTCString()}

For now you only need to follow first step: plan you task.
- Decorators for which components do you need to create?
- What components of the provided can be used? How much of your own HTML code do you use?
- What properties of the components should be used?
- If you create a decorator for an unusual type of data, what fields should data have?
    (For example, if you create a list of birthdays, you may need to store an array of objects with fields 'date' and 'name')

You current task:
- As a result write plan with plain english as a base for next step.
- Plan must not contains assumptions. All assumptions must be resolved with tools.
- If you create a decorator to display column values, did you remember to do this for the table and for the list?
- If you create a decorator for editing column values, did you remember to do this for the table and for the list?
- Next step started when explicitly asked by administrator.
- At the end of this step, analyze the plan, highlight what is directly confirmed by facts from the current dialogue, and what is an assumption. Adjust the plan by removing all assumptions. The final version should contain only factually confirmed conclusions.
- After plan ready, do not switch to next step you must answer 'ready for next step'.
`
};

export class CreateDecoratorTool extends PluginCreatorTool {
    name: string = "create_decorator";
    crmApi: ICrmApi;
    toolkit: BaseTool[];
    description: string = `
Tool used for add decorators for some elements in the system.
Decorators are used to change some existing functionality or simply change the appearance of some system elements.
This tool have memory and remember past conversations in current session.
Input is text with human description of decorator.
Output is result of adding decorator.
`;

    constructor(api: IApiForAIAgent, access_token: string) {
        super(api, access_token, 'gpt-4o');
        this.crmApi = createApi(api.getUserInfo()!);

        this.toolkit = [
            new AskUserTool(api, this.crmApi),
            new AddOrUpdatePluginTool(api, this.crmApi, this),
        ];
    }

    async getPrompt(): Promise<string> {
        const prompt = await getDecoratorPrompt(this.api);
        return prompt;
    }

    async create(query: string, agent: LangChainAgent): Promise<string> {
        try {
            console.log(`create_decorator_tool ${query}`);

            //response stored in history. ignore it
            const response_plan = await agent.invoke(
                { input: query }
            );

            let response = await agent.invoke(
                { input: "Let's move further to the step 2. Write plugin javascript code." }
            );

            return response.output;//"Custom behavior added";
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }

    async update(query: string, agent: LangChainAgent, moduleInfo: IGeneratedModuleInfo): Promise<string> {
        console.log(`*${this.name}/update ${query}`);

        let response = await agent.invoke(
            { input: query }
        );

        return response.output;
    }
}