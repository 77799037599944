import { CSSProperties, MutableRefObject, useEffect, useState } from 'react';
import { ICrmValueOption } from '@core/Models/tenantConfig.models';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Password from 'antd/es/input/Password';
import { CreatableSelect } from '../CreatableSelect/CreatableSelect';
import { InvalidHint, InvalidSpan } from '../InvalidHint/InvalidHint';
import { t } from 'i18next';
import { InputValidator } from '../InputValidator/InputValidator';

export interface IStringInputProps {
    initialValue?: string | null;
    placeholder?: string;
    autocompleteValues?: string[];
    onChanged?: (value: string | null) => void;
    onEnter?: () => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    setIsValid?: (isValid: boolean) => void;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    disabled?: boolean;
    valueRef?: MutableRefObject<string | null>;
    autoFocus?: boolean;
    type?: "text" | "textarea" | "password";
    validation?: (value: any) => boolean;
    delayOnChanged?: boolean;
}

export const StringInput = registerComponent(coreUiComponentDescriptions.StringInput, _StringInput);

function _StringInput(props: IStringInputProps) {
    const isValidValue = (value: any) => {
        return (value == null || typeof value == "string" || typeof value == "number") && (!props.validation || props.validation(value));
    }
    
    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));
    const isEqual = InputValidator.isEqual;

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);

    useEffect(() => {
        if (props.valueRef) {
            props.valueRef.current = props.initialValue ?? null;
        }
    }, []);

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;

        if (!isEqual(props.initialValue, newValue)) {
            props.onChanged?.(newValue);
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;

        if (props.valueRef) {
            props.valueRef.current = newValue;
        }

        if (!props.delayOnChanged) {
            props.onChanged?.(newValue);
        }

        setIsValid(isValidValue(newValue));
    }

    const onChangedSelect = (value: string | null) => {
        if (props.valueRef) {
            props.valueRef.current = value;
        }
        props.onChanged?.(value);
        setIsValid(isValidValue(value));
    }

    const onKeyDownTextArea = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const newValue = e.currentTarget.value;

        if (e.key === 'Enter') {
            if (e.ctrlKey) {
                onBlur({ target: { value: newValue } } as any);
                props.onEnter?.();
            }
            else {
                e.stopPropagation();
                return;
            }
        }

        props.onKeyDown?.(e);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;

        if (e.key === 'Enter') {
            onBlur({ target: { value: newValue } } as any);

            props.onEnter?.();
        }

        props.onKeyDown?.(e);
    };

    const render = () => {
        switch (props.type) {
            case "textarea":
                return <TextArea
                    defaultValue={extractString(props.initialValue)}
                    placeholder={isValid ? props.placeholder : t("error")}
                    className={props.className}
                    style={props.style}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDownTextArea}
                    disabled={props.readonly || props.disabled}
                    allowClear
                    autoSize={{maxRows: 4}}
                    status={isValid ? undefined : "error"}
                    autoFocus={props.autoFocus}
                />;
            case "password":
                return <Password
                    defaultValue={extractString(props.initialValue)}
                    placeholder={isValid ? props.placeholder : t("error")}
                    className={props.className}
                    style={props.style}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    disabled={props.readonly || props.disabled}
                    allowClear
                    autoFocus={props.autoFocus}
                    status={isValid ? undefined : "error"}
                />;
            case "text":
            default:
                if (props.autocompleteValues) {
                    return <CreatableSelect
                        value={extractString(props.initialValue)}
                        placeholder={isValid ? props.placeholder : t("error")}
                        style={{width: "100%"}}
                        disabled={props.readonly || props.disabled}
                        onChanged={onChangedSelect}
                        options={props.autocompleteValues}
                        status={isValid ? undefined : "error"}
                        autoFocus={props.autoFocus}
                    />;
                }

                return <Input
                    defaultValue={extractString(props.initialValue)}
                    placeholder={isValid ? props.placeholder : t("error")}
                    className={props.className}
                    style={props.style}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    disabled={props.readonly || props.disabled}
                    allowClear
                    autoFocus={props.autoFocus}
                    status={isValid ? undefined : "error"}
                />;
        }
    }

    return <>
        {render()}
        {isValid ||
            <InvalidSpan/>
        }
    </>;
}

function extractString(value: any) {
    if (value == null) {
        return "";
    }

    if (typeof value == "string") {
        return value;
    }

    if (typeof value == "number") {
        return value.toString();
    }

    return "";
}
