import {OrderGridView} from './OrderGridView/OrderGridView';
import {OrdersListView} from './OrdersListView/OrdersListView';
import {ViewMode} from '../Components/ViewSelector'
import { IEntityData } from '@core/Models/i-entity';
import { ICrmCellInputEditors, IMousePosition } from '@pluginShared/core-ui-api';
import { useState, MutableRefObject, useRef } from 'react';
import { ICrmField } from '@core/Models/tenantConfig.models';
import { OrderGridViewEditors } from './OrderGridView/OrderGridViewEditors/OrderGridViewEditors';
import { IOrderContextMenu, OrderContextMenu } from './OrderContextMenu/OrderContextMenu';
import { IQueryStatus } from '@core/Redux/Slices/ordersSlice/storesSlice';
import { StatusPanel } from '../Components/StatusPanel';

export interface IEntitiesViewProps {
    viewMode: ViewMode;
    fields?: string[];
    tableId: string;
    entityIds: string[];
    onEditEntity: (entityId: string) => void;
    itemsPerPage: number;
    ResetCursor: () => void;
    MoveCursor: (offset: number) => void;
    cursorAtStart: boolean;
    cursorAtEnd: boolean;
    queryStatus: IQueryStatus;

    simpleFilters: {[columnId: string]: any};
    onChangedSimpleQuery: (columnId: string, query: any) => void;
    onResetSimpleQuery: (columnId: string) => void;
}

export function EntitiesView(props: IEntitiesViewProps) {
    const [selectedEntityId, setSelectedEntityId] = useState<string | undefined>(undefined);

    const cellEditorsRef = useRef<ICrmCellInputEditors>({
        showEditor: (tableId: string, entityId: string, entityData: IEntityData, field: ICrmField, position: IMousePosition, targetRef?: React.MutableRefObject<any>) => {},
        hideEditor: () => {},
    });

    const contextMenuRef = useRef<IOrderContextMenu>({
        onContextMenu: (e: MouseEvent, ref: MutableRefObject<any>, tableId:string, entityId: string, entityData: IEntityData, field?: ICrmField) => {},
    });

    const onEditEntity = (entityId: string) => {
        setSelectedEntityId(entityId);
        props.onEditEntity(entityId);
    }
    
    return (<>
        <>{props.viewMode === ViewMode.List
            ? <OrdersListView
                tableId={props.tableId}
                entityIds={props.entityIds}
                fields={props.fields}
                numRowsPerPage={props.itemsPerPage}
                contextMenuRef={contextMenuRef}
                selectedEntityId={selectedEntityId}
                onEditEntity={onEditEntity}/>
            : <OrderGridView
                tableId={props.tableId}
                entityIds={props.entityIds}
                fields={props.fields}
                numRowsPerPage={props.itemsPerPage}
                ResetCursor={props.ResetCursor}
                MoveCursor={props.MoveCursor}
                cursorAtStart={props.cursorAtStart}
                cursorAtEnd={props.cursorAtEnd}
                cellEditorsRef={cellEditorsRef}
                contextMenuRef={contextMenuRef}
                selectedEntityId={selectedEntityId}
                onRowClick={setSelectedEntityId}
                simpleFilters={props.simpleFilters}
                onChangedSimpleQuery={props.onChangedSimpleQuery}
                onResetSimpleQuery={props.onResetSimpleQuery}/>
        }</>
        <OrderGridViewEditors cellEditorsRef={cellEditorsRef}/>
        <OrderContextMenu
            contextMenuRef={contextMenuRef}
            setSelectedEntityId={setSelectedEntityId}
            cellEditorsRef={cellEditorsRef}
            onEditEntity={onEditEntity}
            viewMode={props.viewMode}
            />
    </>);
}
