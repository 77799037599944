import axios, { AxiosResponse } from 'axios';
import {
    ITenantConfig
} from '@core/Models/tenantConfig.models';
import {ICrmUserInfo, IGetUserConfigsRequest, ISaveUserConfigsRequest, IGetUserInfoRequest, IGetTenantUsersRequest, ICreateNewUserRequest} from '@core/Models/autogenerated/user.models';

const endpoint = '/api/user';

export async function getTenantConfig(req: Partial<IGetUserConfigsRequest>): Promise<ITenantConfig> {
    const resp = await axios.post(`${endpoint}/GetJsonConfig`, JSON.stringify(req));
    return resp.data;
}

export async function userApiGetUserInfo(req: Partial<IGetUserInfoRequest>): Promise<ICrmUserInfo> {
    const resp = await axios.post(`${endpoint}/GetUserInfo`, JSON.stringify(req));
    return resp.data;
}

export async function userApiGetTenantUsers(req: Partial<IGetTenantUsersRequest>): Promise<ICrmUserInfo[]> {
    const resp = await axios.post(`${endpoint}/GetTenantUsers`, JSON.stringify(req));
    return resp.data;
}

export async function userApiSaveJsonConfig(req: Partial<ISaveUserConfigsRequest>): Promise<AxiosResponse<unknown, unknown>> {
    const resp = await axios.post(`${endpoint}/SaveJsonConfig`, JSON.stringify(req));
    return resp;
}

export async function userApiCreateNewUser(req: Partial<ICreateNewUserRequest>): Promise<AxiosResponse<unknown, unknown>> {
    const resp = await axios.post(`${endpoint}/CreateNewUser`, JSON.stringify(req));
    return resp;
}

