import { PageHeader } from '@core/VisualComponents/Page/PageHeader/PageHeader';
import { Page } from '@core/VisualComponents/Page/Page';
import { PageHeaderRow } from '@core/VisualComponents/Page/PageHeader/PageHeaderRow/PageHeaderRow';
import { PageContent } from '@core/VisualComponents/Page/PageContent/PageContent';
import { PageTitleContainer } from '@core/VisualComponents/Page/PageHeader/PageTitleContainer/PageTitleContainer';
import { entityCreatePath, tablePath } from '@core/Constants/route-paths';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@core/Redux/hooks';
import styles from './CreateOrderPage.module.scss';
import { PageHeaderAside } from '@core/VisualComponents/Page/PageHeader/PageHeaderAside/PageHeaderAside';
import { PrimaryButton } from '@core/VisualComponents/Buttons/PrimaryButton';
import { selectTableConfig } from '@core/Redux/store';
import { Breadcrubs, IBreadcrumbs, goBack } from '@core/VisualComponents/Navigation/Breadcrumbs';
import { useUnsavedChangeBlocker } from '@core/VisualComponents/Navigation/blockNavigation';
import _ from 'lodash';
import { CreateOrEditEntity, ICreateOrEditEntityCommands } from './CreateOrEditEntity/CreateOrEditEntity';
import { DeviceTypeContext } from '@core/Contexts/DeviceTypeContext';

export function CreateOrderPage(props: {tableId: string}) {
    const { t } = useTranslation();
    const navigateTo = useNavigate();
    const tableConfig = useAppSelector(selectTableConfig(props.tableId));
    const orderChanged = useRef<boolean>(false);
    const createExternalCommands = useRef<ICreateOrEditEntityCommands>({});
    const deviceType = useContext(DeviceTypeContext);

    const unblockPage = useUnsavedChangeBlocker(() => {
            // eslint-disable-next-line no-restricted-globals
            return confirm(t("confirm-drop-changes"));
        },
        () => {
            return orderChanged.current;
        },
    );

    const onCreateOrderClicked = () => {
        navigateTo(tablePath(props.tableId));
    };

    const breadcrumbs: IBreadcrumbs[] = useMemo(() => [
        { url: tablePath(props.tableId), caption: tableConfig?.tableName ?? '' },
        { url: entityCreatePath(props.tableId), caption: t("new_order") },
    ], []);

    
    const cancelClicked = useCallback(() => {
        goBack(breadcrumbs, navigateTo);
    }, [breadcrumbs]);

    const view = (
        <Page>
            <PageHeader>
                <PageHeaderRow>
                    <PageTitleContainer>
                        <Breadcrubs breadcrumbs={breadcrumbs} />
                    </PageTitleContainer>
                    <PageHeaderAside>
                        <PrimaryButton title="Create" onClick={createExternalCommands.current.onCreate}>
                            <span>{t("create")}</span>
                        </PrimaryButton>
                    </PageHeaderAside>
                </PageHeaderRow>
            </PageHeader>
            <PageContent>
                <div className={styles.pageContent}>
                    {deviceType.isMobile
                        ? <CreateOrEditEntity
                            tableId={props.tableId}
                            orderChanged={orderChanged}
                            externalCommands={createExternalCommands}
                            onCreateOrUpdateOrderClicked={onCreateOrderClicked}
                            onCancelClicked={cancelClicked}
                            showSaveButtons
                        />
                        : <div className={styles.pseudoModal}>
                            <h1>{t("create-record")}</h1>
                            <div className={styles.container}>
                                <CreateOrEditEntity
                                    tableId={props.tableId}
                                    orderChanged={orderChanged}
                                    externalCommands={createExternalCommands}
                                    onCreateOrUpdateOrderClicked={onCreateOrderClicked}
                                    onCancelClicked={cancelClicked}
                                    showSaveButtons
                                />
                            </div>
                            <button type="button" className={styles.closeButton} onClick={cancelClicked}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                    <path d="M6.414 5l3.293 3.293a1 1 0 1 1-1.414 1.414L5 6.414 1.707 9.707A1 1 0 0 1 .293 8.293L3.586 5 .293 1.707A1 1 0 0 1 1.707.293L5 3.586 8.293.293a1 1 0 0 1 1.414 1.414L6.414 5z"></path>
                                </svg>
                            </button>
                        </div>
                    }
                </div>

            </PageContent>
        </Page>);

    return <Routes>
        <Route path="" element={view} />
        <Route path="*" element={<span>page not found</span>} />
    </Routes>
}
