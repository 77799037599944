import { useEffect, useRef, useState } from "react";
import Overlay from "react-overlays/esm/Overlay";
import styles from "./InvalidHint.module.scss";
import { t } from "i18next";

export interface IInvalidHintProps {
    text?: any;
    inline?: boolean;
}

export function InvalidHint(componentProps: IInvalidHintProps) {
    const [hintVisible, setHintVisible] = useState<boolean>(false);
    const [hintHovered, setHintHovered] = useState<boolean>(false);
    const buttonRef = useRef<any>();

    const hideHint = () => {
        setHintVisible(false);
    }

    const handleClick = () => {
        setHintVisible(true);
    }

    const popperConfig = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['left', 'right', 'bottom'],
                },
            },
        ]
    };

    return <>
        <div
            ref={buttonRef}
            className={`${styles.button} ${componentProps.inline === true ? styles.inline : styles.absolute}`}
            onMouseEnter={() => setHintHovered(true)}
            onMouseLeave={() => setHintHovered(false)}
            onClick={handleClick}
        >?</div>
        <Overlay
            show={hintVisible || hintHovered}
            flip={true}
            placement={'bottom-start'}
            rootClose={true}
            onHide={hideHint}
            target={buttonRef}
            popperConfig={popperConfig}
        >
            {({ show, props, arrowProps, placement }) => 
                <div {...props} className={styles.overlayContent} onClick={e => e.stopPropagation()}>
                    {componentProps.text ?? t("invalid_value")}
                </div>
            }
        </Overlay>
    </>;
}

export function InvalidSpan(props: { text?: string }) {
    return <div className={styles.invalidSpanContainer}>
        <span>
            {props.text || t("invalid_value")}
        </span>
    </div>;
}