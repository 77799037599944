import { ICrmField } from "@core/Models/tenantConfig.models";
import { Select } from "antd";
import { MutableRefObject, useEffect, useRef } from "react";

export interface ICrmGridAddComboboxFilterDialogProps {
    field: ICrmField;
    queryRef: MutableRefObject<any>;
    onEnter?: () => void;
}

export function CrmGridAddComboboxFilterDialog(props: ICrmGridAddComboboxFilterDialogProps) {
    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChanged = (values: string[] | null) => {
        if (values == null || values.length === 0) {
            props.queryRef.current = null;
        }
        else {
            props.queryRef.current = {
                $in: Array.isArray(values) ? values : [values],
            };
        }
    }

    const getSelectedValues = () => {
        return props.field.options?.filter(x => props.queryRef.current?.["$in"].includes(x.value)).map(x => x.value);
    }

    return <div onClick={e => e.stopPropagation()}>
        <Select
            mode="multiple"
            options={props.field.options!}
            defaultValue={getSelectedValues() ?? null}
            placeholder={props.field.placeholder}
            onChange={onChanged}
            allowClear
            style={{width: "100%"}}
            placement="topLeft"
        />
    </div>;
}