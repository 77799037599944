import { ICrmValueOption } from "@core/Models/tenantConfig.models";
import Select, { GroupBase, OptionProps } from "react-select";
import styles from "./RemovableSelect.module.scss";
import { useRef, useState } from "react";
import { ConfirmDelete } from "../ConfirmDelete/ConfirmDelete";
import { CustomSelect } from "../CustomSelect/CustomSelect";

export interface IRemovableValueOption extends ICrmValueOption {
    canRemove: boolean;
}

export interface IRemovableSelectProps {
    options: IRemovableValueOption[];
    value: IRemovableValueOption;
    classNames?: any;
    onChange?: (e: IRemovableValueOption | null) => void;
    onRemove: (value: string) => void;
    confirmRemove?: boolean;
}

export function RemovableSelect(compProps: IRemovableSelectProps) {
    return <CustomSelect
        options={compProps.options}
        value={compProps.value}
        classNames={compProps.classNames}
        onChange={compProps.onChange}
        components={{
            Option: (props) => <RemovableSelectOption
                {...props}
                onRemove={compProps.onRemove}
                confirmRemove={compProps.confirmRemove}
            />,
        }}
    />
}

type RemovableSelectOptionProps = OptionProps<IRemovableValueOption, false, GroupBase<IRemovableValueOption>> & {
    onRemove: (value: string) => void;
    confirmRemove?: boolean;
};

function RemovableSelectOption(props: RemovableSelectOptionProps) {
    const { data, innerRef, innerProps, isSelected } = props;
    const { label, value, canRemove } = data;
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const removeButtonRef = useRef<any>();

    const onRemove = (value: string) => {
        if (!props.confirmRemove) {
            props.onRemove(value);
        }
        else {
            setShowConfirmDelete(true);
        }
    }

    const onRemoveConfirmed = () => {
        props.onRemove(value);
    }

    const onRemoveCanceled = () => {
        setShowConfirmDelete(false);
    }

    return (
        <div
            ref={innerRef}
            {...innerProps}
            className={`${styles.option} ${isSelected ? styles.selected : ""}`}
        >
            <span>{label}</span>
            { canRemove &&
                <i className="fas fa-times"
                    ref={removeButtonRef}
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemove(value);
                    } }
                ></i>
            }
            <ConfirmDelete triggerRef={removeButtonRef} visible={showConfirmDelete} onCancel={onRemoveCanceled} onDelete={onRemoveConfirmed}/>
        </div>
    );
}