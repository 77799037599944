import _ from 'lodash';

import { ChatMessageHistory } from 'langchain/stores/message/in_memory';
import { BaseTool, CompositeTool, IApiForAIAgent, LangChainAgent } from "./ai-api";
import { ICrmApi } from '@pluginShared/i-crm-api';
import { z } from 'zod';
import { IGeneratedModuleInfo, ModuleType } from '@core/Models/autogenerated/plugins.models';
import { v4 as uuidv4 } from 'uuid';

export class ListPluginsTool extends BaseTool {
    name: string = 'list_plugins';
    description: string = `
usefull when you need to exaimine installed plugins in CRM. Input is ignored, can be empty string. Return list of installed plugins.
`;

constructor(api: IApiForAIAgent) {
        super(api)
    }

    async run(query: any): Promise<string> {
        console.log(`*${this.name} ${query}`);

        const modules = this.api.getInstalledModules();

        const result = modules.map(x => ({
            moduleType: x.info.moduleType,
            name: x.info.name,
            description: x.info.description,
        }))

        return JSON.stringify(result);
    }

}
export abstract class PluginCreatorTool extends CompositeTool {
    abstract name: string;
    abstract description: string;
    abstract toolkit: BaseTool[];

    abstract create(query: string, agent: LangChainAgent): Promise<string>;
    abstract update(query: string, agent: LangChainAgent, moduleInfo: IGeneratedModuleInfo): Promise<string>;

    public currentModule: IGeneratedModuleInfo | null = null;

    constructor(api: IApiForAIAgent, access_token: string, model_name: string) {
        super(api, access_token, model_name, true)
    }


    async run(query: string): Promise<string> {
        try {
            console.log(`*${this.name} ${query}`);

            this.history = new ChatMessageHistory();

            const agent = await this.createAgent();

            let response= await this.create(query, agent);

            if (this.currentModule == null) {
                const agentResponse = await agent.invoke(
                    { input: `Did you forget install plugin with ${AddOrUpdatePluginTool.name_}?` }
                );
                response = agentResponse.output;
            }

            if (this.currentModule != null) {
                const moduleInfo = this.currentModule as IGeneratedModuleInfo;
                moduleInfo.history = await this.saveHistory(); 

                let module = await this.api.addOrUpdatePluginCode(moduleInfo);
                if (module == null)
                    return "I have failed";
                return `Successfully created/updated plugin with name/id ${module.info.name}. Info: ${response}`;
            }

            return `Request handled but no plugins created. Info: ${response}`;
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}

export class UpdatePluginTool extends BaseTool {
    name: string = "update_plugin";
    //access_token: string;
    creatorToolkit: PluginCreatorTool[];

    //history: ChatMessageHistory;
    description: string = `
Tool used for modify existing installed plugins based on user request.
This tool remember all history of plugin created and can use it to incorporate changes.
Input is JSON with 'name' field which is name of plugin and 'request' field with human request.
Plugin name is used to search plugin to update.
Output is a status of operation.
`;

    schema = z.object({
        name: z.string().describe('plugin name'),
        request: z.string().describe('user request for modification'),
    });


    constructor(api: IApiForAIAgent, creatorToolkit: PluginCreatorTool[]) {
        super(api);

        this.creatorToolkit = creatorToolkit;
    }


    async run(query: any): Promise<string> {
        try {
            if (typeof query == "string")
                query = JSON.parse(query);

            console.log(`*${this.name} ${JSON.stringify(query)}`);

            const pluginName = query.name;
            const request = query.request;

            const plugin = this.api.getInstalledModules().find(x => x.info.name == pluginName);
            if (plugin == null)
                return `plugin with name ${pluginName} not found in installed plugins/modules`;
            if (plugin.info.moduleType != ModuleType.GeneratedModule)
                return `plugin with type ${plugin.info.moduleType} can't be changed`;

            const moduleInfo = plugin.info as IGeneratedModuleInfo;

            const creator = this.creatorToolkit.find(x => x.name == moduleInfo.creatorTool);
            if (creator == null)
                return `Plugin ${pluginName} has old format. Can't edit it.`;

            creator.currentModule = null;
            await creator.loadHistory(moduleInfo.history);

            const agent = await creator.createAgent();

            let response= await creator.update(request, agent, moduleInfo);

            if (creator.currentModule == null) {
                const agentResponse = await agent.invoke(
                    { input: `Did you forget install plugin with ${AddOrUpdatePluginTool.name_}?` }
                );
                response = agentResponse.output;
            }
            if (creator.currentModule != null) {
                const moduleInfo = creator.currentModule as IGeneratedModuleInfo;
                moduleInfo.history = await creator.saveHistory(); 

                let module = await this.api.addOrUpdatePluginCode(moduleInfo);
                if (module == null)
                    return "I have failed";
                return `Successfully created/updated plugin with name/id ${module.info.name}. Info: ${response}`;
            }

            return `Request handled but no plugins created. Info: ${response}`;
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}


export class DeleteModuleTool extends BaseTool {
    name: string = "delete_module";
    description: string =
        "Use to delete installed module. Input is module/plugin name. Be carefully to choice what module to delete, use it only if user intention is clear for what to delete.";
    crmApi: ICrmApi;

    constructor(api: IApiForAIAgent, crmApi: ICrmApi) {
        super(api);
        this.crmApi = crmApi;
    }

    async run(query: string): Promise<string> {
        try {
            console.log(`*${this.name} ${query}`);

            const name = query;
            const module = this.api.getInstalledModules().find(x => x.info.name == name.trim());
            if (module == null)
                return `module with name '${name}' not found`;

            if (module.info.moduleType == ModuleType.ManualModule)
                return `module with moduleType '${ModuleType.ManualModule}' are administrator module and can't be deleted. You must ask administrator to delete this.`;

            await this.api.removePlugin(module);

            return `Module ${module.info.name} deleted.`;
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }

}

export class SelectQueryTool extends BaseTool {
    name: string = "select_query";
    description: string =`
Use it to validate select query in case a user wants to generate report.
This tool are playground equivalent of entityApi.select(query). If plugin need use entityApi.select this tool need to be used for:
This tool must be used if use of entityApi.select planned. Different queries should be tried until a meaningful answer is obtained.
There is no chance to write code for entityApi.select without using this tool.
- call this tool to make sure that query is ok (based on output)
- examine exact format of output (field names and data types). entityApi.select output format exactly the same.
`

    crmApi: ICrmApi;

    constructor(api: IApiForAIAgent, crmApi: ICrmApi) {
        super(api);
        this.crmApi = crmApi;
    }

    async run(query: string): Promise<string> {
        try {
            console.log(`*${this.name} ${query}`);

            let queryJson = JSON.parse(query);
            var result = await this.crmApi.entityApi.select(queryJson);
            
            const truncationThreshold = 10;
            if (result.length > truncationThreshold) {
                var resultStr = JSON.stringify(result.slice(0, truncationThreshold));
                return `Showing the top ${truncationThreshold} rows out of ${result.length}.\n${resultStr}...`;
            } else {
                return JSON.stringify(result);
            }
        }
        catch (error: any) {
            if (error.response?.data != null) {
                const data = error.response.data;
                if (typeof data === 'string')
                    return "Error: " + error.response?.data;
                if (typeof data === 'object' && data.Type == 'ModelStateErrors') {
                    return `Error: request contains following errors: ${JSON.stringify(data.Errors)}`;
                }
            }
            return "Error: " + error.toString();
        }
    }
}

export class AddOrUpdatePluginTool extends BaseTool {
    public static readonly name_ = "add_or_update_plugin";
    public name = AddOrUpdatePluginTool.name_;

    description: string =
        "Used for install plugin code in CRM. Input is a JSON with field 'code' in format 'return { ... }', field 'name' and field 'description'";

    schema = z.object({
        code: z.string().describe('plugin code'),
        name: z.string().describe('plugin name identifier in camelCase notation'),
        description: z.string().describe('plugin short description')
    }).describe('tool input');

    crmApi: ICrmApi;
    creator: PluginCreatorTool;

    constructor(api: IApiForAIAgent, crmApi: ICrmApi, creator: PluginCreatorTool) {
        super(api);
        this.crmApi = crmApi;
        this.creator = creator;
    }

    async run(query: any): Promise<string> {
        try {
            if (typeof query == "string")
                query = JSON.parse(query);

            console.log(`*${this.name}: ${JSON.stringify(query)}`);

            this.creator.currentModule = {
                moduleType: ModuleType.GeneratedModule,
                id: uuidv4(),
                creatorTool: this.creator.name,
                code: query.code,
                name: query.name,
                description: query.description,
                history: '',
            };

            return 'Successfull';
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}


export class AskUserTool extends BaseTool {
    name: string = "ask_user";
    description: string =
        "Use to interact with user. Ask some questions for example if needed. Input is a text question";
    crmApi: ICrmApi;

    constructor(api: IApiForAIAgent, crmApi: ICrmApi) {
        super(api);
        this.crmApi = crmApi;
    }

    async run(query: string): Promise<string> {
        try {
            console.log(`*${this.name} ${query}`);

            return 'I don not know. Do as you will';
        }
        catch (error: any) {
            return "Exception: " + error.toString();
        }
    }
}