import * as React from 'react';
import cs from 'clsx';
import tdstyles from '../../common/components/Cell.module.scss'


import { CellContainer } from '@table-library/react-table-library/common/components/Cell';
import { ThemeContext } from '@table-library/react-table-library/common/context/Theme';

import { CellProps } from '@table-library/react-table-library/types/table';

export function Cell({
  index,
  className,
  hide,
  pinLeft,
  pinRight,
  stiff,
  includePreviousColSpan,
  previousColSpans,
  gridColumnStart,
  gridColumnEnd,
  onClick,
  children,
  style,
  ref,
  ...rest
}: CellProps) {
  //const theme = React.useContext(ThemeContext);
  //return <td>{children}</td>


  const hasColSpan = gridColumnStart && gridColumnEnd;
  const colSpan = hasColSpan ? gridColumnEnd - gridColumnStart - 1 : 0;
  const computedGridColumnStart = includePreviousColSpan
    ? gridColumnStart + previousColSpans
    : gridColumnStart;
  const computedGridColumnEnd = includePreviousColSpan
    ? gridColumnEnd + previousColSpans
    : gridColumnEnd;

  const tdprops = {
    role:"gridcell",
      "data-table-library_td":"",
      style:{
        ...(hasColSpan
          ? { gridColumnStart: computedGridColumnStart, gridColumnEnd: computedGridColumnEnd }
          : {}),
        ...style,
      },
      className: cs('td', tdstyles.cell, className, {
        stiff,
        hide,
        'pin-left': pinLeft,
        'pin-right': pinRight,
      }),
      onClick:onClick,
      ...rest
  }
  return <td ref={ref} {...tdprops} className={cs(tdstyles.baseCell, tdprops.className)}>
          <div children={children}/>
        </td>;

  // return (
  //   <>
  //     <CellContainer
  //       role="gridcell"
  //       data-table-library_td=""
  //       style={{
  //         ...(hasColSpan
  //           ? { gridColumnStart: computedGridColumnStart, gridColumnEnd: computedGridColumnEnd }
  //           : {}),
  //         ...style,
  //       }}
  //       // css={css`
  //       //   ${theme?.BaseCell}
  //       //   ${theme?.Cell}
  //       // `}
  //       className={cs('td', tdstyles.cell, className, {
  //         stiff,
  //         hide,
  //         'pin-left': pinLeft,
  //         'pin-right': pinRight,
  //       })}
  //       onClick={onClick}
  //       {...rest}
  //     >
  //       <div>{children}</div>
  //     </CellContainer>

  //     {/* column grouping */}
  //     {Array.from({ length: colSpan }, () => (
  //       <CellContainer className={cs('td', 'hide', 'colspan')} />
  //     ))}
  //   </>
  // );
};
