import { ICrmField } from "@core/Models/tenantConfig.models";
import { DecimalInput } from "src/App/Pages/Shared/OrderField/DecimalInput/DecimalInput";
import styles from "./CrmGridAddDecimalFilterDialog.module.scss";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { t } from "i18next";

export interface ICrmGridAddDecimalFilterDialogProps {
    field: ICrmField;
    queryRef: MutableRefObject<any>;
    setIsValid: (isValid: boolean) => void;
    onEnter?: () => void;
}

export function CrmGridAddDecimalFilterDialog(props: ICrmGridAddDecimalFilterDialogProps) {
    const [isValidLeft, setIsValidLeft] = useState<boolean>(true);
    const [isValidRight, setIsValidRight] = useState<boolean>(true);

    const leftValueRef = useRef<number>(props.queryRef.current?.["$gte"]);
    const rightValueRef = useRef<number>(props.queryRef.current?.["$lte"]);

    useEffect(() => {
        props.setIsValid(isValidLeft && isValidRight);
    }, [isValidLeft, isValidRight]);

    const onChangedLeft = (value: any) => {
        if (value == null || value === "" || isNaN(value)) {
            if (props.queryRef.current?.["$lte"] == null) {
                props.queryRef.current = null;
            }
            else {
                let newQuery = props.queryRef.current;
                delete newQuery["$gte"];
                props.queryRef.current = newQuery;
            }
        }
        else {
            let newQuery = props.queryRef.current ?? {};
            newQuery["$gte"] = value;
            props.queryRef.current = newQuery;
        }
    }

    const onChangedRight = (value: any) => {
        if (value == null || value === "" || isNaN(value)) {
            if (props.queryRef.current?.["$gte"] == null) {
                props.queryRef.current = null;
            }
            else {
                let newQuery = props.queryRef.current;
                delete newQuery["$lte"];
                props.queryRef.current = newQuery;
            }
        }
        else {
            let newQuery = props.queryRef.current ?? {};
            newQuery["$lte"] = value;
            props.queryRef.current = newQuery;
        }
    }

    const onEnter = () => {
        onChangedLeft(leftValueRef.current);
        onChangedRight(rightValueRef.current);
        
        if (isValidLeft && isValidRight) {
            props.onEnter && props.onEnter();
        }
    }

    return <div className={styles.container}>
        <DecimalInput
            placeholder={t("from")}
            initialValue={props.queryRef.current?.["$gte"]}
            valueRef={leftValueRef}
            onChanged={onChangedLeft}
            setIsValid={setIsValidLeft}
            autoFocus
            onEnter={onEnter}
        />
        <span>–</span>
        <DecimalInput
            placeholder={t("to")}
            initialValue={props.queryRef.current?.["$lte"]}
            valueRef={rightValueRef}
            onChanged={onChangedRight}
            setIsValid={setIsValidRight}
            onEnter={onEnter}
        />
    </div>;
}