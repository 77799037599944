import urlJoin from "url-join";

export const signinPagePath = '/signin';
export const signupPagePath = '/signup';
export const registrationPagePath = '/registration';
export const tablePath= (tableId: string) => `/${tableId}`;
export const maintancePage = '/maintance.html';
export const addReportPath = '/add-report';
export const addIntegrationPath = '/add-integration';
export const addDataImportPath = '/add-data-import';
export const aiChatPath = '/ai-chat';
export const profilePath = '/profile'

export const entityCreatePath = (tableId:string) => `/${tableId}/create`;
export const entityEditPath = (tableId:string, orderId: string)=> urlJoin(tablePath(tableId), 'edit', orderId);
export const commentsEditPath = (tableId:string, orderId: string, fieldId: string)=> urlJoin(entityEditPath(tableId, orderId), fieldId);