import axios from 'axios';
import {IAddModuleRequest, IDeleteGeneratedModuleRequest, IGeneratedModuleInfo, IGetAllRequest, IManualModuleInfo } from '@core/Models/autogenerated/plugins.models';

const endpoint = '/api/modules';

export async function modulesApiGetAll(req: Partial<IGetAllRequest>): Promise<IManualModuleInfo[]> {
    const resp = await axios.post(`${endpoint}/ByTenant`, JSON.stringify(req));
    return resp.data;
}

export async function pluginsApiGetGeneratedModules(req: Partial<IGetAllRequest>): Promise<IGeneratedModuleInfo[]> {
    const resp = await axios.post(`${endpoint}/GetGeneratedModules`, JSON.stringify(req));
    return resp.data;
}


export async function modulesApiAddGeneratedModule(req: Partial<IAddModuleRequest>): Promise<void> {
    const resp = await axios.post(`${endpoint}/AddGeneratedModule`, JSON.stringify(req));
    return resp.data;
}

export async function modulesApiDeleteGeneratedModule(req: Partial<IDeleteGeneratedModuleRequest>): Promise<void> {
    const resp = await axios.post(`${endpoint}/DeleteGeneratedModule`, JSON.stringify(req));
    return resp.data;
}





