import axios, { AxiosResponse } from 'axios';
import {
    IAddRequest, 
    IAddRangeRequest, IGet2Request, IEventsQueryRequest, IOperationServiceResult, ICrmOperationEvent
} from '@core/Models/autogenerated/operation.models';

const endpoint = '/api/operation';

export async function operationApiGet(req: Partial<IGet2Request>): Promise<IOperationServiceResult> {
    const resp = await axios.post(`${endpoint}/Get2`, JSON.stringify(req));
    return resp.data;
}

export async function operationApiAdd(req: IAddRequest): Promise<AxiosResponse<any, any>> {
    const resp = await axios.post(`${endpoint}/Add`, JSON.stringify(req));
    return resp;
}

export async function operationApiAddRange(req: IAddRangeRequest): Promise<unknown> {
    await axios.post(`${endpoint}/AddRange`, JSON.stringify(req));
    return Promise.resolve(null);
}

export async function operationApiQuery(req: Partial<IEventsQueryRequest>): Promise<ICrmOperationEvent[]> {
    const resp = await axios.post(`${endpoint}/Query`, JSON.stringify(req));
    return resp.data;
}