import Overlay from 'react-overlays/Overlay';
import {MutableRefObject, useRef} from 'react';
import styles from './CrmCellDateEditor.module.scss';
import './CrmCellDateEditor.css'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { DateTime } from "luxon";
import 'react-datepicker/dist/react-datepicker.css';
import {toUtcDateWithoutTime} from '@core/Helpers/js-date-transformations';
import { IMousePosition } from '@pluginShared/core-ui-api';
import { t } from 'i18next';
import { InputValidator } from 'src/App/Pages/Shared/OrderField/InputValidator/InputValidator';

export interface ICrmCellDateEditorProps {
    targetRef: MutableRefObject<any>;
    value: any;
    valueRef: MutableRefObject<number | null>;
    onSave: () => void;
    onHide: () => void;
    popperConfig?: any;
}

export function CrmCellDateEditor(compProps: ICrmCellDateEditorProps) {
    const hostRef = useRef<any>();

    const handleClear = (e: any) => {
        compProps.valueRef.current = null;
        compProps.onSave();
    };

    const isValid = InputValidator.validateDecimal(compProps.value);

    const dateValue = compProps.value != null && isValid
        ? dayjs(DateTime.fromSeconds(parseFloat(compProps.value as any)).toJSDate())
        : undefined;
    
    const onChange = (date: any) => {
        if (date == null) {
            compProps.valueRef.current = null;
            compProps.onSave();
            return;
        }

        compProps.valueRef.current = toUtcDateWithoutTime(date.toDate());
        compProps.onSave();
    };

    const handleToday = () => {
        onChange(dayjs());
    }

    return (<Overlay
        show
        flip
        placement={'right-start'}
        rootClose
        onHide={compProps.onHide}
        target={compProps.targetRef}
        popperConfig={compProps.popperConfig}
        ref={hostRef}
    >
        {({ show, props, arrowProps, placement }) => 
            <div {...props} className={styles.host}>
                <DatePicker
                    open
                    value={dateValue}
                    onChange={onChange}
                    popupStyle={{
                        position: "static",
                    }}
                    style={{
                        display: 'none',
                    }}
                    getPopupContainer={() => hostRef.current}
                    showNow={false}
                    renderExtraFooter={() =>
                        <div style={{ textAlign: "center" }}>
                            <span className={styles.footerSpan} onClick={handleToday}>{t("today")}</span>
                            <span className={styles.footerSpan} onClick={handleClear}>{t("clear")}</span>
                        </div>
                    }
                />
            </div>
        }
    </Overlay>)
}

