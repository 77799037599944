import * as event_migrations from './Implementation/event_migrations'
import { EventExecutor } from './Implementation/EventExecutor';
import _ from 'lodash';
import type { IDbEntity } from '@core/JsStore/stores/shared/models/i-db-entity';
import type { ICrmOperationEvent } from '@core/Models/autogenerated/operation.models';
import { EventStreamFetchStatus, IEventStream, IEventStreamFetchResult } from './IEventStream';
import type { ICrmArrayUpdateEvent } from '@core/Models/i-crm-array-operation-events';
import type { ICrmOperationEventDecoded } from '@core/Models/i-crm-operation-event-decoded';
import type { IEntity, IEntityData } from '@core/Models/i-entity';
import type { IOrderChanges } from '@core/Models/i-order-changes';
import type { IKvStore } from '@core/Stores/KVStore';
import { CachedStore } from './Implementation/CachedStore';
import type { ISnapshotStore } from '@core/Stores/OrderSnapshotStore';
import Logger from 'js-logger';
import type { ICrmArrayElement } from '@core/Models/i-array-element';
import { CalculateArrayModifications } from './Implementation/array_operations';
import { lock } from '@core/Helpers/lock';
import { LiveQueryMonitor } from './Implementation/LiveQueryMonitor';
import { IQuerySpecificatoin } from "./Implementation/LiveQuery";
import { LiveQuery } from "./Implementation/LiveQuery";
import { v4 as uuidv4 } from 'uuid';
import { ShortQueueStore } from './Implementation/ShortQueueStore';

export type DataTransformationFunction = (entityData: IEntityData) => IEntityData;

export function fullDataCacheEnabled() {
    try {
        const result_str = localStorage.getItem('full_data_cache');
        const result = result_str && JSON.parse(result_str as string);
        return result;
    } catch {
        return false;
    }
}

export interface IEventSourcingStore {
    tableId: string;

    dispose(): void;
    get(entityId: string): Promise<IEntity | null>;
    bulkGet(ids: string[]): Promise<(IDbEntity|null)[]>
    add(entity : IEntity) : Promise<void>;
    update(id: string, entityChanges: IEntityData): Promise<void>;
    updateArray(entityId: string, fieldId: string, oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]): Promise<void>;
    delete(entityId: string): Promise<void>;
    count(): Promise<number>;
    clear() : Promise<void>;
    process(abortController: AbortController) : Promise<void>;

    onUpdateEntity(handler: (oldEntity: IEntity, newEntity: IEntity) => Promise<void>): void;
    onUpdateEntityRemove(handler: (oldEntity: IEntity, newEntity: IEntity) => Promise<void>): void
    onNewEntity(handler: (entity: IEntity) => Promise<void>): void;
    onNewEntityRemove(handler: (entity: IEntity) => Promise<void>): void;
    onRemoveEntity(handler: (entity: IEntity) => Promise<void>): void;
    onRemoveEntityRemove(handler: (entity: IEntity) => Promise<void>): void;
    onEntitySetChanged(handler: (changes: EntitySetChanges) => Promise<void>): void;
    onEntitySetChangedRemove(handler: (changes: EntitySetChanges) => Promise<void>): void;
}

export namespace EntitySetChanges {
    export type AddHandler = (entity: IEntity) => void;
    export type UpdateHandler = (oldEntity: IEntity, newEntity: IEntity) => void;
    export type DeleteHandler = (entity: IEntity) => void;
}

export class EntitySetChanges {
    added  = new Map<string, IEntity>();
    updated = new Map<string, {oldEntity: IEntity, newEntity: IEntity}>();
    deleted = new Map<string, IEntity>();


    public pushAdd(entity: IEntity) {
        this.added.set(entity.id, entity);
        this.updated.delete(entity.id);
        this.deleted.delete(entity.id);
    }

    public pushUpdate(oldEntity:IEntity, newEntity: IEntity) {
        if (this.updated.has(newEntity.id)) {
            this.updated.set(newEntity.id, {
                oldEntity: this.updated.get(newEntity.id)!.oldEntity,
                newEntity: newEntity,
            });
        } else {
            this.updated.set(newEntity.id, {
                oldEntity: oldEntity,
                newEntity: newEntity,
            });
        }
    }

    public pushDelete(entity: IEntity) {
        this.deleted.set(entity.id, entity);
    }

    public handle(
            addHandler: EntitySetChanges.AddHandler,
            updateHandler: EntitySetChanges.UpdateHandler,
            deleteHandler: EntitySetChanges.DeleteHandler,
        ) {

        this.added.forEach((entity, id) => addHandler(entity));
        this.updated.forEach(({oldEntity, newEntity}, id) => updateHandler(oldEntity, newEntity));
        this.deleted.forEach((entity, id) => deleteHandler(entity));
    }

    public getChangedIds() : IOrderChanges {
        return {
            addedIds: Array.from(this.added.keys()),
            updatedIds: Array.from(this.updated.keys()),
            deletedIds: Array.from(this.deleted.keys()),
        }
    }

    public isEmpty() {
        return this.added.size == 0
                && this.updated.size == 0
                && this.deleted.size == 0
    }
}

export class EventSourcingStore implements IEventSourcingStore {
    public id = uuidv4();
    public tableId: string;
    public eventStream: IEventStream;

     //this queue added for durability. for example when user modify data but database is busy.
     //in such case device may be turned off before event propagated
    protected shortEventQueue : ShortQueueStore<ICrmOperationEventDecoded>;

    private store: CachedStore
    private persistentStore : ISnapshotStore;
    private metaStore: IKvStore<any>;

    private dataTransformation : DataTransformationFunction;
    
    private eventExecutor : EventExecutor;

    private cursorLock: string;
    private storeLock: string;
    private extended_logs: boolean;

    private clientInstance: string;
    private queryVars: Map<string, any> = new Map(); //variables that can be used in query with "$$variable"

    private liveQueryCache = new Map<string, LiveQueryMonitor>();

    private onUpdateEntityHandlers: Array<(oldEntity: IEntity, newEntity: IEntity) => Promise<void>> = [];
    private onNewEntityHandlers: Array<(entity: IEntity) => Promise<void>> = [];
    private onRemoveEntityHandlers: Array<(entity: IEntity) => Promise<void>> = [];
    private onEntitySetChangedHandlers: Array<(changes: EntitySetChanges) => Promise<void>> = [];

    private postprocess: (entity: IDbEntity) => IDbEntity;

    constructor(tableId: string
        , eventStream: IEventStream
        , persistentStore: ISnapshotStore
        , metaStore: IKvStore<any>
        , dataTransformation: DataTransformationFunction
        , postprocess: (entity: IDbEntity) => IDbEntity
        , clientInstance: string
        , queryVars: Map<string, any>
        , extenedLogsEnabled: boolean) {
        this.tableId = tableId;
        this.eventStream = eventStream;
        this.persistentStore = persistentStore;

        this.store = new CachedStore(this.persistentStore);
        this.metaStore = metaStore;
        this.shortEventQueue = new ShortQueueStore<ICrmOperationEventDecoded>(
            tableId + '_short', 
            (e) => this.propagateEvent(e)
        );

        this.dataTransformation = dataTransformation;
        this.eventExecutor = new EventExecutor();

        this.cursorLock = `EventSourcingStoreCursor` + tableId;
        this.storeLock = `EventSourcingStoreStore` + tableId;
        
        this.extended_logs = extenedLogsEnabled;
        this.clientInstance = clientInstance;
        this.queryVars = queryVars;

        this.postprocess = postprocess;
    }

    public dispose = () => {
        this.liveQueryCache.forEach(q => {
            this.disposeLiveQueryMonitor(q);
        });
    }

    public async get(entityId: string): Promise<IEntity | null> {
        const dbEntity = await this.store.get(entityId, false);
        if (!dbEntity)
            return null;
    
        return this.db2entity(dbEntity);
    }


    public async bulkGet(ids: string[]): Promise<(IDbEntity|null)[]> {
        return await this.persistentStore.bulkGet(ids);
    }

    public preloadLiveQuerieMonitors(queries: IQuerySpecificatoin[]) {
        let needPrefill: LiveQueryMonitor[] = [];

        for (let q of queries) {
            try {
                let cacheKey = LiveQuery.fingerprint(q);
                if (!this.liveQueryCache.has(cacheKey)) {
                    const liveQuery = LiveQuery.fromMongoQuery(q, this.queryVars);
                    const monitor = new LiveQueryMonitor(liveQuery, this);
                    this.onEntitySetChanged(monitor.handleEntitySetChanged);

                    this.liveQueryCache.set(cacheKey, monitor);
                    needPrefill.push(monitor);
                }
            }
            catch (err) {
                Logger.error("failed to load live query", q, err);
            }
        }

        if (needPrefill.length > 0) {
            Logger.debug(`[preloadLiveQueries]start query prefill of ${needPrefill.length} queries`);
            this.persistentStore.each(dbEntity => {
                const entity = this.db2entity(dbEntity);
    
                for (let monitor of needPrefill)
                    monitor.prefill(entity);
            })
            .then(()=> {
                for (let monitor of needPrefill)
                    monitor.completePrefill();
                Logger.debug(`[preloadLiveQueries]prefill of ${needPrefill.length} completed`);
            })
            .catch(err => {
                Logger.error("[preloadLiveQueries] persistentStore.each failed", err);
            });
        }
    }

    protected getLiveQueryMonitor(query: IQuerySpecificatoin) : LiveQueryMonitor {
        this.preloadLiveQuerieMonitors([query]);
        let cacheKey = LiveQuery.fingerprint(query);
        return this.liveQueryCache.get(cacheKey)!;
    }

    protected disposeLiveQueryMonitor(liveQueryMonitor: LiveQueryMonitor) {
        for (const entry of Array.from(this.liveQueryCache.entries())) {
            const [key, monitor] = entry;
            if (monitor == liveQueryMonitor) {
                this.liveQueryCache.delete(key);
            }
        }
        this.onEntitySetChangedRemove(liveQueryMonitor.handleEntitySetChanged);
        liveQueryMonitor.dispose();
    }

    public disposeLiveQueryMonitorByQuery(query: IQuerySpecificatoin) {
        const cacheKey = LiveQuery.fingerprint(query);
        const monitor = this.liveQueryCache.get(cacheKey);
        if (monitor) {
            this.disposeLiveQueryMonitor(monitor);
        }
    }


    public add(entity : IEntity) : Promise<void> {
        let event = {
            type: 'AddOrder',
            tableId: this.tableId,
            entityId: entity.id,
            data: JSON.stringify(entity.data),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.clientInstance}),
            decodedData: entity.data,
        } as ICrmOperationEventDecoded;

        return this.shortEventQueue.enqueue(event);
    }

    public update(id: string, entityChanges: IEntityData): Promise<void> {
        let event = {
            type: 'UpdateOrder',
            tableId: this.tableId,
            entityId: id,
            data: JSON.stringify(entityChanges),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.clientInstance}),
            decodedData: entityChanges,
        } as ICrmOperationEventDecoded;

        return this.shortEventQueue.enqueue(event);
    }
    
    public updateArray(entityId: string, fieldId: string, oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]): Promise<void> {
        const {add, remove, move} = CalculateArrayModifications(oldValues, newValues);

        if (add.length == 0 && remove.length == 0 && move.length == 0) {
            return Promise.resolve();
        }
    
        const opEventData: ICrmArrayUpdateEvent = {
          fieldId,
          add,
          remove,
          move
        };
    
        let event = {
            type: 'ArrayUpdate',
            tableId: this.tableId,
            entityId,
            data: JSON.stringify(opEventData),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.clientInstance}),
            decodedData: opEventData
        } as ICrmOperationEventDecoded

        return this.shortEventQueue.enqueue(event);
    }
    
    public delete(entityId: string): Promise<void> {
        let event = {
            type: 'DeleteOrder',
            tableId: this.tableId,
            entityId: entityId,
            data: JSON.stringify({}),
            creationContext: JSON.stringify({localId: uuidv4(), clientInstance: this.clientInstance}),
            decodedData: {}
        } as ICrmOperationEventDecoded;

        return this.shortEventQueue.enqueue(event);
    }

    public count(): Promise<number> {
        return this.persistentStore.count();
    }

    public async process(abortController: AbortController) : Promise<void> {
        if (this.extended_logs)
            Logger.debug(`[EventSourcingStore#${this.id}] start processing`);
        const time = performance.now();

        const CURSOR_KEY = 'cursor';

        if (abortController.signal.aborted)
            return;

        try {
            await this.shortEventQueue.process();
        }
        catch(err: any) {
            Logger.error("Error at EventSourcingStore.shortEventQueue.process()", err);
        }

        if (abortController.signal.aborted)
            return;

        await lock(this.cursorLock, async ()=> {
            if (this.extended_logs)
                Logger.debug(`[EventSourcingStore] cursorLock lock acquired ${performance.now() - time} ms`);
            const limit = parseInt(process.env.REACT_APP_EVENTS_LIMIT as string)
            let cursor = await this.metaStore.get(CURSOR_KEY);

            let fetchResult : IEventStreamFetchResult;
            do
            {
                if (abortController.signal.aborted)
                    return;
                
                if (this.extended_logs)
                    Logger.debug(`[EventSourcingStore] event handling started ${performance.now() - time} ms`);
                const batch_time = performance.now();
                let entitySetChanges = new EntitySetChanges();

                fetchResult = await this.eventStream.fetch(cursor, limit);

                if (this.extended_logs)
                    Logger.debug(`[EventSourcingStore] events fetched ${performance.now() - batch_time} ms`);

                if (abortController.signal.aborted)
                    return;        

                await lock(this.storeLock, async () => {
                    if (this.extended_logs)
                        Logger.debug(`[EventSourcingStore] storeLock lock acquired ${performance.now() - batch_time} ms`);

                    this.store = new CachedStore(this.persistentStore);
                    await this.store.preload(fetchResult.events.map(x => x.entityId));
                    if (this.extended_logs)
                        Logger.debug(`[EventSourcingStore] cache preloaded ${performance.now() - batch_time} ms`);

                    for (let event of fetchResult.events) {
                        let decodedEvent = this.decode(event);

                        if (event.type === 'AddOrder' || event.type === 'UpdateOrder') {
                            let transformedData = this.dataTransformation(decodedEvent.decodedData as IEntityData);
                            decodedEvent = { ...event, decodedData : transformedData };
                        }

                        if (abortController.signal.aborted)
                            return;                

                        await this.apply(decodedEvent, entitySetChanges, this.store);
                    }

                    if (this.extended_logs)
                        Logger.debug(`[EventSourcingStore] events applied ${performance.now() - batch_time} ms`);

                    cursor = fetchResult.nextCursor;

                    if (abortController.signal.aborted)
                        return;

                    await this.store.flush();

                    if (this.extended_logs)
                        Logger.debug(`[EventSourcingStore] store flushed ${performance.now() - batch_time} ms`);
                });
                await this.metaStore.set(CURSOR_KEY, cursor);

                if (this.extended_logs)
                    Logger.debug(`[EventSourcingStore] metadata stored ${performance.now() - batch_time} ms`);

                if (!entitySetChanges.isEmpty()) {
                    this.triggerOnEntitySetChanged(entitySetChanges);
                }

                if (this.extended_logs)
                    Logger.debug(`[EventSourcingStore] triggerOnEntitySetChanged ${performance.now() - batch_time} ms`);

                await this.eventStream.process();

                if (this.extended_logs)
                    Logger.debug(`[EventSourcingStore] eventStream processed ${performance.now() - batch_time} ms`);

                if (this.extended_logs && fetchResult.events.length > 0)
                    Logger.debug(`fetched ${fetchResult.events.length} events ${performance.now() - batch_time} ms`);

            } while (fetchResult.status == EventStreamFetchStatus.PartialCompletion);
        });
        
        if (this.extended_logs)
            Logger.debug(`[EventSourcingStore] process spent ${performance.now() - time} ms`);
    }

    public async clear() : Promise<void> {
        await lock(this.storeLock, async () => {
            await this.metaStore.clear();
            await this.persistentStore.clear();
        });
    }

    private decode(event: ICrmOperationEvent): ICrmOperationEventDecoded {
        return {...event, decodedData: JSON.parse(event.data) };
    }

    private async apply(event: ICrmOperationEventDecoded, entitySetChanges: EntitySetChanges, store: CachedStore) : Promise<void> {
        if (event.type == 'PushMessagesToTimelineOrderFieldV2') {
            event = event_migrations.migratePushMessagesToTimelineOrderFieldV2(event);
        }

        switch (event.type) {
            case 'AddOrder': {
                let entity = await store.get(event.entityId);
                if (!entity) {
                    entity = this.eventExecutor.addOrder(event);
                    entity = this.postprocess(entity);
                    await store.insert(event.entityId, entity);
                } else {
                    entity.lastEventNumber = event.id;
                    entity = this.postprocess(entity);
                    await store.upsert(entity!.entityId, entity!);
                }
                entitySetChanges.pushAdd(this.db2entity(entity));
                await this.triggerOnNewEntity(entity);
                break;
            }

            case 'UpdateOrder': {
                let entity = await store.get(event.entityId);

                if (!entity) {
                    Logger.error("UpdateOrder: store doesn't have entity with id:", event.entityId);
                    return;
                }

                let old = _.cloneDeep(entity);
                entity = this.eventExecutor.updateOrder(event, entity);
                entity = this.postprocess(entity);
                await store.upsert(entity!.entityId, entity!);

                //if (!_.isEqual(old.entityData, entity.entityData)) {
                    entitySetChanges.pushUpdate(this.db2entity(old), this.db2entity(entity!));
                    await this.triggerOnUpdateEntity(old, entity!);
                //}
                break;
            }

            case 'DeleteOrder': {
                let entity = await store.get(event.entityId);
                if (entity) {
                    entitySetChanges.pushDelete(this.db2entity(entity));
                    await store.remove(event.entityId);
                    await this.triggerOnRemoveEntity(entity);
                }
                break;
            }

            case 'ArrayUpdate': {
                let entity = await store.get(event.entityId);

                if (!entity) {
                    Logger.error("ArrayUpdate: store doesn't have entity with id:", event.entityId);
                    return;
                }

                let old = _.cloneDeep(entity);

                entity = this.eventExecutor.arrayUpdate(event, entity);
                entity = this.postprocess(entity);

                await store.upsert(entity!.entityId, entity!);

                //if (!_.isEqual(old.entityData, entity.entityData)) {
                    entitySetChanges.pushUpdate(this.db2entity(old), this.db2entity(entity!));
                    await this.triggerOnUpdateEntity(old, entity!);
                //}

                break;
            }
        }
    }

    public db2entity(entity: IDbEntity) : IEntity {
        return {
            id: entity.entityId,
            data: entity.entityData,
            _keywords: entity.keywords,
            _lastEventNumber: entity.lastEventNumber,
        }
    }

    private async propagateEvent(event: ICrmOperationEventDecoded) : Promise<void> {
        const start_time = performance.now();
        let entitySetChanges = new EntitySetChanges();
        Logger.debug(`[EventSourcingStore] start propagating 1: ${event.entityId}. ${performance.now()-start_time} ms`);
        await lock(this.storeLock, async() => {
            let store = new CachedStore(this.persistentStore);

            Logger.debug(`[EventSourcingStore] start propagating 2: ${event.entityId}. ${performance.now()-start_time} ms`);
            await this.apply(event, entitySetChanges, store);
            Logger.debug(`[EventSourcingStore] event applied: ${event.entityId}. ${performance.now()-start_time} ms`);
            await store.flush();
            Logger.debug(`[EventSourcingStore] store flushed: ${event.entityId}. ${performance.now()-start_time} ms`);
        });

        await this.eventStream.add(event);
        Logger.debug(`[EventSourcingStore] event pushed to stream: ${event.entityId}. ${performance.now()-start_time} ms`);

        if (!entitySetChanges.isEmpty()) {
            await this.triggerOnEntitySetChanged(entitySetChanges);
            Logger.debug(`[EventSourcingStore] triggerOnEntitySetChanged triggered: ${event.entityId}. ${performance.now()-start_time} ms`);
        } else {
            Logger.debug(`[EventSourcingStore] skip triggerOnEntitySetChanged: ${event.entityId}. ${performance.now()-start_time} ms`);
        }

        Logger.debug(`propagateEvent spent ${performance.now() - start_time} ms`);
    }

    public onUpdateEntity(handler: (oldEntity: IEntity, newEntity: IEntity) => Promise<void>): void {
        this.onUpdateEntityHandlers.push(handler);
    }
    public onUpdateEntityRemove(handler: (oldEntity: IEntity, newEntity: IEntity) => Promise<void>): void {
        this.onUpdateEntityHandlers = this.onUpdateEntityHandlers.filter(h => h !== handler);
    }
    private async triggerOnUpdateEntity(oldDbEntity: IDbEntity, newDbEntity: IDbEntity): Promise<void> {
        const oldEntity = this.db2entity(oldDbEntity);
        const newEntity = this.db2entity(newDbEntity);

        for (let h of this.onUpdateEntityHandlers.slice(0))
            await h(oldEntity, newEntity);
    }

    public onNewEntity(handler: (entity: IEntity) => Promise<void>): void {
        this.onNewEntityHandlers.push(handler);
    }
    public onNewEntityRemove(handler: (entity: IEntity) => Promise<void>): void {
        this.onNewEntityHandlers = this.onNewEntityHandlers.filter(h => h !== handler);
    }
    private async triggerOnNewEntity(dbEntity: IDbEntity): Promise<void> {
        const entity = this.db2entity(dbEntity);

        for (let h of this.onNewEntityHandlers.slice(0))
            await h(entity);
    }

    public onRemoveEntity(handler: (entity: IEntity) => Promise<void>): void {
        this.onRemoveEntityHandlers.push(handler);
    }
    public onRemoveEntityRemove(handler: (entity: IEntity) => Promise<void>): void {
        this.onRemoveEntityHandlers = this.onRemoveEntityHandlers.filter(h => h !== handler);
    }
    private async triggerOnRemoveEntity(dbEntity: IDbEntity): Promise<void> {
        const entity = this.db2entity(dbEntity);

        for (let h of this.onRemoveEntityHandlers.slice(0))
            await h(entity);
    }

    public onEntitySetChanged(handler: (changes: EntitySetChanges) => Promise<void>): void {
        this.onEntitySetChangedHandlers.push(handler);
    }
    public onEntitySetChangedRemove(handler: (changes: EntitySetChanges) => Promise<void>): void {
        this.onEntitySetChangedHandlers = this.onEntitySetChangedHandlers.filter(h => h !== handler);
    }
    private async triggerOnEntitySetChanged(changes: EntitySetChanges): Promise<void> {
        for (let h of this.onEntitySetChangedHandlers.slice(0))
            await h(changes);
    }
}