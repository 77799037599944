import { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { DataTransformationFunction, EventSourcingStore, fullDataCacheEnabled } from "./EventSourcingStore";
import { IEventStream } from "./IEventStream";
import { KVStore } from "@core/Stores/KVStore";
import { EntitySnapshotStore, ISnapshotStore } from "@core/Stores/OrderSnapshotStore";
import { ICrmField } from "@core/Models/tenantConfig.models";
import { SnapshotStorePassthroughCache } from "@core/Stores/SnapshotStorePassthroughCache";
import { useEffect, useState } from "react";
import { IEntity, IEntityData } from "@core/Models/i-entity";
import { IEventSouringQueryViewState, LiveQueryView } from "./Implementation/LiveQueryView";
import { IQuerySpecificatoin } from "./Implementation/LiveQuery";
import { extenedLogsEnabled } from "@core/Services/logger-service";
import Logger from "js-logger";
import axios from "axios";
import { ICrmArrayElement } from "@core/Models/i-array-element";

const fullDataCache = fullDataCacheEnabled();

export class RpcClientEventSourcingStore extends EventSourcingStore {
    methodUrl: (method:string) => string;

    constructor(tableId: string, eventStream: IEventStream, dataTransformation: DataTransformationFunction, postprocess: (entity: IDbEntity) => IDbEntity, queryVars: Map<string, any>, clientInstance: string, url: string) {
        const metaStore = new KVStore(tableId + '_meta');

        let persistentStore: ISnapshotStore;

        if (fullDataCache)
            persistentStore = new SnapshotStorePassthroughCache(new EntitySnapshotStore(tableId));
        else
            persistentStore = new EntitySnapshotStore(tableId);

        const extended_logs = extenedLogsEnabled();

        super(tableId
            , eventStream
            , persistentStore
            , metaStore
            , dataTransformation
            , postprocess
            , clientInstance
            , queryVars
            , extended_logs);

        this.methodUrl = (method:string) => `${url}/${method}`;
    }

    public static getSchemas(name: string, fields: ICrmField[]) {
        let result = [
            ...EntitySnapshotStore.getSchemas(name, fields),
            ...KVStore.getSchemas(name + '_meta')
        ];
        return result;
    }

    public async get(entityId: string): Promise<IEntity | null> {
        return await axios.post(this.methodUrl("get"), entityId);
    }

    public async bulkGet(ids: string[]): Promise<(IDbEntity|null)[]> {
        return await axios.post(this.methodUrl("bulkGet"), ids);
    }

    public async add(entity : IEntity) : Promise<void> {
        await axios.post(this.methodUrl("add"), entity);
    }

    public async update(id: string, entityChanges: IEntityData): Promise<void> {
        await axios.post(this.methodUrl("update"), {id, entityChanges});
    }

    public async updateArray(entityId: string, fieldId: string, oldValues: ICrmArrayElement[], newValues: ICrmArrayElement[]): Promise<void> {
        await axios.post(this.methodUrl("updateArray"), {entityId, fieldId, oldValues, newValues});
    }

    public async count(): Promise<number> {
        return 1;
    }

    public useGet = (entityId: string): IEntity | null => {
        const [entity, setEntitity] = useState<IEntity | null>(null);

        useEffect(() => {
            if (entityId == null)
                return;

            const req = entityId;
            const eventSource = new EventSource(this.methodUrl('useGet') + `?req=${JSON.stringify(req)}`);

            // Listen for the 'message' event
            eventSource.onmessage = (event) => {
                const update = JSON.parse(event.data);
                switch (update.event) {
                    case "Update":
                        setEntitity(update.entity);
                        break;
                }
            };

            // Listen for the 'end' event
            eventSource.addEventListener('end', (event) => {
                console.log('Stream ended:', event.data);
                eventSource.close();
            });

            // Handle errors
            eventSource.onerror = (error) => {
                console.error('Error with SSE useGet:', error);
                eventSource.close();
            };

            return () => {
                eventSource.close();
            }
        }, [this, entityId]);

        return entity;
    }

    public preloadLiveQuerieMonitors(queries: IQuerySpecificatoin[]) {
    }

    public disposeLiveQueryMonitorByQuery(query: IQuerySpecificatoin) {
    }

    public async process(abortController: AbortController) : Promise<void> {
        // try {
        //     await this.shortEventQueue.process();
        // }
        // catch(err: any) {
        //     Logger.error("Error at EventSourcingStore.shortEventQueue.process()", err);
        // }
        // await this.eventStream.process();
    }

    public useQuerySubscribe = (query: IQuerySpecificatoin, skip: number, limit: number, sortAscending: boolean
        , onArrayUpdate: (entities: IEntity[]) => void
        , onIndividualUpdate: (entities: IEntity[]) => void
        , onNewState: (state: IEventSouringQueryViewState) => void) => {


        useEffect(() => {
            if (query == null)
                return;

            const req = { query, skip, limit, sortAscending };
            const eventSource = new EventSource(this.methodUrl('useQuerySubscribe') + `?req=${JSON.stringify(req)}`);
            //const eventSource = new EventSource(`http://localhost:3002/useQuerySubscribe`);

            onNewState({status: "loading", resultCount: 0});
            onArrayUpdate([]);

            // Listen for the 'message' event
            eventSource.onmessage = (event) => {
                const update = JSON.parse(event.data);
                switch (update.event) {
                    case "ArrayUpdate":
                        onArrayUpdate(update.entities);
                        break;
                    case "IndividualUpdate":
                        onIndividualUpdate(update.entities);
                        break;
                    case "StateUpdate":
                        onNewState(update.state);
                        break;
                }
            };

            // Listen for the 'end' event
            eventSource.addEventListener('end', (event) => {
                console.log('Stream ended:', event.data);
                eventSource.close();
            });

            // Handle errors
            eventSource.onerror = (error) => {
                console.error('Error with SSE:', error);
                eventSource.close();
            };

            return () => {
                eventSource.close();
            }
        }, [this, query, skip, limit, sortAscending]);
    }
}