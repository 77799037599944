import { DateTime } from 'luxon';
import styles from './FormattedDate.module.scss';
import _ from 'lodash';
import { CSSProperties } from 'react';

export interface IFormattedDateProps {
    value: any;
    style?: CSSProperties;
}

export function FormattedDate(props: IFormattedDateProps) {
    const value = parseFloat(props.value);

    if (!_.isNumber(value) || _.isNaN(value)) {
        return <span className={styles.host}>{props.value}</span>;
    }

    const date = DateTime.fromSeconds(value);
    const dateStr = date.toLocaleString();
    return (<span className={styles.host} style={props.style}>{dateStr}</span>);
}
