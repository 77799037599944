import {IDbEntity} from '@core/JsStore/stores/shared/models/i-db-entity';
import {IDbRawData} from '@core/JsStore/stores/shared/models/i-db-raw-data';

export function mapIDbRawToIDbEntity(dbRaw: IDbRawData): IDbEntity {
    const { id, lastEventNumber, ...restRawFields } = dbRaw;

    return {
        lastEventNumber: lastEventNumber as number,
        entityId: id as string,
        entityData: restRawFields
    };
}
