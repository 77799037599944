import { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { DataTransformationFunction, EntitySetChanges, EventSourcingStore, fullDataCacheEnabled } from "./EventSourcingStore";
import { IEventStream } from "./IEventStream";
import { KVStore } from "@core/Stores/KVStore";
import { EntitySnapshotStore, ISnapshotStore } from "@core/Stores/OrderSnapshotStore";
import { ICrmField } from "@core/Models/tenantConfig.models";
import { SnapshotStorePassthroughCache } from "@core/Stores/SnapshotStorePassthroughCache";
import { useEffect, useState } from "react";
import { IEntity, IEntityData } from "@core/Models/i-entity";
import { IEventSouringQueryViewState, LiveQueryView } from "./Implementation/LiveQueryView";
import { IQuerySpecificatoin } from "./Implementation/LiveQuery";
import { extenedLogsEnabled } from "@core/Services/logger-service";
import Logger from "js-logger";
import { ICrmArrayElement } from "@core/Models/i-array-element";

const fullDataCache = fullDataCacheEnabled();

const MONITOR_DISPOSE_TIMEOUT_MINUTES = 360;

export interface IReactEventSourcingStore extends EventSourcingStore {
    useGet(entityId: string): IEntity | null;
    useQuerySubscribe(query: IQuerySpecificatoin, skip: number, limit: number, sortAscending: boolean
        , onArrayUpdate: (entities: IEntity[]) => void
        , onIndividualUpdate: (entities: IEntity[]) => void
        , onNewState: (state: IEventSouringQueryViewState) => void): void
}

export class ReactEventSourcingStore extends EventSourcingStore implements IReactEventSourcingStore{
    constructor(tableId: string
        , eventStream: IEventStream
        , dataTransformation: DataTransformationFunction
        , postprocess: (entity: IDbEntity) => IDbEntity
        , queryVars: Map<string, any>
        , clientInstance: string) {

        const metaStore = new KVStore(tableId + '_meta');

        let persistentStore: ISnapshotStore;

        if (fullDataCache)
            persistentStore = new SnapshotStorePassthroughCache(new EntitySnapshotStore(tableId));
        else
            persistentStore = new EntitySnapshotStore(tableId);

        const extended_logs = extenedLogsEnabled();

        super(tableId
            , eventStream
            , persistentStore
            , metaStore
            , dataTransformation
            , postprocess
            , clientInstance
            , queryVars
            , extended_logs);
    }

    public static getSchemas(name: string, fields: ICrmField[]) {
        let result = [
            ...EntitySnapshotStore.getSchemas(name, fields),
            ...KVStore.getSchemas(name + '_meta')
        ];
        return result;
    }


    public useGet = (entityId: string): IEntity | null => {
        const self = this;
        const [entity, setEntitity] = useState<IEntity | null>(null);

        useEffect(() => {
            let active = true;
            load();
            subscribe();

            return () => {
                active = false;
                unsubscribe();
            }

            function subscribe() {
                self.onUpdateEntity(monitor_change);
                self.onNewEntity(monitor_new);
                self.onRemoveEntity(monitor_remove);
            }

            function unsubscribe() {
                self.onUpdateEntityRemove(monitor_change);
                self.onNewEntityRemove(monitor_new);
                self.onRemoveEntityRemove(monitor_remove);
            }

            async function load() {
                let entity = await self.get(entityId);
                setEntitity(entity);
            }

            async function monitor_change(prev: IEntity, next: IEntity) {
                if (next.id == entityId) {
                    setEntitity(next);
                }
            }
            async function monitor_new(entity: IEntity) {
                if (entity.id == entityId) {
                    setEntitity(entity);
                }
            }
            async function monitor_remove(entity: IEntity) {
                if (entity.id == entityId) {
                    setEntitity(null);
                }
            }

        }, [entityId]);

        return entity;
    }

    public useQuerySubscribe = (query: IQuerySpecificatoin, skip: number, limit: number, sortAscending: boolean
        , onArrayUpdate: (entities: IEntity[]) => void
        , onIndividualUpdate: (entities: IEntity[]) => void
        , onNewState: (state: IEventSouringQueryViewState) => void) => {

        useEffect(() => {
            if (query == null)
                return;

            const monitor = this.getLiveQueryMonitor(query);
            const view = new LiveQueryView(this, monitor, skip, limit, sortAscending);

            // Захватываем монитор
            monitor.acquire();

            onNewState(view.state);
            onArrayUpdate(view.getEntities());

            view.onArrayUpdate(onArrayUpdate);
            view.onIndividualUpdate(onIndividualUpdate);
            view.onNewState(onNewState);

            return () => {
                view.onArrayUpdateRemove(onArrayUpdate);
                view.onIndividualUpdateRemove(onIndividualUpdate);
                view.dispose();
                view.onNewStateRemove(onNewState);

                // Освобождаем монитор
                monitor.release();

                if (query.transient) {
                    setTimeout(() => {
                        if (monitor.getRefCount() == 0) {
                            this.disposeLiveQueryMonitor(monitor);
                        }
                    }, MONITOR_DISPOSE_TIMEOUT_MINUTES * 60 * 1000);
                }
            }
        }, [this, query, skip, limit, sortAscending]);

        return;
    }
}