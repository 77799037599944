import React, { useEffect, useState } from 'react';
import { FieldWrapper } from '@core/VisualComponents/Form/FieldWrapper/FieldWrapper';
import { FieldLabel } from '@core/VisualComponents/Form/FieldLabel/FieldLabel';
import { ICrmField } from '@core/Models/tenantConfig.models';
import { getCachedValuesFromDumbCrmDb } from '@core/JsStore/stores/autocomplete-store';
import { OrderFieldEditor } from '@core/VisualComponents/OrderFieldEditor/OrderFieldEditor';
import { ICrmArrayElement } from '@core/Models/i-array-element';
import { IEntityData } from '@core/Models/i-entity';

export interface IOrderFieldProps {
    tableId: string;
    entityId?: string;
    entityData: IEntityData;
    value: unknown;
    field: ICrmField;
    onValueChanged: (value: string | number | boolean | ICrmArrayElement[] | null) => void;
    useModalWindowEditor?: boolean;
    editState: any;
    setEditState: React.Dispatch<any>;
    onOpenComments: (fieldId: string) => void;
    setIsValid?: (isValid: boolean) => void;
}

export function OrderField(props: IOrderFieldProps) {
    const [autocompleteValues, setAutocompleteValues] = useState<string[] | null>(null);

    useEffect(() => {
        if (!props.field.autocomplete) {
            return;
        }

        const loadAutocompleteValues = async () => {
            const values = await getCachedValuesFromDumbCrmDb(props.tableId, props.field.id);
            setAutocompleteValues(values);
        };

        loadAutocompleteValues().catch(err => {
            throw new Error('Unhandled error occured')
        });
    }, []);

    return (<FieldWrapper key={props.field.id}>
        <FieldLabel caption={props.field.caption}/>
        <OrderFieldEditor
            onChanged={props.onValueChanged}
            field={props.field}
            value={props.value}
            entityId={props.entityId}
            entityData={props.entityData}
            editState={props.editState}
            setEditState={props.setEditState}
            autocompleteValues={autocompleteValues ?? undefined}
            setIsValid={props.setIsValid}
            onOpenComments={props.onOpenComments}
            tableId={props.tableId}
        />
    </FieldWrapper>);
}
