import * as Cache from 'memory-cache';
import {db} from '@core/JsStore/idb';
import {Table} from 'dexie';

export const autocompleteTableName = (tableId: string) => `${tableId}_autocomplete`;

const MaximumAutocompleteValues : number = 1000;
export const AUTOCOMPLETE_VERSION = 2;
export const AUTOCOMPLETE_META_KEY = "autocomplete_version";

export interface IDbAutocompleteValue {
    fieldName: string;
    valuesJson: string;
}

export interface ICachedAutocompleteValue {
    values: Record<string, number>;
}

function getAutocompleteTable(tableId: string): Table {
    return (db as any)[autocompleteTableName(tableId)] as Table;
}

function getCacheKey(tableId: string, fieldName: string): string {
    return `autocomplete_${tableId}_${fieldName}`;
}

function getChangedCacheKey(tableId: string): string {
    return `autocomplete_changed_cache_${tableId}`;
}

async function setAutocompleteValueToCache(tableId: string, fieldName: string, cachedValues: ICachedAutocompleteValue, value: string): Promise<void> {
    const keysToRemove = Object.keys(cachedValues.values).slice(0, -MaximumAutocompleteValues);
    for (const key of keysToRemove) {
        delete cachedValues.values[key];
    }

    if (!cachedValues.values[value]) {
        cachedValues.values[value] = 0;
    }
    cachedValues.values[value] += 1;

    const changedCacheKey = getChangedCacheKey(tableId);
    const changedCache: Set<string> = Cache.get(changedCacheKey) || new Set();
    if (!changedCache.has(fieldName)) {
        changedCache.add(fieldName);
        Cache.put(changedCacheKey, changedCache);
    }
}

export async function insertAutocompleteValueToDumbCrmDb(tableId: string, fieldName: string, values: Record<string, number>): Promise<void> {
    const keysToRemove = Object.keys(values).slice(0, -MaximumAutocompleteValues);
    for (const key of keysToRemove) {
        delete values[key];
    }

    const autocompleteTable = getAutocompleteTable(tableId);
    await db.transaction('rw', autocompleteTable, async () => {
        await autocompleteTable.add({
            fieldName,
            valuesJson: JSON.stringify(values)
        });
    });

    const cacheKey = getCacheKey(tableId, fieldName);
    Cache.put(cacheKey, {
        values
    } as ICachedAutocompleteValue)
}

export async function restoreCachedValuesFromDumbCrmDb(tableId: string, fieldName: string): Promise<ICachedAutocompleteValue | null> {
    const cacheKey = getCacheKey(tableId, fieldName);
    const autocompleteTable = getAutocompleteTable(tableId);
    return db.transaction('rw', autocompleteTable, async () => {
        const cachedValuesFromDb: IDbAutocompleteValue = await autocompleteTable.where({fieldName}).first();

        if (!cachedValuesFromDb) {
            return null;
        }

        const values = JSON.parse(cachedValuesFromDb.valuesJson);
        const cachedValues = {
            values
        } as ICachedAutocompleteValue;
        Cache.put(cacheKey, cachedValues)

        return cachedValues;
    });
}

export async function getCachedValuesFromDumbCrmDb(tableId: string, fieldName: string): Promise<string[]> {
    const cacheKey = getCacheKey(tableId, fieldName);
    let cachedValues: ICachedAutocompleteValue | null = Cache.get(cacheKey);
    if (!cachedValues)
        cachedValues = await restoreCachedValuesFromDumbCrmDb(tableId, fieldName);

    const comparator = (value1: string, value2: string) => {
        value1 = value1.trim().toLowerCase();
        value2 = value2.trim().toLowerCase();

        if (value1 < value2) {
            return -1;
        }

        if (value1 > value2) {
            return 1;
        }

        return 0;
    }

    return Object.keys(cachedValues?.values ?? {}).slice().sort(comparator);

}

export async function pushAutocompleteValueToDumbCrmDb(tableId: string, fieldName: string, value: string): Promise<void> {
    value = value?.trim()
    const cacheKey = getCacheKey(tableId, fieldName);
    let cachedValues: ICachedAutocompleteValue | null = Cache.get(cacheKey);
    if (!cachedValues)
        cachedValues = await restoreCachedValuesFromDumbCrmDb(tableId, fieldName);

    if (cachedValues) {
        await setAutocompleteValueToCache(tableId, fieldName, cachedValues, value);
    }
    else {
        await insertAutocompleteValueToDumbCrmDb(tableId, fieldName, {[value]: 1});
    }
}

export async function removeAutocompleteValueFromDumbCrmDb(tableId: string, fieldName: string, value: string): Promise<void> {
    value = value?.trim()
    const cacheKey = getCacheKey(tableId, fieldName);
    let cachedValues: ICachedAutocompleteValue | null = Cache.get(cacheKey);
    if (!cachedValues)
        cachedValues = await restoreCachedValuesFromDumbCrmDb(tableId, fieldName);

    if (cachedValues?.values && cachedValues.values[value]) {
        cachedValues.values[value] -= 1;

        if (cachedValues.values[value] <= 0) {
            delete cachedValues.values[value];
        }

        const changedCacheKey = getChangedCacheKey(tableId);
        const changedCache: Set<string> = Cache.get(changedCacheKey) || new Set();
        if (!changedCache.has(fieldName)) {
            changedCache.add(fieldName);
            Cache.put(changedCacheKey, changedCache);
        }
    }
}

export async function flushCachesToDumbCrmDb(tableId: string): Promise<void> {
    const cacheKey = getChangedCacheKey(tableId);
    let cachedKeys: Set<string> | null = Cache.get(cacheKey);
    if (!cachedKeys)
        return;

    const fieldNames = Array.from(cachedKeys);

    const autocompleteTable = getAutocompleteTable(tableId);
    await db.transaction('rw', autocompleteTable, async () => {
        for (let fieldName of fieldNames) {
            const fieldNameCacheKey = getCacheKey(tableId, fieldName);
            const cachedValues: ICachedAutocompleteValue = Cache.get(fieldNameCacheKey);

            await autocompleteTable.put({
                fieldName,
                valuesJson: JSON.stringify(cachedValues.values)
            });
        }
    });

    Cache.put(cacheKey, new Set());
}

export async function clearAutocompleteFromDumbCrmDb(tableId: string): Promise<void> {
    const autocompleteTable = getAutocompleteTable(tableId);
    await db.transaction('rw', autocompleteTable, async () => {
        await autocompleteTable.clear();
    });
}
