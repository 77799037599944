import { ICrmField } from "@core/Models/tenantConfig.models";
import { TimeInput } from "src/App/Pages/Shared/OrderField/TimeInput/TimeInput";
import styles from "./CrmGridAddTimeFilterDialog.module.scss";
import { MutableRefObject, useEffect, useState } from "react";
import { t } from "i18next";

export interface ICrmGridAddTimeFilterDialogProps {
    field: ICrmField;
    queryRef: MutableRefObject<any>;
    onEnter?: () => void;
}

export function CrmGridAddTimeFilterDialog(props: ICrmGridAddTimeFilterDialogProps) {
    const [currLeftValue, setCurrLeftValue] = useState<string | null>(props.queryRef.current?.["$gte"]);
    const [currRightValue, setCurrRightValue] = useState<string | null>(props.queryRef.current?.["$lte"]);

    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChangedLeft = (value: any) => {
        if (value == null || value === "") {
            if (props.queryRef.current?.["$lte"] == null) {
                props.queryRef.current = null;
            }
            else {
                let newQuery = props.queryRef.current;
                delete newQuery["$gte"];
                props.queryRef.current = newQuery;
            }
            setCurrLeftValue(null);
        }
        else {
            let newQuery = props.queryRef.current ?? {};
            newQuery["$gte"] = value;
            props.queryRef.current = newQuery;
            setCurrLeftValue(value);
        }
    }

    const onChangedRight = (value: any) => {
        if (value == null || value === "") {
            if (props.queryRef.current?.["$gte"] == null) {
                props.queryRef.current = null;
            }
            else {
                let newQuery = props.queryRef.current;
                delete newQuery["$lte"];
                props.queryRef.current = newQuery;
            }
            setCurrRightValue(null);
        }
        else {
            let newQuery = props.queryRef.current ?? {};
            newQuery["$lte"] = value;
            props.queryRef.current = newQuery;
            setCurrRightValue(value);
        }
    }

    return <div className={styles.container}>
        <div>
            <TimeInput
                placeholder={t("from")}
                initialValue={currLeftValue}
                onChanged={onChangedLeft}
            />
        </div>
        <span>–</span>
        <div>
            <TimeInput
                placeholder={t("to")}
                initialValue={currRightValue}
                onChanged={onChangedRight}
            />
        </div>
    </div>;
}