import {DateTime} from 'luxon';
import {CSSProperties, useCallback, useEffect, useState} from 'react';
import {toUtcDateWithoutTime} from '@core/Helpers/js-date-transformations';
import styles from './DateInput.module.scss';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { InputValidator } from '../InputValidator/InputValidator';

import { DatePicker } from "antd";
import dayjs from 'dayjs';
import { t } from 'i18next';
import { InvalidSpan } from '../InvalidHint/InvalidHint';

export interface IDateInputProps {
    initialValue: number | null;
    placeholder?: string;
    onChanged: (value: number | null) => void;
    className?: string;
    setIsValid?: (isValid: boolean) => void;
    style?: CSSProperties;
    readonly?: boolean;
    validation?: (value: any) => boolean;
}

export const DateInput = registerComponent(coreUiComponentDescriptions.DateInput, _DateInput);

function _DateInput(props: IDateInputProps) {
    const isValidValue = (value: any) => {
        if (props.validation) {
            return props.validation(value);
        }

        return InputValidator.validateDecimal(props.initialValue);
    }

    const [isValid, setIsValid] = useState<boolean>(isValidValue(props.initialValue));

    const [dateValue, setDateValue] = useState(props.initialValue != null && isValid
        ? dayjs(DateTime.fromSeconds(parseFloat(props.initialValue as any)).toJSDate())
        : undefined);

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(isValid);
        }
    }, [isValid]);
    
    const onChange = useCallback((date: any) => {
        setIsValid(true);
        setDateValue(date);

        if (date == null) {
            props.onChanged(null);
            return;
        }

        props.onChanged(toUtcDateWithoutTime(date.toDate()));
    }, [props.onChanged]);

    const handleClear = (e: any) => {
        onChange(null);
    };

    const handleToday = () => {
        onChange(dayjs());
    }

    return <div onClick={e => e.stopPropagation()}>
        <DatePicker
            value={dateValue?.isValid() ? dateValue : undefined}
            onChange={onChange}
            format={getLocalizedDateFormat()}
            className={styles.host}
            placeholder={isValid ? props.placeholder : t("error")}
            status={isValid ? undefined : "error"}
            showNow={false}
            renderExtraFooter={() =>
                <div style={{ textAlign: "center" }}>
                    <span className={styles.footerSpan} onClick={handleToday}>{t("today")}</span>
                    <span className={styles.footerSpan} onClick={handleClear}>{t("clear")}</span>
                </div>
            }
            preserveInvalidOnBlur
        />
        {isValid ||
            <InvalidSpan/>
        }
    </div>;
}

function getLocalizedDateFormat(): string {
    const now = new Date(2000, 11, 31); // Используем фиксированную дату для предсказуемого анализа
    const formatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const parts = formatter.formatToParts(now);

    const formatMap: { [key: string]: string } = {
        day: 'DD',
        month: 'MM',
        year: 'YYYY'
    };

    const dateFormat = parts
        .map(part => formatMap[part.type] || part.value)
        .join('');

    // dateFormat может выглядеть как "YYYY/MM/dd" или "dd.MM.YYYY" в зависимости от локали
    return dateFormat;
}
