import {CrmCellDateEditor} from './CrmCellDateEditor/CrmCellDateEditor';
import {CSSProperties, MutableRefObject, useRef, useState} from 'react';
import {CrmCellTimeEditor} from './CrmCellTimeEditor/CrmCellTimeEditor';
import { IOnShowEditorReq, coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { registerComponent } from '@core/Plugins/pluginManager';
import { ICrmArrayElement } from '@core/Models/i-array-element';
import { ModalWindow } from '@core/VisualComponents/ModalWindow/ModalWindow';
import { ArrayEditorInner } from '@core/VisualComponents/ArrayComponents/ArrayEditorInner/ArrayEditorInner';
import { t } from 'i18next';
import { StringInput } from 'src/App/Pages/Shared/OrderField/StringInput/StringInput';
import { InputValidator } from 'src/App/Pages/Shared/OrderField/InputValidator/InputValidator';
import { CrmEditOverlay, ICrmEditOverlayProps } from './CrmEditOverlay/CrmEditOverlay';
import { UrlInput } from 'src/App/Pages/Shared/OrderField/UrlInput/UrlInput';
import { DecimalInput } from 'src/App/Pages/Shared/OrderField/DecimalInput/DecimalInput';
import { CheckboxInput } from 'src/App/Pages/Shared/OrderField/CheckboxInput/CheckboxInput';
import { ComboboxInput } from 'src/App/Pages/Shared/OrderField/ComboboxInput/ComboboxInput';
import { CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models.shared';
import { CommentsEditView } from '@core/VisualComponents/Comments/CommentsEditView';
import { PhoneInput } from 'src/App/Pages/Shared/OrderField/PhoneInput/PhoneInput';
import { PrimaryButton } from '@core/VisualComponents/Buttons/PrimaryButton';

export interface ICellEditor {
    req: IOnShowEditorReq;
}

export interface ICrmCellInputEditorsProps {
    onHide: () => void;
    onSave: (value: ICrmArrayElement[] | string | number | boolean | null) => void;
    gridViewRef: MutableRefObject<any>;
    editor: ICellEditor | null;
    readonly?: boolean;
    style?: CSSProperties;
}

export const CrmCellInputEditors = registerComponent(coreUiComponentDescriptions.CrmCellInputEditors, _CrmCellInputEditors);

function _CrmCellInputEditors(props: ICrmCellInputEditorsProps) {

    const popperConfig = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    boundary: 'viewport',
                },
            },
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['left', 'right', 'bottom'],
                },
            },
        ]
    };

    const valueRef = useRef<any>(props.editor?.req.initialValue);
    const [isValid, setIsValid] = useState<boolean>(true);

    const onSave = () => {
        props.onSave(valueRef.current);
        props.onHide();
    }

    const editor = props.editor!;
    const {viewType, placeholder, caption, options} = editor.req.field;

    const overlayProps: ICrmEditOverlayProps = {
        onHide: props.onHide,
        onSave: onSave,
        targetRef: editor.req.targetRef,
        disabledSave: !isValid,
    }

    if (props.readonly || editor.req.field.readonly) {
        return <></>;
    }

    switch (viewType) {
        case CrmFieldViewType.Time:
            return <CrmCellTimeEditor
                value={editor.req.initialValue as number}
                valueRef={valueRef}
                targetRef={editor.req.targetRef}
                onSave={onSave}
                onHide={() => props.onHide()}
                popperConfig={popperConfig}
            />;
        case CrmFieldViewType.Date:
            return <CrmCellDateEditor
                value={editor.req.initialValue as number}
                valueRef={valueRef}
                targetRef={editor.req.targetRef}
                onSave={onSave}
                onHide={() => props.onHide()}
                popperConfig={popperConfig}
            />;
        case CrmFieldViewType.YesNo:
            return <CrmEditOverlay {...overlayProps}>
                <CheckboxInput
                    initialValue={editor.req.initialValue as boolean}
                    placeholder={editor.req.field.caption}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, editor.req.field)}
                />
            </CrmEditOverlay>
        case CrmFieldViewType.Combobox:
            return <CrmEditOverlay {...overlayProps}>
                <ComboboxInput
                    initialValue={editor.req.initialValue as string}
                    options={options ?? []}
                    placeholder={placeholder}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, editor.req.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Timeline:
        case CrmFieldViewType.Comments:
            return <ModalWindow
                onHide={props.onHide}
                title={t("edit-modal-title") + " " + editor.req.field.caption}
            >
                <CommentsEditView
                    field={editor.req.field}
                    tableId={editor.req.tableId}
                    entityId={editor.req.entityId}
                    isReversed
                    style={props.style}
                />
            </ModalWindow>;
        case CrmFieldViewType.String:
        case CrmFieldViewType.MultiString:
        case CrmFieldViewType.Unknown:
            return <CrmEditOverlay {...overlayProps}>
                <StringInput
                    initialValue={editor.req.initialValue as string}
                    placeholder={placeholder}
                    autocompleteValues={editor.req.field.autocomplete ? editor.req.autocompleteValues : undefined}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    type={viewType == CrmFieldViewType.String ? "text" : "textarea"}
                    validation={value => InputValidator.validateByField(value, editor.req.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Url:
            return <CrmEditOverlay {...overlayProps}>
                <UrlInput
                    initialValue={editor.req.initialValue as string}
                    placeholder={placeholder}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, editor.req.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Phone:
            return <CrmEditOverlay {...overlayProps}>
                <PhoneInput
                    initialValue={editor.req.initialValue as string}
                    placeholder={placeholder}
                    autocompleteValues={editor.req.field.autocomplete ? editor.req.autocompleteValues : undefined}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    autoFocus
                />
            </CrmEditOverlay>;
        case CrmFieldViewType.Array:
            return <ModalWindow
                    title={t("edit-modal-title") + " " + editor.req.field.caption}
                    onHide={props.onHide}
                    onCancel={props.onHide}
                    onSave={onSave}
                >
                    <ArrayEditorInner
                        values={editor.req.initialValue}
                        onChanged={values => valueRef.current = values}
                        setIsValid={setIsValid}
                        saveIfInvalid
                        narrow
                        tableId={editor.req.tableId}
                        field={editor.req.field}
                        reversed
                    />
                </ModalWindow>;
        case CrmFieldViewType.Decimal:
            return <CrmEditOverlay {...overlayProps}>
                <DecimalInput
                    initialValue={editor.req.initialValue as string}
                    placeholder={placeholder}
                    onEnter={onSave}
                    valueRef={valueRef}
                    setIsValid={setIsValid}
                    validation={value => InputValidator.validateByField(value, editor.req.field)}
                    autoFocus
                />
            </CrmEditOverlay>;
    };
}
