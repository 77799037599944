import { ICrmField } from "@core/Models/tenantConfig.models";
import { DateTime } from "luxon";
import { CrmText } from "../CrmText";
import { registerComponent } from "@core/Plugins/pluginManager";
import { coreUiComponentDescriptions } from "@pluginShared/core-ui-api";
import { CSSProperties } from "react";
import { ArrayValuesViewer } from "../ArrayComponents/ArrayValuesViewer/ArrayValuesViewer";
import { InputValidator } from "src/App/Pages/Shared/OrderField/InputValidator/InputValidator";
import { t } from "i18next";
import { OrderListViewItemValueError } from "./OrderListViewItemValueError";
import { FormattedDate } from "../FormattedDate/FormattedDate";
import { CrmFieldViewType } from "@core/Models/autogenerated/tenantConfig.models.shared";

export interface IOrdersListViewItemValueProps {
    value: any,
    field: ICrmField,
    key?: string,
    style?: CSSProperties;
}

export const OrdersListViewItemValue = registerComponent(coreUiComponentDescriptions.OrdersListViewItemValue, _OrdersListViewItemValue);

function _OrdersListViewItemValue(props: IOrdersListViewItemValueProps): JSX.Element {
    const { value, field, key } = props;

    if (value == null || value === "") {
        return <span key={key}></span>;
    }

    switch (field.viewType) {
        case CrmFieldViewType.Date:
            if (InputValidator.validateByField(value, field)) {
                return <FormattedDate key={key} style={props.style} value={value}/>
            }
            break;
        case CrmFieldViewType.Comments:
        case CrmFieldViewType.Timeline:
            return <span key={key} style={props.style}>...</span>;
        case CrmFieldViewType.Combobox:
            if (InputValidator.validateByField(value, field)) {
                return <CrmText key={key} style={props.style} text={
                    props.field.options!.find(x => x.value == value)?.label ?? value.toString()
                }/>;
            }
            break;
        case CrmFieldViewType.MultiString:
            if (typeof value != "string" && typeof value != "number") {
                break;
            }
            const str = (value?.toString() ?? '') as string
            let strArr = str.split('\n');
            if(strArr.length > 2){
                strArr = strArr.slice(0,2);
                strArr.push('...');
            }

            let html = [];
            let innerKey = 0;
            for (let i = 0; i < strArr.length; i++) {
                if (i > 0)
                    html.push(<br key={innerKey++}/>);
                html.push(<CrmText key={innerKey++} text={strArr[i]}/>)
            }
            return <span key={key} title={str}>{html}</span>;
        case CrmFieldViewType.Unknown:
        case CrmFieldViewType.String:
        case CrmFieldViewType.Phone:
            if (typeof value == "string" || typeof value == "number") {
                return <CrmText key={key} text={value.toString()} style={props.style}/>;
            }
            break;
        case CrmFieldViewType.Url:
            if (InputValidator.validateByField(value, field)) {
                return <CrmText key={key} text={value.toString()} style={props.style}/>;
            }
            break;
        case CrmFieldViewType.Decimal:
            if (InputValidator.validateByField(value, field)) {
                return <CrmText key={key} text={value.toString()} style={props.style}/>;
            }
            break;
        case CrmFieldViewType.Time:
            if (InputValidator.validateByField(value, field)) {
                return <CrmText key={key} text={value.toString()} style={props.style}/>;
            }
            break;
        case CrmFieldViewType.Array:
            if (Array.isArray(value)) {
                return <ArrayValuesViewer values={value} field={props.field} reversed/>
            }
            break;
        case CrmFieldViewType.YesNo:
            if (InputValidator.validateByField(value, field)) {
                return <CrmText key={key} style={props.style} text={
                    typeof value == "boolean"
                        ? (value ? t("yes") : t("no"))
                        : value.toString()
                }/>
            }
            break;
    }
    
    return <OrderListViewItemValueError value={value} key={key}/>;
}
