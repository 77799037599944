import axios from 'axios';
import {IGetRequest, IRegisterRequest, ISignInResponse, ISignupRequest} from '@core/Models/autogenerated/auth.models';

const endpoint = '/api/auth';
export async function authApiSignin(req: Partial<IGetRequest>): Promise<ISignInResponse> {
    const resp = await axios.post(`${endpoint}/SignIn`, JSON.stringify(req));
    return resp.data;
}

export async function authApiRegister(req: IRegisterRequest): Promise<ISignInResponse> {
    const resp = await axios.post(`${endpoint}/Register`, JSON.stringify(req));
    return resp.data;
}

export async function authApiSignup(req: ISignupRequest): Promise<ISignInResponse> {
    const resp = await axios.post(`${endpoint}/Signup`, JSON.stringify(req), {timeout: 5*60*1000});
    return resp.data;
}