import { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";
import { EntitySnapshotStore, ISnapshotStore } from "./OrderSnapshotStore";
import _, { reject } from "lodash";
import Logger from "js-logger";

export class SnapshotStorePassthroughCache implements ISnapshotStore {
    private cache: Map<string, IDbEntity|null>;
    private preloaded: boolean;
    private persistentStore : ISnapshotStore;
    private preloadPromise: Promise<void> | null;
    private preloadPromiseResolve = ()=>{};
    private preloadPromiseReject = (reason: any)=>{};

    constructor(persistentStore : ISnapshotStore) {
        this.cache = new Map<string, IDbEntity>();
        this.persistentStore = persistentStore;
        this.preloaded = false;

        this.preloadPromise = null;
    }

    public async bulkGet(entityIds: string[]): Promise<(IDbEntity | null)[]> {
        await this.preloadAll();

        let result: (IDbEntity | null)[] = [];
        for (let id of entityIds)
            result.push(await this.get(id));
        return result;
    }

    public async each(handler: (e: IDbEntity) => void): Promise<void> {
        await this.preloadAll();

        let i = 0;
        let iter = this.cache.values();
        let v: ReturnType<typeof iter.next>;
        
        do {
            v = iter.next();

            if (v.value != null) {
                handler(v.value);
            }

            // Use setTimeout to delay execution and allow UI updates
            i++;
            if (i % 10000 === 0) {
                await new Promise<void>(resolve => setTimeout(resolve, 0));
            }
        } while (!v.done);

        // this.cache.forEach((value, key) => {
        //     if (value != null)
        //         handler(value);
        // })
    }
        

    public async bulkPut(bulkPutList: IDbEntity[]): Promise<void> {
        await this.persistentStore.bulkPut(bulkPutList);
        for (let e of bulkPutList) {
            this.cache.set(e.entityId, e);
        }

    }

    public async remove(entityId: string): Promise<void> {
        await this.persistentStore.remove(entityId);
        this.cache.set(entityId, null);
    }
    
    async count(): Promise<number> {
        if (this.preloaded)
            return this.cache.size;
        else
            return this.persistentStore.count();
    }

    public async clear(): Promise<void> {
        this.cache.clear();
        this.persistentStore.clear();
    }

    public async get(entityId: string, cache: boolean = true): Promise<IDbEntity | null> {
        if (!this.preloaded ?? !this.cache.has(entityId)) {
            const entity = await this.persistentStore.get(entityId);
            this.cache.set(entityId, entity);
        }
        return _.cloneDeep(this.cache.get(entityId)!);
    }

    private async preloadAll() : Promise<void> {
        if (this.preloaded)
            return;

        if (this.preloadPromise != null) {
            await this.preloadPromise;
            return;
        }

        this.preloadPromise = new Promise((resolve, reject)=>{
            this.preloadPromiseResolve = resolve;
            this.preloadPromiseReject = reject;
        });

        try {
            await this.persistentStore.each((e) => {
                this.cache.set(e.entityId, e);
            });
            this.preloadPromiseResolve();
        } catch (err: any) {
            Logger.error("[SnapshotStorePassthroughCache] fail to preload", err);
            this.preloadPromiseReject(err);
        }

        this.preloaded = true;
    }
}
