import { ICrmField } from "@core/Models/tenantConfig.models";
import { useRef, useEffect, MutableRefObject } from "react";
import { StringInput } from "src/App/Pages/Shared/OrderField/StringInput/StringInput";

export interface ICrmGridAddStringFilterDialogProps {
    field: ICrmField;
    queryRef: MutableRefObject<any>;
    onEnter?: () => void;
}

export function CrmGridAddStringFilterDialog(props: ICrmGridAddStringFilterDialogProps) {
    const inputRef = useRef<any>(null);
    const valueRef = useRef<string>(null);

    useEffect(() => {
        inputRef.current?.focus();
    })
    
    const onChanged = (value: string | null) => {
        if (value == null || value === "") {
            props.queryRef.current = null;
            return null;
        }
        const newQuery = {
            $containsString: value,
        };
        props.queryRef.current = newQuery;
        return newQuery;
    }

    const onEnter = () => {
        onChanged(valueRef.current);
        props.onEnter?.();
    }

    return <StringInput
        initialValue={props.queryRef.current?.["$containsString"]}
        onChanged={onChanged}
        autoFocus
        valueRef={valueRef}
        onEnter={onEnter}
    />;
}