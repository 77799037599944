import {DateTime} from 'luxon';

export function toUtcDateWithoutTime(date: Date): number|null {
    if(!date)
        return null;

    const offsetInMinutes = date.getTimezoneOffset();
    return DateTime
        .fromJSDate(date)
        .startOf('day')
        .plus({minute: -offsetInMinutes})
        .toUnixInteger();
}
