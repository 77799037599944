import { ChatOpenAI } from "@langchain/openai";
import { DynamicStructuredTool } from "@langchain/core/tools";
import { AgentExecutor, AgentExecutorInput, CreateOpenAIToolsAgentParams, createOpenAIToolsAgent } from "langchain/agents";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { AIMessage, HumanMessage, BaseMessage, ToolMessage, MessageType, StoredMessage } from '@langchain/core/messages';
import { formatToOpenAIToolMessages } from "langchain/agents/format_scratchpad/openai_tools";
import { RunnableWithMessageHistory, RunnableWithMessageHistoryInputs, RunnableConfig } from "@langchain/core/runnables";
import { BaseMessagePromptTemplateLike } from "@langchain/core/prompts";
import { ChatMessageHistory } from 'langchain/stores/message/in_memory';
import { ChainValues } from "@langchain/core/utils/types";
import { load } from '@langchain/core/load'
import { Runnable } from '@langchain/core/runnables';
import { Serialized, SerializedNotImplemented } from '@langchain/core/load/serializable'
import { z } from "zod";

import _ from 'lodash';

import { ICrmUserInfo } from "@core/Models/autogenerated/user.models";
import { ICrmFilter, ITableConfig, ITenantConfig } from "@core/Models/tenantConfig.models";
import { INewUserInfo } from "src/App/AppLayouts/Shared/AIChat/Components/NewUserForm/NewUserForm";
import { ICrmApi } from "@pluginShared/i-crm-api";
import { IInstalledModule } from "@core/Models/installedModule";
import { IGeneratedModuleInfo, ModuleType } from "@core/Models/autogenerated/plugins.models";
import { IEventProcessorEf } from "@core/Models/autogenerated/processes.models";
import i18n from "src/Locale/i18n";
import { appSlice, createApi } from "@core/Redux/Slices/appSlice";
import { addPluginCode, removePlugin } from "@core/Plugins/pluginManager";
import { Dispatch } from "react";
import { store } from "@core/Redux/store";
import { setCurrentFilter } from "@core/Redux/Slices/filtersViewSlice";
import { eventProcessorApiAdd, eventProcessorApiDelete, eventProcessorApiGenerate, eventProcessorApiList } from "@core/Api/event-processor-api";


export const AI_BASE_PATH = "https://aichat.services.persis.ru:61351/Proxy";

export interface IApiForAIAgent {
    getOrderConfig(): ITenantConfig | null;
    saveOrderConfig(config: ITenantConfig | null): Promise<void>;
    getFilters(tableId: string) : ICrmFilter[] | undefined;
    saveFilters(tableId: string, filters: ICrmFilter[]): Promise<void>;
    selectFilter(tableId: string, id: string): void;
    getUserInfo(): ICrmUserInfo | null;
    getInstalledModules(): IInstalledModule[];
    addOrUpdatePluginCode(module: IGeneratedModuleInfo): Promise<IInstalledModule | null>;
    removePlugin(module: IInstalledModule): Promise<void>;
    showNewUserForm(login?: string, password?: string): Promise<INewUserInfo>;
    getCrmApi(): ICrmApi;
    getUserLanguage(): string;
    getCurrentSelectedTable(): ITableConfig | null;
    addEventProcessor(description: string): Promise<IEventProcessorEf>;
    deleteEventProcessor(id: string): Promise<void>;
    listEventProcessors(): Promise<IEventProcessorEf[]>;
}

export abstract class AIApi implements IApiForAIAgent {
    configChanged : boolean = false;
    rebootNeeded: boolean = false;
    config: ITenantConfig | null;
    crmApi: ICrmApi;

    dispatch: Dispatch<any>;
    userInfo: ICrmUserInfo;

    constructor(
        config: ITenantConfig | null,
        userInfo: ICrmUserInfo,
        dispatch: Dispatch<any>
    ) {
        this.config = config;
        this.userInfo = userInfo;
        this.crmApi = createApi(userInfo);
        this.dispatch = dispatch;
    }
    
    getUserLanguage(): string {
        return i18n.language;
    }

    async addOrUpdatePluginCode(info : IGeneratedModuleInfo): Promise<IInstalledModule | null> {
                const existingModule = this.getInstalledModules().find(x => x.info.name == info.name);
        if (existingModule != null) {
            await this.removePlugin(existingModule);
        }

        const installation = await addPluginCode(info, this.crmApi);
        if (installation == null)
            return null;

        this.dispatch(appSlice.actions.addModule(installation));

        return installation;
    }

    async removePlugin(module: IInstalledModule) {
        await removePlugin(module);

        this.dispatch(appSlice.actions.removeModule(module));
    }

    getInstalledModules(): IInstalledModule[] {
        return store.getState().app.pluginsState.modules;
    }
    
    getUserInfo(): ICrmUserInfo | null {
        return this.userInfo;
    }

    getOrderConfig() {
        return this.config;
    }

    async saveOrderConfig(config: any): Promise<void> {
        this.config = config;
        this.configChanged = true;
    }

    getFilters(tableId: string) {
        const tableConfig = this.config?.tables.find(x => x.tableId === tableId);
        if (tableConfig == null)
            throw new Error(`table with id ${tableId} not found`);
        return tableConfig?.filters;
    }

    selectFilter(tableId: string, id: string) {
        setCurrentFilter({id: id, offset: 0});
    }

    async saveFilters(tableId: string, filters: ICrmFilter[]) {
        if (this.config == null)
            return;

        const tables = this.config.tables.map(table => {
            if (table.tableId !== tableId)
                return table;

            return {
                ...table,
                filters: filters
            };
        });


        this.config = {...this.config, tables: tables} as ITenantConfig;
        this.configChanged = true;
    }

    abstract showNewUserForm(login?: string, password?: string): Promise<INewUserInfo>;

    getCrmApi() {
        return this.crmApi;
    }
    
    getCurrentSelectedTable() {
        return store.getState().app.currentTable;
    }

    async addEventProcessor(description: string): Promise<IEventProcessorEf> {
        const response = await eventProcessorApiGenerate({description: description});
        const processor = response.data;
        //todo: user confirmation
        await eventProcessorApiAdd(processor);
        return processor;
    }

    async deleteEventProcessor(id: string): Promise<void> {
        await eventProcessorApiDelete(id);
    }

    async listEventProcessors(): Promise<IEventProcessorEf[]> {
        const response = await eventProcessorApiList();
        return response.data;
    }
}

export abstract class BaseTool {

    abstract name: string;
    abstract description: string;
    schema: any = undefined;

    abstract run(query: string | any): Promise<string>;

    api: IApiForAIAgent;

    constructor(api: IApiForAIAgent) {
        this.api = api;
    }

    toLangchainTool(): DynamicStructuredTool {
        // return new DynamicTool({
        //     name: this.name,
        //     description: this.description,
        //     func: this.run.bind(this)
        // });

        const defaultSchema = z.object({input: z.any().describe("tool input")});

        //Flexible parser for string input that handles most of the GPT input format errors
        defaultSchema.parse = (args: any, params: any) => {
            if (args == null)
                return { input: '' };
            if (typeof args == 'string')
                return { input: args };
            if (typeof args == 'object') {
                if (args.input != null) { 
                    if (typeof args.input == 'string')
                        return { input: args.input };
                    if (typeof args.input == 'object')
                        return { input: JSON.stringify(args.input) };
                }
                else {
                    return { input: JSON.stringify(args) };
                }
            }
            return { input: '' };
        }

        defaultSchema.parseAsync = (args: any, params: any) => Promise.resolve(defaultSchema.parse(args, params));

        return new DynamicStructuredTool({
            name: this.name,
            description: this.description,
            schema: this.schema ?? defaultSchema,
            func: this.invoke
        });
    }
    
    invoke = (input: any): Promise<string> => {
        if (this.schema != null)
            return this.run(input);

        if (input.input != null && typeof(input.input) == "object")
            input.input = JSON.stringify(input.input);

        return this.run(input.input ?? input);
    }

    getState = () => {
        return {
            self: { history: null },
            tools: {},
        } as IToolState;
    }

    setState = (state: IToolState) => {
        return;
    }
}

export type LangChainAgent = Runnable<Record<string, any>, ChainValues>;

export abstract class CompositeTool extends BaseTool {

    abstract name: string;
    abstract description: string;
    schema: any = undefined;

    abstract toolkit: BaseTool[];

    abstract getPrompt(): Promise<string>;

    abstract run(query: string | any): Promise<string>;

    llm: ChatOpenAI;
    history: ChatMessageHistory|null = null;

    api: IApiForAIAgent;

    constructor(api: IApiForAIAgent, access_token: string, modelName: string ='gpt-4o', useHistory: boolean = true) {

        super(api);

        this.api = api;

        let llm = new ChatOpenAI({
            temperature: 0.0,
            openAIApiKey: access_token,
            //modelName:'gpt-4',
            //modelName:'gpt-4-turbo',
            modelName: modelName,
            modelKwargs: { seed: 123 },
            verbose: true,
        }, { basePath: AI_BASE_PATH });

        this.llm = llm;

        if (useHistory)
            this.history = new ChatMessageHistory();
    }

    public saveHistory = async () => {
        if (this.history == null)
            return '';

        const messages = await this.history.getMessages();
        const serialized = messages.map(x => x.toJSON());

        var json = JSON.stringify(serialized);

        return json;
    }

    public loadHistory = async (json: string) => {
        this.history = new ChatMessageHistory();
        const serialized = JSON.parse(json) as Serialized[];
        for (let x of serialized) {
            const message: BaseMessage = await load(JSON.stringify(x));
            this.history.addMessage(message);
        }
    }

    public addHumanMessage = async (text: string) => {
        if (this.history == null) {
            return;
        }

        const message = new HumanMessage(text);

        await this.history.addMessage(message);
    }

    public getState = () => {
        const toolsState: {[name: string]: IToolState} = {};
        for (const tool of this.toolkit) {
            toolsState[tool.name] = tool.getState();
        }

        return {
            self: { history: this.history },
            tools: toolsState,
        } as IToolState;
    }

    public setState = (state: IToolState) => {
        this.history = state.self.history;

        for (const tool of this.toolkit) {
            tool.setState(state.tools[tool.name]);
        }
    };

    public createAgent = async () : Promise<LangChainAgent> => {
        let langchainToolkit = _.map(this.toolkit, (x) => x.toLangchainTool());

        const promptBase =
            (await this.getPrompt())
                .replaceAll("{", "{{")
                .replaceAll("}", "}}");

        let promptTemplate: BaseMessagePromptTemplateLike[] = [
            ["system", promptBase],
        ];
        if (this.history != null)
            promptTemplate.push(["placeholder", "{chat_history}"]);
        promptTemplate.push(["human", "{input}"]);
        promptTemplate.push(["placeholder", "{agent_scratchpad}"])

        const prompt = ChatPromptTemplate.fromMessages(promptTemplate);


        let agent = await createOpenAIToolsAgent({
            llm: this.llm as any, //TODO: fix CI build incompatibility
            tools: langchainToolkit,
            prompt: prompt,
            streamRunnable: false,
        } as CreateOpenAIToolsAgentParams);

        const agentExecutor = new AgentExecutor({
            agent,
            tools: langchainToolkit,
            maxIterations: 5,
            returnIntermediateSteps: true,
        } as AgentExecutorInput);

        if (this.history == null) {
            return agentExecutor
        } else {
            const chainWithHistory = new AgentWithHistory({
                    runnable: agentExecutor,
                    getMessageHistory: () => this.history!,
                    inputMessagesKey: "input",
                    historyMessagesKey: "chat_history",
                },
                this.history
            );

            return chainWithHistory
        }
    }
}

export interface IToolState {
    self: { history: ChatMessageHistory | null };
    tools: {
        [name: string]: IToolState;
    }
}

class AgentWithHistory extends RunnableWithMessageHistory<Record<string, any>, ChainValues> {
    history: ChatMessageHistory;

    constructor(fields: RunnableWithMessageHistoryInputs<Record<string, any>, ChainValues>, history: ChatMessageHistory) {
        super(fields);
        this.history = history;
    }

    async invoke(input: Record<string, any>, options?: Partial<RunnableConfig> | undefined): Promise<ChainValues> {

        options = options ?? {};
        options = {...options, "configurable":{"sessionId":"0"}};
        
        const response = await super.invoke(input, options);

        let toolsHistory = formatToOpenAIToolMessages(response.intermediateSteps);
        this.history.addMessages(toolsHistory);

        return response
    }
}
