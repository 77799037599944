import { Button } from 'antd';
import styles from './PrimaryButton.module.scss';
import React, { MutableRefObject } from 'react';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface IPrimaryButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    children: any
    title?: string;
    style?: any;
    disabled?: boolean;
}

export const PrimaryButton = registerComponent(coreUiComponentDescriptions.PrimaryButton, _PrimaryButton);

function _PrimaryButton(props: IPrimaryButtonProps) {
    return <Button
        title={props.title}
        className={styles.host}
        style={props.style}
        onClick={props.onClick}
        disabled={props.disabled}
        type='primary'
    >
        {props.children}
    </Button>;
}
