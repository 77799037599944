import { MutableRefObject } from 'react';
import styles from './SecondaryButton.module.scss';
import { Button } from 'antd';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';

export interface ISecondaryButtonProps {
    onClick?: (e: React.MouseEvent) => void;
    children: any;
    title?: string;
    style?: any;
    disabled?: boolean;
}

export const SecondaryButton = registerComponent(coreUiComponentDescriptions.SecondaryButton, _SecondaryButton);

function _SecondaryButton(props: ISecondaryButtonProps) {
    return <Button
        title={props.title}
        className={styles.host}
        style={{...props.style}}
        onClick={props.onClick}
        disabled={props.disabled}
    >
        {props.children}
    </Button>;
}
