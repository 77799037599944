import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

const phoneRegex = /\+?(?:(?![\n\r])[\s\-()/])*(\d(?:(?![\n\r])[\s\-()/])*){4,15}/gs;

export function extractStrictCleanPhones(value: string): string[] {
    value = value.trim();
    const phones: string[] = [];

    const phoneMatches = value.match(phoneRegex);
    if (phoneMatches) {
        for (const match of phoneMatches) {
            phones.push(strictCleanPhoneNumber(match));
        }
    }

    return phones;
}

export function extractNormalizedPhones(value: string, language: string): string[] {
    value = value.trim();
    const phones: string[] = [];

    const phoneMatches = value.match(phoneRegex);
    if (phoneMatches) {
        for (const match of phoneMatches) {
            phones.push(normalizePhoneNumber(match, language));
        }
    }

    return phones;
}

/**
 * очищает номер от всего кроме: цифра, +, *, #
 */
function cleanPhoneNumber(phoneNumber: string): string {
    // Define a regex to match allowed characters: digits (0-9), +, #, and *
    const allowedChars = /[^\d+#*]/g;

    // Replace all characters that are not digits, +, #, or * with an empty string
    return phoneNumber.replace(allowedChars, '');
}


/**
 * очищает номер от всего кроме: цифр
 */
export function strictCleanPhoneNumber(phone: string): string {
    // Удаляем все символы, кроме цифр
    return phone.replace(/\D/g, '');
}


/**
 * Функция, приводящая строку с номером телефона в единый формат E164
 */
function normalizePhoneNumber(value: string, language: string): string {
    let normalized: string = extractDigitsAndPlus(value);

    if (!normalized.startsWith('+')) {
        normalized = parseNumberByRegion(normalized, language);
    }

    return normalized;
}

/**
 * Функция, извлекающая цифры и плюс из строки
 */
function extractDigitsAndPlus(input: string): string {
    const plusSign = input.includes('+') ? '+' : '';
    const digits = input.replace(/\D/g, '');

    return plusSign + digits;
}

/**
 * Функция, пробующая преобразовать строку с номером телефона из национального формата в международный
 */
function parseNumberByRegion(value: string, language: string): string {
    const countryCode = languageToCountryCodeOrDefault(language);
    const parsedNumber = parsePhoneNumberFromString(value, countryCode);

    if (parsedNumber && parsedNumber.isValid()) {
        return parsedNumber.number;
    }

    return value;
}

/**
 * Функция, пробующая извлечь код страны из языка системы. По умолчанию возвращает `US`
 */
function languageToCountryCodeOrDefault(language: string = "en-US"): CountryCode {
    try {
        return language.split("-")[1] as CountryCode;
    } catch (Ex) {
        return "US";
    }
}
