import {createAsyncThunk} from '@reduxjs/toolkit';
import Logger from 'js-logger';
import {serializeError} from 'serialize-error';
import { IEventSourcingStore } from '@core/EventSourcing/EventSourcingStore';

export interface IUpdateOrdersCountAsyncResponse {
    tableId: string;
    count: number;
}

export const updateEntityCountAsync = createAsyncThunk(
    'orders/updateOrdersCountAsync',
    async (args: {store: IEventSourcingStore}) => {
        try {
            let count = await args.store.count();

            return {
                tableId: args.store.tableId,
                count,
            } as IUpdateOrdersCountAsyncResponse;
        } catch (err: any) {
            Logger.error('updateOrdersCountAsync failed');
            Logger.error(serializeError(err, { maxDepth: 2}));
            throw new Error(err);
        }
    }
);
