import { ICrmField, ICrmValueOption } from "@core/Models/tenantConfig.models";
import { t } from "i18next";
import { MutableRefObject, useEffect, useRef } from "react";
import { ComboboxInput } from "src/App/Pages/Shared/OrderField/ComboboxInput/ComboboxInput";

export interface ICrmGridAddBooleanFilterDialogProps {
    field: ICrmField;
    queryRef: MutableRefObject<any>;
    onEnter?: () => void;
}

export function CrmGridAddBooleanFilterDialog(props: ICrmGridAddBooleanFilterDialogProps) {
    const inputRef = useRef<any>(null);
    
    useEffect(() => {
        inputRef.current?.focus({preventScroll: true});
    });
    
    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChanged = (value: string | string[] | null) => {
        if (value == null) {
            props.queryRef.current = null;
        }
        else {
            props.queryRef.current = value === "true";
        }
    }

    const options: ICrmValueOption[] = [
        {
            value: "true",
            label: t("yes"),
        },
        {
            value: "false",
            label: t("no"),
        },
    ];

    const getSelectedValue = () => {
        if (props.queryRef.current == null) {
            return null;
        }
        return props.queryRef.current.toString();
    }

    return <ComboboxInput
        options={options}
        initialValue={getSelectedValue()}
        placeholder={props.field.placeholder}
        onChanged={onChanged}
    />;
}