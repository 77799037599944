import { Route, Routes } from 'react-router-dom';
import { CreateOrderPage } from './CreateOrderPage';
import { EditOrderPage } from './EditOrderPage';
import { ListOrdersPage } from './ListOrdersPage';

export function OrdersPage(props: {tableId: string}) {
    return (
        <Routes>
            <Route path="" element={<ListOrdersPage tableId={props.tableId}/>} />
            <Route path="create/*" element={<CreateOrderPage  tableId={props.tableId}/>} />
            <Route path="edit/:entityId/*" element={<EditOrderPage  tableId={props.tableId}/>} />
        </Routes>
    );
}
