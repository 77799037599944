//todo: rollback
//import * as lokijs from 'lokijs';
import lokijs from 'lokijs';

export class LokijsHelpers {
    static sortHelper(prop1: string, prop2: string, desc: boolean) {
        if ((lokijs as any).Comparators.aeq(prop1, prop2)) return 0;
    
        if ((lokijs as any).Comparators.lt(prop1, prop2, false)) {
            return (desc) ? (1) : (-1);
        }
    
        if ((lokijs as any).Comparators.gt(prop1, prop2, false)) {
            return (desc) ? (-1) : (1);
        }
    
        // not lt, not gt so implied equality-- date compatible
        return 0;
    }
    
    static getIn(object: any, path: string | string[], usingDotNotation: boolean) {
        if (object == null) {
            return undefined;
        }
        if (typeof (path) === "string" && !usingDotNotation) {
            return object[path];
        }
    
        if (typeof (path) === "string") {
            path = path.split(".");
        }
    
        if (!Array.isArray(path)) {
            throw new Error("path must be a string or array. Found " + typeof (path));
        }
    
        var index = 0,
            length = path.length;
    
        while (object != null && index < length) {
            object = object[path[index++]];
        }
        return (index && index == length) ? object : undefined;
    }
    
    static compoundeval(properties: any, obj1: any, obj2: any) {
        var res = 0;
        var prop, field, val1, val2, arr, path;
        for (var i = 0, len = properties.length; i < len; i++) {
            prop = properties[i];
            field = prop[0];
            if (~field.indexOf('.')) {
                arr = field.split('.');
                val1 = LokijsHelpers.getIn(obj1, arr, true);
                val2 = LokijsHelpers.getIn(obj2, arr, true);
            } else {
                val1 = obj1[field];
                val2 = obj2[field];
            }
            res = LokijsHelpers.sortHelper(val1, val2, prop[1]);
            if (res !== 0) {
                return res;
            }
        }
        return 0;
    }
    
    static binarySearchExpand<T>(from: number, to: number, key: keyof T, data: T[], index: number[])
        : { from: number, to: number, leftExpand: number, rightExpand: number } 
    {
        let rightExpand = 0;
        if (to < 0)
            to = 0;
        if (to > index.length-1)
            to = index.length-1;
        if (to < index.length-1) {
            const tail = data[index[to]][key];
            while (to < index.length && data[index[to]][key] == tail) {
                to++;
                rightExpand++;
            }
            if (to >= index.length) {
                to--;
                rightExpand--;
            }
        }
    
        let leftExpand = 0;
        if (from < 0)
            from = 0;
        if (from > index.length-1)
            from = index.length-1;
        if (from > 0) {
            const tail = data[index[from]][key];
            while (from > 0 && data[index[from]][key] == tail) {
                from--;
                leftExpand++;
            }
            if (from < 0) {
                from++;
                leftExpand--;
            }
        }
    
        return { from, to, leftExpand, rightExpand };
    
    }
    
    public static indexTake<T extends object>(collection: Collection<T>, skip: number, limit: number, orderBy: [keyof T, boolean][]): T[] {
        let data;
        if (orderBy.length > 0) {
            const key = orderBy[0][0];
            const descending = orderBy[0][1];
    
            if (collection.binaryIndices.hasOwnProperty(key)) {
                collection.ensureIndex(key);
                const index = collection.binaryIndices[key].values;
                let expandSkip = 0;
                
                let fromOrig = 0;
                let toOrig = 0;
                if (!descending) {
                    fromOrig = skip;
                    toOrig = skip + limit;
                } else {
                    fromOrig = index.length - skip - limit - 1;
                    toOrig = index.length - skip - 1;
                }
    
                if (fromOrig >= index.length || toOrig < 0)
                    return [];
    
                let { from, to, leftExpand, rightExpand } = LokijsHelpers.binarySearchExpand(fromOrig, toOrig, key, collection.data, index );
                data = index.slice(from, to+1).map(i => collection.data[i]);
    
                if (!descending) {
                    expandSkip = leftExpand;
                } else {
                    expandSkip = rightExpand;
                    data = data.reverse();
                }
    
                const comparator = (a: T, b: T) => {
                    return LokijsHelpers.compoundeval(orderBy, a, b);
                }
                data = data.sort(comparator);
                return data.slice(expandSkip, expandSkip + limit);
            } else {
                throw new Error(`there is no index for ${orderBy[0]}`);
            }
        } else {
            return collection.data.slice(skip, skip + limit);
        }
    }

    public static indexMin<T extends object>(collection: Collection<T>, propname: keyof T) {
        if (collection.binaryIndices.hasOwnProperty(propname)) {
            collection.ensureIndex(propname);
            const index = collection.binaryIndices[propname].values;
            if (index.length > 0) {
                const entry = collection.data[index[0]]
                return entry[propname];
            }
        } else {
            throw new Error(`there is no index for ${propname.toString()}`);
        }

        return undefined;
    }
}