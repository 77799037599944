import { EventRemoteStream } from '@core/EventSourcing/EventRemoteStream';
import { EventSourcingStore, fullDataCacheEnabled } from '@core/EventSourcing/EventSourcingStore';
import { mapAccordingToSchema } from '@core/JsStore/mappers/map-according-to-db-schema';
import { flushCachesToDumbCrmDb, pushAutocompleteValueToDumbCrmDb, removeAutocompleteValueFromDumbCrmDb } from '@core/JsStore/stores/autocomplete-store';
import { IEntityData } from '@core/Models/i-entity';
import { CrmFieldDataTransormation, ITableConfig } from '@core/Models/tenantConfig.models';
import { selectDbSchema, selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import { useAppDispatch, useAppSelector } from '@core/Redux/hooks';
import { applyFromYmdToUnixTimestampTransormation } from '@core/ServiceComponents/OperationEventPoller/apply-field-data-transormations';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { QueueStore } from './QueueStore';
import { ICrmOperationEvent } from '@core/Models/autogenerated/operation.models';
import { updateEntityCountAsync } from '@core/Redux/Slices/ordersSlice/thunks/updateOrdersCountAsync';
import { AllFilter, QueryFilter } from 'src/App/Pages/OrdersPage/OrderFilters/OrderFilters';
import Logger from 'js-logger';
import { serializeError } from 'serialize-error';
import { IQuerySpecificatoin } from "@core/EventSourcing/Implementation/LiveQuery";
import { fakeUserNameKey } from '@core/Constants/app-storage-keys';
import { updateDownloadingProgress, updateUploadingQueueLength } from '@core/Redux/Slices/ordersSlice/storesSlice';
import { generateKeywordsByEntity } from '@core/EventSourcing/Implementation/KeywordsGenerator';
import { IDbEntity } from '@core/JsStore/stores/shared/models/i-db-entity';
import { IReactEventSourcingStore, ReactEventSourcingStore } from '@core/EventSourcing/ReactEventSourcingStore';
import i18n from 'src/Locale/i18n';
import { RpcClientEventSourcingStore } from '@core/EventSourcing/RpcClientEventSourcingStore';
import { ICrmArrayElement } from '@core/Models/i-array-element';

// Create a context with default values
export const StoreContext = React.createContext<Record<string, ReactEventSourcingStore>>(null!);
export const useStore = (tableId: string) => useContext(StoreContext)[tableId];

function eventSourcingRpc() {
    let enabled = false;
    try {
        const enabled_str = localStorage.getItem('eventSourcingRpcEnabled');
        enabled = enabled_str && JSON.parse(enabled_str as string);
    } catch {
    }

    const url = localStorage.getItem("eventSourcingRpcUrl");
    if (enabled && url) {
        return {enabled, url};
    }
    return {enabled: false, url: ""};
}

export function OrderStoreProvider(props: any) {
    const dispatch = useAppDispatch();

    const dbSchema = useAppSelector(selectDbSchema);
    const tables = useAppSelector(selectTenantConfig)?.tables;
    const userInfo = useAppSelector(selectUserInfo);

    const updateAutocompleteValuesInDb = async (tableId: string, oldEntityData: IEntityData | null, newEntityData: IEntityData | null, prevKey?: string) => {
        const keys = Object.keys({
            ...oldEntityData,
            ...newEntityData,
        });
        
        for (let key of keys) {
            if (key == "_keywords" || key == "id") {
                continue;
            }

            const oldValue = oldEntityData?.[key];
            const newValue = newEntityData?.[key];
            const cacheKey = (prevKey ? prevKey + "." : "") + key;

            if (oldValue !== newValue) {
                if (oldValue != null && oldValue !== "" && (typeof oldValue == "string" || typeof oldValue == "number")) {
                    await removeAutocompleteValueFromDumbCrmDb(tableId, cacheKey, oldValue.toString());
                }
                if (newValue != null && newValue !== "" && (typeof newValue == "string" || typeof newValue == "number")) {
                    await pushAutocompleteValueToDumbCrmDb(tableId, cacheKey, newValue.toString());
                }

                if (Array.isArray(oldValue) || Array.isArray(newValue)) {
                    const oldIds = (Array.isArray(oldValue) ? oldValue : []).map(x => x.id);
                    const newIds = (Array.isArray(newValue) ? newValue : []).map(x => x.id);
                
                    for (const value of (Array.isArray(oldValue) ? oldValue : []).filter(x => !newIds.includes(x.id))) {
                        await updateAutocompleteValuesInDb(tableId, value, null, cacheKey);
                    }
                
                    for (const value of (Array.isArray(newValue) ? newValue : []).filter(x => !oldIds.includes(x.id))) {
                        await updateAutocompleteValuesInDb(tableId, null, value, cacheKey);
                    }
                }
            }
        }
    };
    
    const transformOrderData = useCallback((entityData: IEntityData, tableConfig: ITableConfig) => {
        let changedEntityData: IEntityData | undefined = undefined;

        const keys = Object.keys(entityData);
        for (let key of keys) {
            const value = entityData[key];
            if (value === null || value === undefined)
                continue;

            const fieldConfig = tableConfig?.fields?.find(x => x.id === key
                && x.dataTransformations
                && x.dataTransformations.length > 0);

            if (!fieldConfig)
                continue;

            const dataTransformations = fieldConfig.dataTransformations ?? [];
            for (let dataTransformation of dataTransformations) {
                switch (dataTransformation) {
                    case CrmFieldDataTransormation.FromYmdToUnixTimestamp:
                        const modifiedValue = applyFromYmdToUnixTimestampTransormation(value);
                        if (modifiedValue != null && modifiedValue !== value) {
                            if (!changedEntityData)
                                changedEntityData = {...entityData};

                            changedEntityData[key] = modifiedValue;
                        }
                        break;
                    default:
                        throw new Error('ArgumentOutOfRange');
                }
            }
        }

        return changedEntityData || entityData;
    }, [tables]);

    let stores: Record<string, IReactEventSourcingStore> = useMemo(() => {
        if (tables == null)
            return {};

        let clientInstance = JSON.parse(localStorage.getItem(fakeUserNameKey) ?? '');

        let stores: Record<string, ReactEventSourcingStore> = {};

        for (let table of tables) {
            const dataAdapter = (data : IEntityData) => {
                let transformedData = transformOrderData(data, table);
                let modifiedOrderData = mapAccordingToSchema(transformedData, dbSchema!.tables.find(x => x.name === table.tableId)!);
                return modifiedOrderData;
            }
        
            const postprocess = (entity: IDbEntity) => {
                entity.entityData._keywords = generateKeywordsByEntity(entity, table, i18n.language);
                return entity;
            }

            let eventPendingQueue = new QueueStore<ICrmOperationEvent>(table.tableId, userInfo?.tenant);
            let eventStream = new EventRemoteStream(table.tableId, eventPendingQueue);
            let queryVars = new Map<string, any>();
            queryVars.set("user", userInfo?.login);
        
            const rpc = eventSourcingRpc();
            let store: ReactEventSourcingStore;
            if (rpc.enabled) {
                store = new RpcClientEventSourcingStore(table.tableId, eventStream, dataAdapter, postprocess, queryVars, clientInstance, rpc.url);
            } else {
                store = new ReactEventSourcingStore(table.tableId, eventStream, dataAdapter, postprocess, queryVars, clientInstance);
            }

            store.onEntitySetChanged(async (changes) => {
                //dispatch(updateChangedOrdersAsync({ orderChanges: changes.getChangedIds() }));
                dispatch(updateEntityCountAsync({store: store}));
                await flushCachesToDumbCrmDb(table.tableId);
            })

            store.onNewEntity(async (newEntity) => {
                await updateAutocompleteValuesInDb(table.tableId, null, newEntity.data);
            });
            store.onUpdateEntity(async (oldEntity, newEntity) => {
                await updateAutocompleteValuesInDb(table.tableId, oldEntity.data, newEntity.data);
            });
            store.onRemoveEntity(async (oldEntity) => {
                await updateAutocompleteValuesInDb(table.tableId, oldEntity.data, null);
            })

            eventStream.onUploadingSizeChanged.add(async (length: number) => {
                dispatch(updateUploadingQueueLength({tableId: table.tableId, length}));
            });

            eventStream.onDownloadingPartialComplete.add(async (downloadingProcess: number|null) => {
                dispatch(updateDownloadingProgress({tableId: table.tableId,  length: downloadingProcess }));
            });

            stores[table.tableId] = store;
        }

        return stores;
    }, [tables]);


    useEffect(()=> {
        if (tables != null) {
            for (let table of tables) {
                let preloadQueries: IQuerySpecificatoin[] = [];
                preloadQueries.push(new AllFilter('', undefined, undefined, table.sortDirection, ()=>{}).getQuery(0, table!.sortField));

                for (let filter of table?.filters ?? []) {
                    try {
                        if (filter.users == null || filter.users.includes(userInfo!.login)) {
                            if (filter.version != null && filter.version >= 2) {
                                let query = {
                                    where: filter.where,
                                    orderBy: filter.sortField ?? table?.sortField
                                }
                                preloadQueries.push(query);
                            } else {
                                //TODO: remove QueryFilter usage. make more direct transformation
                                preloadQueries.push(new QueryFilter('', filter, table.sortDirection, ()=>{}, ()=>{}).getQuery(0, table!.sortField));
                            }
                        }
                    } catch (err) {
                        Logger.error('error preloading filter:', filter, serializeError(err, {maxDepth:2}));
                    }
                }

                if (!fullDataCacheEnabled())
                    stores[table.tableId].preloadLiveQuerieMonitors(preloadQueries);
            }
        }
        
        const initEntityCounts = async () => {
            const updateTasks = tables?.map(table => {
                return dispatch(updateEntityCountAsync({store: stores[table.tableId]}))
            });
            if (updateTasks != null)
                await Promise.all(updateTasks);
        }

        initEntityCounts().catch(err => Logger.error('initEntityCounts failed', serializeError(err, { maxDepth: 2 })));



        return () => {
            for (let [tableId, store] of Object.entries(stores)) {
                Logger.debug(`dispose store ${tableId}`);
                store.dispose();
            }
        }
    }, [stores]);

    return (
      <StoreContext.Provider value={stores!}>
        {props.children}
      </StoreContext.Provider>
    );
  };