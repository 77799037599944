import { ICrmFilter, ITableConfig, ITenantConfig } from "@core/Models/tenantConfig.models";
import { AllFilter, IFilter, QueryFilter, QueryFilterV2, SearchFilter } from "./OrderFilters";
import { userApiSaveJsonConfig } from "@core/Api/user-api";
import { saveConfig } from "@core/Redux/Slices/appSlice";
import { TFunction } from "i18next";
import { ICrmUserInfo } from "@core/Models/autogenerated/user.models";
import { IQuerySpecificatoin } from "@core/EventSourcing/Implementation/LiveQuery";
import _ from "lodash";

function removeFilterFromConfig(filter: IFilter
    , config : ITenantConfig
    , dispatch: (f:any)=>void
    ) {

    const tables = config.tables.map(table => {
        if (table.tableId != filter.tableId)
            return table;

        let newFilters = [];
        for (var x of table.filters ?? []) {
            if (x.id !== filter.id)
                newFilters.push(x);
        }
    
        return {
            ...table,
            filters: newFilters
        }
    });

    let newConfig = {...config, tables: tables} as ITenantConfig;

    dispatch(saveConfig(newConfig));
    userApiSaveJsonConfig({ config: JSON.stringify(newConfig) });    
}

export function createFilters(config : ITenantConfig | null
    , tableId: string
    , selectFilter: (id:string|null)=>void
    , userInfo: ICrmUserInfo
    , dispatch: (f:any)=>void
    , t: TFunction<"translation", undefined>
    ): IFilter[] {

    let filterList: IFilter[] = [];
    // let searchFilters: IFilter[] = [];

    if (!config)
        return [];

    const tableConfig = config.tables.find(x => x.tableId === tableId);
    if (tableConfig == null)
        return [];

    const onConfigFilterRemove = (filter : IFilter) => {
        removeFilterFromConfig(filter, config, dispatch);
    }

    // const onSearchClear = (filter: IFilter) => {
    //     clearSearch();
    // }

    const onFilterSelected = (filter : IFilter) => {
        selectFilter(filter.id);
    };

    const defaultSortDirection = tableConfig.sortDirection ?? 'Desc';

    // if (searchQuery) {
    //     let newFilter = new SearchFilter(t, searchQuery, defaultSortDirection, onFilterSelected, onSearchClear );
    //     searchFilters.push(newFilter);
    // }

    // for (let x of searchFilters ?? []) {
    //     filterList.push(x);
    // }

    filterList.push(new AllFilter(tableId, "", t("notSelected") ?? "All", defaultSortDirection, onFilterSelected));

    for (let x of tableConfig?.filters ?? []) {
        if (x.users == null || x.users.includes(userInfo.login)) {
            if (x.version != null && x.version >= 2) {
                filterList.push(new QueryFilterV2(tableId, x, defaultSortDirection, onFilterSelected, onConfigFilterRemove));
            } else {
                filterList.push(new QueryFilter(tableId, x, defaultSortDirection, onFilterSelected, onConfigFilterRemove));
            }
        }
    }

    return filterList;
}

export function getMergedMongoQueries(filterQuery: IQuerySpecificatoin, simpleQueries: {[columnId: string]: any}, searchQuery: string | null) : IQuerySpecificatoin {
    let query: IQuerySpecificatoin = {
        where: filterQuery.where,
        orderBy: filterQuery.orderBy,
        fullTextSearch: searchQuery ?? undefined,
    };

    if (!_.isEmpty(simpleQueries)) {
        if (query.where == 'getAll') {
            query.where = simpleQueries;
        }
        else {
            query.where = {
                '$and': [
                    query.where,
                    simpleQueries,
                ]
            };
        }
    }

    if (!_.isEmpty(simpleQueries) || searchQuery) {
        query.transient = true;
    }

    return _.cloneDeep(query);
}