import {useContext, useEffect, useMemo} from 'react';
import {Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useNavigate} from 'react-router-dom';
import {PluginPage} from './Pages/PluginPage/PluginPage';
import {useAppDispatch, useAppSelector} from '@core/Redux/hooks';
import {SigninPage} from './Pages/SigninPage/SigninPage';
import {DesktopAppLayout} from './AppLayouts/DesktopAppLayout/DesktopAppLayout';
import {addDataImportPath, addIntegrationPath, addReportPath, aiChatPath, tablePath, profilePath, registrationPagePath, signinPagePath, signupPagePath} from '@core/Constants/route-paths';
import {AccessTokenContext, AccessTokenDispatchContext} from '@core/Contexts/AccessTokenContext';
import {useLocalStorage} from '@core/Hooks/use-local-storage';
import {accessTokenKey} from '@core/Constants/app-storage-keys';
import {isMobile} from 'react-device-detect';
import {MobileAppLayout} from './AppLayouts/MobileAppLayout/MobileAppLayout';
import {OperationEventPoller} from '@core/ServiceComponents/OperationEventPoller/OperationEventPoller';
import {DeviceTypeContext, IDeviceType} from '@core/Contexts/DeviceTypeContext';
import {AiChatPage} from './Pages/AiChatPage/AiChatPage'
import urlJoin from 'url-join';
import {OrdersPage} from './Pages/OrdersPage/OrdersPage';
import {
    initApplicationAsync,
    initApplicationCore} from '@core/Redux/Slices/appSlice';
import {
    selectAppReady,
    selectPluginsState,
    selectTenantConfig,
    selectUserLogged,
    selectUserNotLogged
} from '@core/Redux/store';
import {serializeError} from 'serialize-error';
import Logger from 'js-logger';
import { OrderStoreProvider } from '@core/Stores/OrderStoreProvider';
import { RegistrationPage } from './Pages/RegistrationPage/RegistrationPage';
import { SignupPage } from './Pages/SignupPage/SignupPage';
import { AddReport } from './Pages/ReportsPage/AddReport';
import { AddIntegration } from './Pages/IntegrationPage/AddIntegration';
import { AddDataImport} from './Pages/DataImportPage/AddDataImport';
import { ProfilePage } from './Pages/ProfilePage/profilePage';
import {useTranslation} from "react-i18next";
import { loadedRoutes } from '@core/Plugins/pluginManager';
import { ITenantConfig } from '@core/Models/tenantConfig.models';
import { ConfigProvider } from 'antd';
import { locale } from 'src/Locale/date-fns';
import { PRIMARY_COLOR } from 'src/theme';

declare var location: any;

const AppLayout = (props: any) =>
{
    const dispatch = useAppDispatch();
    const deviceType = useContext(DeviceTypeContext);
    const appReady = useAppSelector(selectAppReady);
    const userLogged = useAppSelector(selectUserLogged);
    const userNotLogged = useAppSelector(selectUserNotLogged);

    const tenantConfig = useAppSelector(selectTenantConfig);

    const navigateTo = useNavigate();

    useEffect(() => {
        const initApp = async () => {
            await dispatch(initApplicationAsync());
        }

        initApp().catch(err => Logger.error('initApp failed', serializeError(err, { maxDepth: 2 })));

        return () => {};
    }, []);

    useEffect(()=> {
        if (userNotLogged)
            navigateTo(signinPagePath);
    }, [userNotLogged]);

    if (userLogged && appReady) {
        if (deviceType.isMobile)
            return <MobileAppLayout/>;
        else
            return <DesktopAppLayout/>;
    } else {
        return <Loading/>;
    }
}

function Loading() {
    const { t } = useTranslation();

    return <span>{t("loading_status")}</span>
}

function NoRoute() {
    const [accessToken] = useLocalStorage<string>(accessTokenKey, {watchChanges: true});
    const appReady = useAppSelector(selectAppReady);
    const tenantConfig = useAppSelector(selectTenantConfig);
    const defaultTableId = getDefaultTableId(tenantConfig);
    if (defaultTableId == null) {
        return <span>There is no tables in configuration</span>;
    }

    if (appReady) {
        const getDefaultPath = () => accessToken
            ? tablePath(defaultTableId)
            : signinPagePath;
            
        return (<Navigate to={getDefaultPath()}/>);
    }
    else
        return (<></>);
}

function App() {
    const [accessToken, setAccessToken] = useLocalStorage<string>(accessTokenKey, {watchChanges: true});
    const appReady = useAppSelector(selectAppReady);
    const tenantConfig = useAppSelector(selectTenantConfig);

    let _ = useMemo(()=>initApplicationCore(), []);

    // если в другой вкладке сменили пользователя, перезагружаем страницу.
    // нельзя, чтобы стейт заказов с одной учётки писался в другую
    // данные будут инконсистентны
    useEffect(()=>{
        const listener = (e: StorageEvent) => {
            if(e.key !== accessTokenKey)
                return;

            location.pathname = '/';
        }
        window.addEventListener('storage', listener);

        return ()=>{
            window.removeEventListener('storage', listener);
        };
    }, []);

    const pluginsState = useAppSelector(selectPluginsState);

    const isMobileDevice = process.env.REACT_APP_USE_MOBILE_APP_LAYOUT === 'true'
        ? true
        : isMobile;
    const deviceType: IDeviceType = {isMobile: isMobileDevice};

    const renderAppRoutes = () => {
        let router = createBrowserRouter(
            createRoutesFromElements([
                (<Route path={registrationPagePath} element={<RegistrationPage/>}/>),
                (<Route path={signupPagePath} element={<SignupPage/>}/>),
                (<Route path={signinPagePath} element={<SigninPage/>}/>),
                (<Route element={<AppLayout/>}>
                    {tenantConfig?.tables.map(table => 
                        <Route key={'table' + table.tableId} path={tablePath(table.tableId)+'/*'} element={<OrdersPage tableId={table.tableId}/>}/>
                    )}
                    <Route path={aiChatPath} element={<AiChatPage/>}/>
                    <Route path={profilePath} element={<ProfilePage/>}/>
                    <Route path={addReportPath} element={<AddReport/>}/>
                    <Route path={addIntegrationPath} element={<AddIntegration/>}/>
                    <Route path={addDataImportPath} element={<AddDataImport/>}/>

                    {pluginsState.modules.flatMap(module => 
                        module.installation.routes.map((route) => 
                            <Route key={route.id}
                                path={urlJoin(route.path, ':pageId?')}
                                element={<PluginPage route = {loadedRoutes[route.id ?? ""]}/>}/>
                        )
                    )}
                                                
                    <Route path='*' element={<NoRoute/>} />
                </Route>)
            ])
        );

        return  <RouterProvider router={router} />;
    }

    return (
        <DeviceTypeContext.Provider value={deviceType}>
            <AccessTokenContext.Provider value={accessToken}>
                <AccessTokenDispatchContext.Provider value={setAccessToken as ((val: string | undefined) => void)}>
                    <OrderStoreProvider>
                        <ConfigProvider locale={locale} theme={{ token: { colorPrimary: PRIMARY_COLOR } }} componentSize="large">
                            {renderAppRoutes()}
                            {appReady && <OperationEventPoller/>}
                        </ConfigProvider>
                    </OrderStoreProvider>
                </AccessTokenDispatchContext.Provider>
            </AccessTokenContext.Provider>
        </DeviceTypeContext.Provider>
    );
}

export function getDefaultTableId(tenantConfig: ITenantConfig|null): string | undefined {
    return tenantConfig?.tables?.[0]?.tableId;
}

export default App;
