import type { IEntity } from '@core/Models/i-entity';

export class SearchTools {
    private static readonly dateRegExp = /((\d{1,2}\D+){2}\d{2,4})|(\d{2,4}(\D+\d{1,2}){2})/;

    /**
     * Метод, извлекающий дату из текста. Если такой не нашлось, возращается `null`
     */
    private static extractDates(value: string) : string[] {
        // Если в тексте есть что-то похожее на дату
        if (this.dateRegExp.test(value)) {
            // Извлекаем числа из текста
            const dateParts = value.match(/\d+/g);
        
            // По идее, этого быть не должно, но на всякий случай проверяем, что всё извлеклось нормально
            if (!dateParts || dateParts.length < 3) {
                return [];
            }
            
            const [num1, num2, num3] = dateParts.map(Number);
            const variants = [
                `${num1}.${num2}.${num3}`, // mm.dd.yyyy
                `${num2}.${num1}.${num3}`, // dd.mm.yyyy
                `${num2}.${num3}.${num1}`, // yyyy.mm.dd
                `${num3}.${num2}.${num1}`, // yyyy.dd.mm
            ]
            let result: string[] = [];

            for (const variant of variants) {
                const date = new Date(variant).toLocaleDateString();

                if (date != "Invalid Date") {
                    result.push(date);
                }
            }

            return result;
        }
        
        return [];
    }

    private static readonly phoneRegex = /\+?[\s-()/]*(\d[\s-()/]*){5,15}/g;

    /**
     * Метод, пробующий извлечь номер телефона из строки
     */
    private static extractPhoneNumbers(value: string) : string[] {
        const phones: string[] = [];
        const phoneMatches = value.match(this.phoneRegex);

        if (phoneMatches) {
            for (const match of phoneMatches) {
                let normalized = match.replace(/\D/g, '');

                if (normalized.length > 6) {
                    normalized = normalized.replace(/^0+/g, '');
                    normalized = normalized.slice(1);
                }

                phones.push(normalized);
            }
        }

        return phones;
    }

    /**
     * Метод, обрабатывающий строку поиска. Возвращает массив строк, по которым будет происходить поиск
     */
    public static normalizeTextToSearch(value: string) : string[] {
        value = value.trim();
        let keywords = new Set<string>([value]);
        
        const extractedDates = this.extractDates(value);
        for (const date of extractedDates) {
            keywords.add(date);
        }

        const extractedPhoneNumbers = this.extractPhoneNumbers(value);
        for (const phone of extractedPhoneNumbers) {
            keywords.add(phone);
        }

        return Array.from(keywords);
    }

    /**
     * Метод, возвращающий строку с разделителями, содержащая значения какого-то объекта
     */
    private static objectValuesToString(object: {}) : string {
        return Object.values(object).map((e: any) => typeof e == "object" && e != null ? this.objectValuesToString(e) : e).join("#").toLowerCase();
    }

    /**
     * Метод, проверяющий, содержит ли Entity как подстроку одну из массива строк
     */
    public static isValuesInEntity(values: string[], entity: IEntity) : boolean {
        const keywordsString = SearchTools.objectValuesToString({id: entity.id, ...entity.data, _keywords: entity._keywords});
        return values.some(value => keywordsString.includes(value));
    }
}