import React, {MutableRefObject, useContext, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {CrmGridView} from '@core/VisualComponents/CrmGridView/CrmGridView';
import {useAppSelector} from '@core/Redux/hooks';
import {selectEntitiesAreLoading, selectEntityById, selectQueryStatus} from '@core/Redux/Slices/ordersSlice/storesSlice';
import styles from './OrderGridView.module.scss';
import {ICrmField} from '@core/Models/tenantConfig.models';
import { selectTableConfig, selectUserInfo } from '@core/Redux/store';
import {ICellClickedArgs, ICrmCellInputEditors} from "src/PluginShared/core-ui-api";
import { DeviceTypeContext } from '@core/Contexts/DeviceTypeContext';
import { IOrderContextMenu } from '../OrderContextMenu/OrderContextMenu';
import { IEntityData } from '@core/Models/i-entity';

export interface IOrderGridViewProps {
    tableId: string;
	entityIds: string[];
	fields?: string[];
	numRowsPerPage: number;
	ResetCursor: () => void;
	MoveCursor: (offset: number) => void;
	cursorAtStart: boolean;
	cursorAtEnd: boolean;
	cellEditorsRef: React.MutableRefObject<ICrmCellInputEditors>;
	contextMenuRef: MutableRefObject<IOrderContextMenu>;
	selectedEntityId?: string;
	onRowClick: (entityId: string) => void;

    simpleFilters: {[columnId: string]: any};
    onChangedSimpleQuery: (columnId: string, query: any) => void;
    onResetSimpleQuery: (columnId: string) => void;
}

export function OrderGridView(props: IOrderGridViewProps) {
    const { t } = useTranslation();
    const tableConfig = useAppSelector(selectTableConfig(props.tableId));
    const tableName = tableConfig?.tableName ?? '';

    const userInfo = useAppSelector(selectUserInfo);
    const deviceType = useContext(DeviceTypeContext);
    const queryStatus = useAppSelector(selectQueryStatus(props.tableId));
    const isLoading = useAppSelector(selectEntitiesAreLoading(props.tableId));

    const orderFields = useMemo(() => {
        let result: ICrmField[] = [];

        if (props.fields === undefined) {
            result = [...result, ...tableConfig?.fields ?? []];
            return result;
        }

        for (let e of tableConfig?.fields ?? []) {
            if (props.fields?.includes(e.id))
                result.push(e);
        }

        return result;
    }, [tableConfig?.fields, props.fields]);

    const onEditCellClicked = (args: ICellClickedArgs) => {
        const {entity, field} = args.props;
        const entityData = args.entityData;

        props.cellEditorsRef.current.showEditor(props.tableId, entity.id, entityData, field, { x: args.e.clientX, y: args.e.clientY });
    };

    const onContextMenu = (e: MouseEvent, ref: MutableRefObject<any>, entityId: string, entityData: IEntityData, field?: ICrmField) => {
        return props.contextMenuRef.current.onContextMenu(e, ref, props.tableId, entityId, entityData, field);
    }

    const useItemByIdSelector = (id: string) => {
        //return props.entities.find(x => x.id === id)?.data ?? {};
        return useAppSelector(selectEntityById(props.tableId, id));
    }
    
    return (<div className={styles.host}>
            <CrmGridView entityIds={props.entityIds}
                keyField="id"
                tableName={tableName}
                gridId={`${props.tableId}_Grid`}
                fields={orderFields}
                addColumnAllowed={!deviceType.isMobile && userInfo?.role == 'SuperUser'}
                useItemByIdSelector={useItemByIdSelector}
                numRowsPerPage={props.numRowsPerPage}
                onCellDoubleClicked={onEditCellClicked}
                onContextMenu={onContextMenu}
                ResetCursor={props.ResetCursor}
                MoveCursor={props.MoveCursor}
                cursorAtStart={props.cursorAtStart}
                cursorAtEnd={props.cursorAtEnd}
                selectedEntityId={props.selectedEntityId}
                onRowClick={props.onRowClick}
                simpleFilters={props.simpleFilters}
                onChangedSimpleQuery={props.onChangedSimpleQuery}
                onResetSimpleQuery={props.onResetSimpleQuery}
                showFilters
                isLoading={queryStatus.status == "loading"}
                >
            </CrmGridView>

    </div>);
}
