import type { IEntity } from "@core/Models/i-entity";
import _ from "lodash";
import { EntitySetChanges, EventSourcingStore } from "../EventSourcingStore";
import { LiveQuery } from "./LiveQuery";
import { ResultSet } from "./ResultSet";
import { NowReference } from "./MongoQueryParser";
import { DateTime } from "luxon";
import { serializeError } from "serialize-error";
import { v4 as uuidv4 } from 'uuid';
import Logger from "js-logger";
import type { IDbEntity } from "@core/JsStore/stores/shared/models/i-db-entity";

export enum LiveQueryMonitorState {
    loading,
    live,
    shutdown
}

export class LiveQueryMonitor {
    id = uuidv4();

    state: LiveQueryMonitorState = LiveQueryMonitorState.loading;

    result: ResultSet;
    result_skiplimit: IEntity[] = [];

    store: EventSourcingStore;
    query: LiveQuery;
    nowRef: NowReference;

    recalculationTimer?: number;

    wherePredicate: (entity: IEntity)=>[boolean, number];
    orderBySelector: (entity: IEntity)=>any;

    private refCount: number = 0;

    private preloadAwaiter: Promise<void>
    private preloadAwaiterResolver: ()=>void;

    private onChangeHandlers: Array<(result: ResultSet, changedEntities: Map<string, IEntity> ) => void> = [];
    private onNewStateHandlers: Array<(state: LiveQueryMonitorState) => void> = [];

    constructor(query: LiveQuery, store: EventSourcingStore) {
        this.store = store;
        this.query = query;
        // this.transient = transient ?? false;

        this.wherePredicate = query.wherePredicate;
        this.orderBySelector = query.orderBySelector;
        this.nowRef = query.nowRef;

        this.result = new ResultSet();

        this.preloadAwaiterResolver = ()=>{};
        this.preloadAwaiter = new Promise<void>((resolve, reject)=> {
            this.preloadAwaiterResolver = resolve;
        });
    }

    /** Захватить монитор, начать использовать */
    public acquire = () => {
        this.refCount++;
    }

    /** Освободить монитор, прекратить использовать */
    public release = () => {
        if (this.refCount > 0) {
            this.refCount--;
        }
        else {
            Logger.error("Released LiveQueryMonitor with refCount = 0");
        }
    }

    public getRefCount = () => {
        return this.refCount;
    }

    public dispose = () => {
        this.state = LiveQueryMonitorState.shutdown;

        if (this.result != null)
            this.result.dispose();

        this.onNewStateHandlers = [];
        this.onChangeHandlers = [];

        if (this.recalculationTimer != null)
            clearTimeout(this.recalculationTimer);

        this.preloadAwaiterResolver();
    }

    public prefill = (entity: IEntity):void => {
        if (this.state == LiveQueryMonitorState.shutdown)
            return;
        try {
            let [ok, validDue] = this.wherePredicate(entity);
            this.result.upsert(ok, {id: entity.id, orderKey:this.orderBySelector(entity), validDue: validDue});
        } catch(err: any) {
            const sErr = serializeError(err, { maxDepth: 2 });
            Logger.error('LiveQuery predicate throw error', sErr, entity, this.query.mongoQuery);
        }
    }

    public completePrefill = () => {
        if (this.state == LiveQueryMonitorState.shutdown)
            return;
        
        this.state = LiveQueryMonitorState.live;
            
        this.preloadAwaiterResolver();

        this.triggerOnNewState(this.state);
        const emptyCache = new Map<string, IEntity>();
        this.triggerOnChange(this.result, emptyCache);
        
        this.updateRecalculationTimer();
    }

    public handleEntitySetChanged = async (changes: EntitySetChanges) => {
        const start_time = performance.now();
        await this.preloadAwaiter;
        let resultUpdated = false;
        this.nowRef.now = DateTime.now();

        changes.handle(
            (newEntity)=> {
                try{
                    let [ok, validDue] = this.wherePredicate(newEntity);
                    if (this.result.upsert(ok, {id: newEntity.id, validDue: validDue, orderKey:this.orderBySelector(newEntity)}))
                        resultUpdated = true;
                } catch(err: any) {
                    const sErr = serializeError(err, { maxDepth: 2 });
                    Logger.error('LiveQuery predicate throw error', sErr, newEntity, this.query.mongoQuery);
                }
            },
            (oldEntity, newEntity) => {
                if (newEntity == null) {
                    if (this.result.delete(oldEntity.id)) {
                        resultUpdated = true;
                    }
                } else {
                    try {
                        let [ok, validDue] = this.wherePredicate(newEntity);
                        if (this.result.upsert(ok, {id: newEntity.id, validDue: validDue, orderKey:this.orderBySelector(newEntity)}))
                            resultUpdated = true;
                    } catch(err: any) {
                        const sErr = serializeError(err, { maxDepth: 2 });
                        Logger.error('LiveQuery predicate throw error', sErr, newEntity, this.query.mongoQuery);
                    }
                }
            },
            (oldEntity) => {
                if (this.result.delete(oldEntity.id)) {
                    resultUpdated = true;
                }
            }
        );

        if (resultUpdated) {
            var changedEntities = new Map<string, IEntity>(changes.added);
            changes.updated.forEach((e) => {
                if (e.newEntity != null)
                    changedEntities.set(e.newEntity.id, e.newEntity);
            });

            this.triggerOnChange(this.result, changedEntities);
        }

        this.updateRecalculationTimer();

        //console.log(`[Query] updated:${resultUpdated}, time ${performance.now()-start_time} ms`);
    }

    private updateRecalculationTimer = () => {
        //"1+" для некоторого запаса
        let recalculationTimeout = 1 + this.result.getRecalculationTime() - DateTime.utc().toUnixInteger();
        if (this.recalculationTimer != null)
            clearTimeout(this.recalculationTimer);
        if (recalculationTimeout != Infinity) {
            let delay = Math.min(2000000000, recalculationTimeout*1000); //prevent setTimeout delay overflow
            this.recalculationTimer = window.setTimeout(this.handlePredicateStateChanged, delay);
        }
        else
            this.recalculationTimer = undefined;
    }

    private handlePredicateStateChanged = () => {
        let now = DateTime.now();
        let needRecalculate = this.result.getRecalculationEntities(now.toUnixInteger());
        this.nowRef.now = now;

        this.store.bulkGet(Array.from(needRecalculate))
            .then((dbEntities: (IDbEntity|null)[]) => {
                if (now < this.nowRef.now) {
                    //some newer task already do this update
                    return;
                }

                let resultUpdated = false;
                let changes = dbEntities.filter(e => e != null).map(e => this.store.db2entity(e!));

                for (let entity of changes) {
                    try {
                        let [ok, validDue] = this.wherePredicate(entity);
                        let orderKey = this.orderBySelector(entity);
                        if (this.result.upsert(ok, {id: entity.id, validDue: validDue, orderKey:orderKey}))
                            resultUpdated = true;
                    } catch(err: any) {
                        const sErr = serializeError(err, { maxDepth: 2 });
                        Logger.error('LiveQuery predicate throw error', sErr, entity, this.query.mongoQuery);
                    }
                }

                if (resultUpdated) {
                    var changedEntities = new Map<string, IEntity>();
                    changes.forEach((e) => {
                        if (e != null)
                            changedEntities.set(e.id, e);
                    });
        
                    this.triggerOnChange(this.result, changedEntities);
                }

                this.updateRecalculationTimer();   
            })
            .catch(err => {
                Logger.error("[LiveQueryMonitor] handlePredicateStateChanged store.bulkGet failed", err);
            });
    }   

    public onNewState = (handler: (state: LiveQueryMonitorState) => void): void => {
        this.onNewStateHandlers.push(handler);
    }
    public onNewStateRemove = (handler: (state: LiveQueryMonitorState) => void): void => {
        this.onNewStateHandlers = this.onNewStateHandlers.filter(h => h !== handler);
    }
    private triggerOnNewState = (state: LiveQueryMonitorState): void => {
        for (let h of this.onNewStateHandlers.slice(0))
            h(state);
    }

    /**
     * Adds a handler that monitor resultSet change. 
     * resultSet contain ids of all entities that passed the filter 
     * changedEntities contains entity data for changed entites at current batch of events.
     * target list of entities can't be reconstructed from only changedEntities and resultSet: manual loading may be required
     */
    public onChange = (handler: (resultSet: ResultSet, changedEntities: Map<string, IEntity>) => void): void => {
        this.onChangeHandlers.push(handler);
    }
    public onChangeRemove = (handler: (resultSet: ResultSet, changedEntities: Map<string, IEntity>) => void): void => {
        this.onChangeHandlers = this.onChangeHandlers.filter(h => h !== handler);
    }
    private triggerOnChange = (resultSet: ResultSet, changedEntities: Map<string, IEntity>): void => {
        for (let h of this.onChangeHandlers.slice(0))
            h(resultSet, changedEntities);
    }
}
  