import cs from 'clsx';

import styles from './StatusIndicator.module.scss';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from '@core/Redux/hooks';
import { useTranslation } from "react-i18next";
import { TFunction } from 'i18next';
import { selectDownloadingProgress, selectEntitiesAreLoading, selectUploadingQueueNotEmpty } from '@core/Redux/Slices/ordersSlice/storesSlice';
import {ReactComponent as CloudSyncIcon} from '@assets/Icons/cloud-sync-outline.svg';
import {ReactComponent as CloudCheckIcon} from '@assets/Icons/cloud-check-outline.svg';
import {ReactComponent as CloudRemoveIcon} from '@assets/Icons/cloud-remove-outline.svg';

type Status = 'loading' | 'uploading' | 'uploading_ok' | 'downloading' | null;

type StatusView = {
    text: string, 
    icon: FunctionComponent<any>, 
    className: string
} | null;

class StatusPanelFSM {
    private showLoading: boolean = false;
    private showUploading: boolean = false;
    private showDownloading: boolean = false;

    public update = (loading: boolean, uploading: boolean, downloadingProgress: number|null, setView: (view: StatusView)=>void, t: TFunction) => {
        this.showLoading = loading;
        this.showUploading = uploading;
        this.showDownloading = downloadingProgress != null;
        this.updateView(downloadingProgress, setView, t);
    }

    private updateView = (downloadingProgress: number|null, setView: (view: StatusView)=>void, t: TFunction) => {
        if (this.showLoading) {
            setView({
                text: t("loading_status"),
                icon: CloudSyncIcon,
                className: styles.loadingStatus
            });
        } else if (this.showUploading) {
            setView({
                text: t("uploading_status"),
                icon: CloudSyncIcon,
                className: styles.uploadingStatus
            });
        }  else if (this.showDownloading) {
            setView({
                text: t("downloading_status") + ` ${Math.floor(downloadingProgress!*100)}%`,
                icon: CloudSyncIcon,
                className: styles.downloadingStatus
            });
        } else {
            setView({
                text: t("idle_status"),
                icon: CloudCheckIcon,
                className: styles.idleStatus
            });
        }
    }
}

export function StatusIndicator(props: {tableId: string}) {
    const [fsm] = useState(new StatusPanelFSM());
    const { t } = useTranslation();
    const [statusView, setStatusView] = useState<StatusView>(null);

    const entitiesAreUploading = useAppSelector(selectUploadingQueueNotEmpty(props.tableId));
    const entitiesAreLoading = useAppSelector(selectEntitiesAreLoading(props.tableId));
    const entitiesDownloadingProgress = useAppSelector(selectDownloadingProgress(props.tableId));

    useEffect(() => {
        fsm.update(entitiesAreLoading, entitiesAreUploading, entitiesDownloadingProgress, setStatusView, t);

    }, [entitiesAreLoading, entitiesAreUploading, entitiesDownloadingProgress]);

    if (statusView == null) {
        return null;
    }
    else
        return (
            <statusView.icon className={cs(statusView.className, styles.icon)} title={statusView.text}/>
        )
}