import { t } from "i18next";
import { InvalidHint } from "src/App/Pages/Shared/OrderField/InvalidHint/InvalidHint";

export interface IOrderListViewItemValueErrorProps {
    value?: any;
}

export function OrderListViewItemValueError(props: IOrderListViewItemValueErrorProps) {
    let value = props.value;
    if (value == null || (typeof value != "string" && typeof value != "number")) {
        value = t("error");
    }
    else {
        value = value.toString();
    }

    return <span style={{
        color: "red",
        position: "relative",
        paddingRight: "2.4em",
        display: "inline-flex",
        width: "fit-content",
        height: "1.6em",
        alignItems: "center",
        maxWidth: "100%",
    }}>
        {value}
        <InvalidHint/>
        </span>
}