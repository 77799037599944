import { ICrmField } from "@core/Models/tenantConfig.models";
import { DateInput } from "src/App/Pages/Shared/OrderField/DateInput/DateInput";
import styles from "./CrmGridAddDateFilterDialog.module.scss";
import { MutableRefObject, useEffect, useState } from "react";
import { t } from "i18next";

export interface ICrmGridAddDateFilterDialogProps {
    field: ICrmField;
    queryRef: MutableRefObject<any>;
    onEnter?: () => void;
}

export function CrmGridAddDateFilterDialog(props: ICrmGridAddDateFilterDialogProps) {
    const [currLeftValue, setCurrLeftValue] = useState<number | null>(props.queryRef.current?.["$gte"]);
    const [currRightValue, setCurrRightValue] = useState<number | null>(props.queryRef.current?.["$lt"] ? props.queryRef.current?.["$lt"] - OneDayInSeconds : null);

    const keydownHandler = (event: KeyboardEvent) => {
        switch (event.key) {
            case "Enter":
                props.onEnter && props.onEnter();
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownHandler, false);
        return () => {
            document.removeEventListener("keydown", keydownHandler, false);
        };
    }, [keydownHandler]);

    const onChangedLeft = (value: any) => {
        if (value == null || value === "" || isNaN(value)) {
            if (props.queryRef.current?.["$lt"] == null) {
                props.queryRef.current = null;
            }
            else {
                let newQuery = props.queryRef.current;
                delete newQuery["$gte"];
                props.queryRef.current = newQuery;
            }
            setCurrLeftValue(null);
        }
        else {
            let newQuery = props.queryRef.current ?? {};
            newQuery["$gte"] = value;
            props.queryRef.current = newQuery;
            setCurrLeftValue(value);
        }
    }

    const onChangedRight = (value: any) => {
        if (value == null || value === "" || isNaN(value)) {
            if (props.queryRef.current?.["$gte"] == null) {
                props.queryRef.current = null;
            }
            else {
                let newQuery = props.queryRef.current;
                delete newQuery["$lt"];
                props.queryRef.current = newQuery;
            }
            setCurrRightValue(null);
        }
        else {
            let newQuery = props.queryRef.current ?? {};
            newQuery["$lt"] = value + OneDayInSeconds;
            props.queryRef.current = newQuery;
            setCurrRightValue(value);
        }
    }

    return <div className={styles.container}>
        <div>
            <DateInput
                placeholder={t("from")}
                initialValue={currLeftValue}
                onChanged={onChangedLeft}
            />
        </div>
        <span>–</span>
        <div>
            <DateInput
                placeholder={t("to")}
                initialValue={currRightValue}
                onChanged={onChangedRight}
            />
        </div>
    </div>;
}

const OneDayInSeconds = 60 * 60 * 24;